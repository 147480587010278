/* eslint-disable react-hooks/exhaustive-deps */
import { Geofence, GeofenceType, GeoJsonType } from '@eagle/core-data-types';
import L, { LeafletMouseEvent, PopupEvent } from 'leaflet';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Circle, CircleProps, Pane, Polygon, useMap } from 'react-leaflet';
import { CacheDataTypes } from '../../../../types';
import { FetchOneOfAll } from '../../../fetch';
import { useBoolFlag, useNumberFlag } from '../../../flags';
import { GeofenceContextMenu } from './geofence-context-menu';
import { GeofenceTooltip } from './geofence-tooltip';
import { getTooltipDisplay, parseCircleGeometry } from './geofence.util';

const DYNAMIC_GEOFENCE_VISIBILITY_FLAG = 'portals-dynamic-geofence-visibility-feature';
const DYNAMIC_GEOFENCE_VISIBILITY_THRESHOLD_FLAG = 'portals-dynamic-geofence-visibility-minimum-pixel-threshold';

interface Props {
  geofences: Geofence[];
  handleMouseMove: (event: LeafletMouseEvent) => void;
  handleMouseOut: () => void;
  handleMouseOver: (id: string) => void;
  handlePopupClose: () => void;
  handlePopupOpen: (event: PopupEvent) => void;
  hoveredGeofenceId?: string;
  intersections: Geofence[];
  showTooltip: boolean;
}

export const GeofencePane: FC<Props> = ({
  geofences,
  handleMouseMove,
  handleMouseOut,
  handleMouseOver,
  handlePopupClose,
  handlePopupOpen,
  hoveredGeofenceId,
  intersections,
  showTooltip,
}): JSX.Element => {
  const map = useMap();
  const { t } = useTranslation(['common']);
  const intersectionDisplays = intersections.map(({ display }) => display);
  const hasDynamicGeofenceVisibilityFlag = useBoolFlag(DYNAMIC_GEOFENCE_VISIBILITY_FLAG);
  const dynamicGeofenceVisibilityThreshold = useNumberFlag(DYNAMIC_GEOFENCE_VISIBILITY_THRESHOLD_FLAG);

  if (!geofences) return <></>;

  return (
    <Pane name="geofence-pane">
      {geofences.map(({ _id, display, geofenceTypeId, geometry }) => (
        <FetchOneOfAll
          key={_id}
          id={geofenceTypeId}
          dataType={CacheDataTypes.GEOFENCE_TYPE}
          loadingFactory={() => <></>}
          renderFactory={({ color }: GeofenceType) => {
            const eventHandlers = {
              mousemove: handleMouseMove,
              mouseout: handleMouseOut,
              mouseover: () => handleMouseOver(_id),
              popupclose: handlePopupClose,
              popupopen: handlePopupOpen,
            };

            const children = (
              <>
                {showTooltip && <GeofenceTooltip display={getTooltipDisplay(intersectionDisplays, t) ?? display} />}
                <GeofenceContextMenu
                  geofences={intersections}
                  handleMouseOut={handleMouseOut}
                  handleMouseOver={handleMouseOver}
                />
              </>
            );

            switch (geometry.type) {
              case GeoJsonType.POLYGON: {
                const positions = L.GeoJSON.coordsToLatLngs(geometry.coordinates, 1);

                if (hasDynamicGeofenceVisibilityFlag && dynamicGeofenceVisibilityThreshold) {
                  const area = getAreaOfBoundsInPixels(L.polygon(positions).getBounds(), map);

                  if (area < dynamicGeofenceVisibilityThreshold) {
                    return <></>;
                  }
                }

                return (
                  <Polygon
                    eventHandlers={eventHandlers}
                    pathOptions={{ color, weight: hoveredGeofenceId === _id ? 2 : 1 }}
                    positions={positions}
                  >
                    {children}
                  </Polygon>
                );
              }

              case GeoJsonType.CIRCLE: {
                const { center, radius } = parseCircleGeometry(geometry);

                if (hasDynamicGeofenceVisibilityFlag && dynamicGeofenceVisibilityThreshold) {
                  const circle = L.circle(L.GeoJSON.coordsToLatLng(center), { radius });
                  const area = getAreaOfBoundsInPixels(getCircleBounds(circle, map), map);

                  if (area < dynamicGeofenceVisibilityThreshold) {
                    return <></>;
                  }
                }

                return (
                  <MemoizedCircle
                    eventHandlers={eventHandlers}
                    pathOptions={{ color, weight: hoveredGeofenceId === _id ? 2 : 1 }}
                    center={center}
                    radius={radius}
                  >
                    {children}
                  </MemoizedCircle>
                );
              }

              default:
                return <></>;
            }
          }}
        />
      ))}
    </Pane>
  );
};

interface MemoizedCircleProps extends Omit<CircleProps, 'center'> {
  center: [number, number];
}

// Memoizing center is needed to avoid flickering of geofence tooltip.
const MemoizedCircle: FC<MemoizedCircleProps> = ({ center, ...props }) => {
  const centerLatLng = useMemo(() => L.GeoJSON.coordsToLatLng(center), center);

  return <Circle center={centerLatLng} {...props} />;
};

const getCircleBounds = (circle: L.Circle, map: L.Map): L.LatLngBounds => {
  // The bounding box of a circle can only be calculated if it's added
  // to the map, so we add it, do the calculation, and then remove it.
  circle.addTo(map);
  const bounds = circle.getBounds();
  map.removeLayer(circle);
  return bounds;
};

const getAreaOfBoundsInPixels = (bounds: L.LatLngBounds, map: L.Map): number => {
  const nePx = map.project(bounds.getNorthEast());
  const swPx = map.project(bounds.getSouthWest());
  const width = nePx.x - swPx.x;
  const height = swPx.y - nePx.y;

  return width * height;
};
