import { AsYouType, isValidPhoneNumber } from 'libphonenumber-js/max';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { PhoneInputCountry } from '../../components/phone-input';
import { COUNTRIES } from '../../components/phone-input/constants';
import { PHONE_MIN_LENGTH } from '../../constants';
import { Nullable } from '../../types';
import { matchIsArray } from '../../util/array';
import { getCallingCodeOfCountry } from '../../util/phone-input';

interface UsePhoneDigitsParams {
  defaultCountry?: PhoneInputCountry;
  disableFormatting?: boolean;
  excludedCountries?: PhoneInputCountry[];
  forceCallingCode?: boolean;
  onChange?: (value: string) => void;
  onlyCountries?: PhoneInputCountry[];
  value: string;
}

interface UsePhoneDigitsReturn {
  inputValue: string;
  isoCode: Nullable<PhoneInputCountry>;
  isValid: boolean;
  onCountryChange: (newCountry: PhoneInputCountry) => void;
  onInputChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

interface State {
  inputValue: string;
  isoCode: Nullable<PhoneInputCountry>;
}

interface GetInitialStateParams {
  defaultCountry?: PhoneInputCountry;
  disableFormatting?: boolean;
  initialValue: string;
}

interface Filters {
  excludedCountries?: PhoneInputCountry[];
  onlyCountries?: PhoneInputCountry[];
}

export const getInitialState = (params: GetInitialStateParams): State => {
  const { defaultCountry, initialValue, disableFormatting } = params;

  const fallbackValue = defaultCountry ? `+${COUNTRIES[defaultCountry]?.[0] as string}` : '';

  const asYouType = new AsYouType(defaultCountry);
  let inputValue = asYouType.input(initialValue);

  const phoneNumberValue = asYouType.getNumberValue();

  if (defaultCountry && asYouType.getCountry() === undefined) {
    inputValue = fallbackValue;
  } else if (disableFormatting && phoneNumberValue) {
    inputValue = phoneNumberValue;
  }

  return {
    inputValue: inputValue || fallbackValue,
    isoCode: asYouType.getCountry() || defaultCountry || null,
  };
};

const matchIsIsoCodeAccepted = (isoCode: PhoneInputCountry, filters: Filters): boolean => {
  const { excludedCountries, onlyCountries } = filters;
  if (matchIsArray(excludedCountries, true) && excludedCountries.includes(isoCode)) {
    return false;
  }
  if (matchIsArray(onlyCountries) && !onlyCountries.includes(isoCode)) {
    return false;
  }
  return true;
};

export const usePhoneDigits = ({
  value,
  onChange,
  defaultCountry,
  forceCallingCode,
  onlyCountries,
  excludedCountries,
  disableFormatting,
}: UsePhoneDigitsParams): UsePhoneDigitsReturn => {
  const asYouTypeRef = useRef<AsYouType>(new AsYouType(defaultCountry));
  const [previousDefaultCountry, setPreviousDefaultCountry] = useState(defaultCountry);
  const [state, setState] = useState<State>(() => {
    return getInitialState({
      initialValue: value,
      defaultCountry,
      disableFormatting,
    });
  });
  const [previousValue, setPreviousValue] = useState(value);

  const matchIsIsoCodeValid = (isoCode: Nullable<PhoneInputCountry>): Nullable<boolean> => {
    return (
      isoCode &&
      matchIsIsoCodeAccepted(isoCode, {
        onlyCountries,
        excludedCountries,
      })
    );
  };

  const typeNewValue = (inputValue: string): string => {
    asYouTypeRef.current.reset();
    return asYouTypeRef.current.input(inputValue);
  };

  const onInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
    let inputVal = event.target.value;
    inputVal = inputVal.startsWith('+') || inputVal === '' ? inputVal : `+${inputVal}`;
    const formattedValue = typeNewValue(inputVal);
    const country = asYouTypeRef.current.getCountry() ?? null;
    const phoneNumber = asYouTypeRef.current.getNumber() ?? null;
    const numberValue = asYouTypeRef.current.getNumberValue() ?? '';

    if (forceCallingCode && !phoneNumber && (state.isoCode || defaultCountry)) {
      const inputValueIsoCode = `+${getCallingCodeOfCountry(
        state.isoCode || (defaultCountry as PhoneInputCountry),
      )}`;
      onChange?.(inputValueIsoCode);
      setPreviousValue(inputValueIsoCode);
      setState({
        isoCode: state.isoCode || defaultCountry || null,
        inputValue: inputValueIsoCode,
      });
    } else if (numberValue && (!country || !matchIsIsoCodeValid(country))) {
      onChange?.(numberValue);
      setPreviousValue(numberValue);
      setState({
        isoCode: null,
        inputValue: numberValue,
      });
    } else if (disableFormatting) {
      onChange?.(numberValue);
      setPreviousValue(numberValue);
      setState({
        isoCode: country ?? null,
        inputValue: numberValue,
      });
    } else {
      onChange?.(formattedValue);
      setPreviousValue(formattedValue);
      setState({
        isoCode: country ?? null,
        inputValue: formattedValue,
      });
    }
  };

  useEffect(() => {
    if (value !== previousValue) {
      setPreviousValue(value);
      setState(
        getInitialState({
          initialValue: value,
          defaultCountry,
        }),
      );
    }
  }, [value, previousValue, defaultCountry]);

  useEffect(() => {
    if (defaultCountry === previousDefaultCountry) return;
    if (asYouTypeRef.current.country === previousDefaultCountry) return;
    setPreviousDefaultCountry(defaultCountry);
    asYouTypeRef.current = new AsYouType(defaultCountry);
    const { inputValue, isoCode } = getInitialState({
      initialValue: '',
      defaultCountry,
    });
    setPreviousValue(inputValue);
    asYouTypeRef.current.input(inputValue);
    onChange?.(inputValue);
    setState({
      inputValue,
      isoCode,
    });
  }, [defaultCountry, previousDefaultCountry, onChange]);

  const onCountryChange = (newCountry: PhoneInputCountry): void => {
    if (newCountry === state.isoCode) {
      return;
    }
    const callingCode = COUNTRIES[newCountry]?.[0] as string;
    const formattedValue = typeNewValue(`+${callingCode}`);
    onChange?.(formattedValue);
    setPreviousValue(formattedValue);
    setState({
      isoCode: newCountry,
      inputValue: formattedValue,
    });
  };

  const isValid: boolean = state.inputValue && state.inputValue.length > PHONE_MIN_LENGTH ? isValidPhoneNumber(state.inputValue) : true;

  return {
    inputValue: state.inputValue,
    isoCode: state.isoCode,
    onInputChange,
    onCountryChange,
    isValid,
  };
};
