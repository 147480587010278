import { AlertResponse } from '@eagle/core-data-types';
import { evaluate } from '@jexop/core';
import { Paper } from '@mui/material';
import { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useCustomRoutes, useMaxScreen } from '../../hooks';
import { testid } from '../../util';
import { TableRow } from '../table-row';

interface AlertListItemProps {
  alert: AlertResponse;
  template: unknown;
  cardTemplate: unknown;
}

export const AlertListItem: FC<AlertListItemProps> = ({ alert, template, cardTemplate }) => {
  const { alert: alertRoute } = useCustomRoutes();
  const mediumScreen = useMaxScreen('md');
  const routerLocation = useLocation();

  if (mediumScreen) {
    return <Paper role='row' component={Link} to={`/${alertRoute}/${alert.alertId}`} sx={{ mb: 3, display: 'block', textDecoration: 'none' }}><>{evaluate(cardTemplate, alert)}</></Paper>;
  }

  return <TableRow data-testid={testid`alert-id-${alert.data.testId ?? alert.alertId}`} hover link={`/${alertRoute}/${alert.alertId}`} state={{ previousUrl: routerLocation.pathname }} ><>{evaluate(template, alert)}</></TableRow>;
};
