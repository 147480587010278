/* eslint-disable react-hooks/exhaustive-deps */
import { PaginatedResponse } from '@eagle/api-types';
import { LastThingEvent } from '@eagle/core-data-types';
import { AxiosInstance } from 'axios';
import { LRUCache } from 'lru-cache';
import { Duration } from 'luxon';
import { createContext, FC, PropsWithChildren, useContext, useEffect, useMemo, useRef } from 'react';
import { useAuthenticated } from '../auth';
import { Undefinable } from '../types';

interface Props {
  cacheTtl?: Duration;
}

export interface LastThingEventsByFeature { [feature: string]: { [eventTypeId: string]: LastThingEvent } }

export interface LastThingEvents {
  byFeature: LastThingEventsByFeature;
  latest: Undefinable<LastThingEvent>;
}

type ContextProps = [
  getLastEvents: (thingId: string) => Promise<LastThingEvents | undefined>,
  invalidateLastEvents: (thingId: string) => void,
];

type Fetcher<K, V> = (key: K, signal: AbortSignal) => Promise<V | undefined | void> | V | undefined | void;

const ERROR_NO_PROVIDER = (): never => { throw new Error('useLastThingState must be inside a LastThingStateProvider'); };

export const LastThingStateContext = createContext<ContextProps>([ERROR_NO_PROVIDER, ERROR_NO_PROVIDER]);

const lastThingEventFetcher = (axios: AxiosInstance) => {
  return (thingId: string, signal: AbortSignal) => axios.get<PaginatedResponse<LastThingEvent>>(`/api/v2/last-thing-event/thing/${thingId}`, { params: { limit: 500, sort: '-occurred' }, signal })
    .then((result) => {
      return {
        byFeature: result.data.items.reduce<LastThingEventsByFeature>((acc, evt) => ({ ...acc, [evt.feature]: { ...acc[evt.feature], [evt.eventTypeId]: evt } }), {}),
        latest: result.data.items[0],
      };
    });
};

export const LastThingStateProvider: FC<PropsWithChildren<Props>> = ({ cacheTtl = Duration.fromObject({ minute: 1 }), children }) => {
  const auth = useAuthenticated();
  const fetcher = useRef<Fetcher<string, LastThingEvents>>(lastThingEventFetcher(auth.axios));
  useEffect(() => {
    fetcher.current = lastThingEventFetcher(auth.axios);
  }, [auth.axios]);

  const value = useMemo<ContextProps>(() => {
    const cache = new LRUCache<string, LastThingEvents>({
      max: 10000,
      ttl: cacheTtl.toMillis(),
      fetchMethod: (thingId, _, { signal }) => {
        return fetcher.current(thingId, signal);
      },
    });
    return [
      (thingId) => cache.fetch(thingId),
      (thingId) => cache.delete(thingId),
    ];
  }, [auth.user, auth.switchedTokenProvider]);

  return (
    <LastThingStateContext.Provider value={value}>
      {children}
    </LastThingStateContext.Provider>
  );
};

export const useLastThingState = (): ContextProps => useContext(LastThingStateContext);

