/* eslint-disable react-hooks/exhaustive-deps */
import { AlertResponse } from '@eagle/core-data-types';
import { DateTime } from 'luxon';
import { useSnackbar } from 'notistack';
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { from, of, Subject } from 'rxjs';
import { catchError, debounceTime, finalize, switchMap, tap } from 'rxjs/operators';
import { useAuthenticated } from '../../auth';
import { AppliedFilterType, ErrorMessage, FilterPathIdentifiers } from '../../components';
import { DateFilterField } from '../../components/date-time-range/date-time-range.types';
import { AppliedFilter, Pagination } from '../../components/entity-search/types';
import { FilterFieldProps, FilterTypes } from '../../components/filter';
import { FILTERS_KEY } from '../../components/filter/filter-builder';
import { KeyHandlerWrapper } from '../../components/wrapper/key-handler-wrapper';
import { SEARCH_DEBOUNCE, T_ONE } from '../../constants';
import { useObservable, useTitle } from '../../hooks';
import { PageAction } from '../../types/page-action';
import { addLocalStorageItem, getLocalStorageItem, setLocalStorageItem } from '../../util/local-storage';
import { AlertsQuery, DateRange, EntityType, FindItemsDeferredResult } from '../list/types';
import { SearchProvider, useSearch } from '../list/use-search';
import { AlertListPageView } from './view';
import { AlertEntityTypes, AlertTabKey, AlertTabTypes } from './view.types';

export const ALERT_LIST_KEY = 'alert-list';

interface Props {
  actions?: PageAction[];
  'data-testid'?: string;
  dateOptions: DateFilterField[];
  defaultDateLabel?: string;
  defaultDateRange: DateRange;
  entityTypes: AlertEntityTypes<EntityType[]>;
  icon?: JSX.Element;
  onQueryChanged: (query: AlertsQuery) => FindItemsDeferredResult<AlertResponse>;
  onRefresh: boolean;
  renderContent: (alerts: AlertResponse[], isLoading: boolean, matchCount: number, pagination: Pagination, setPagination: (value: Pagination) => void) => JSX.Element;
  renderFilterContent?: (
    filters: AppliedFilter<AppliedFilterType>[],
    setFilterOpen: (value: boolean) => void,
    onFiltersChanged: (filters: AppliedFilter<AppliedFilterType>[]) => unknown,
  ) => JSX.Element;
  setLastRefresh: (value: Date) => void;
  setRefresh: Dispatch<SetStateAction<boolean>>;
  title: string;
  useNewFilter?: boolean;
}

export const InternalAlertListPageController = ({
  actions,
  dateOptions,
  defaultDateLabel,
  defaultDateRange,
  entityTypes,
  icon,
  onQueryChanged,
  onRefresh = false,
  renderContent,
  renderFilterContent,
  setLastRefresh,
  setRefresh,
  title,
  useNewFilter,
  ...props
}: Props): JSX.Element => {
  useTitle(title);
  const { t } = useTranslation(['common', 'terms']);
  const { enqueueSnackbar } = useSnackbar();
  const [filterOpen, setFilterOpen] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const [tabFilters, setTabFilters] = useState<AlertTabTypes<AppliedFilter[]>>({ alerts: [], things: [], persons: [] });
  const { userInfo, account } = useAuthenticated();

  const alertTabFilters: AlertTabTypes<FilterFieldProps[]> = useMemo(() => ({
    alerts: [
      {
        entityTypes: entityTypes.alerts,
        propertyLabel: t('common:common.labels.type'),
        typePropertyName: FilterTypes.ALERT_TYPE,
      },
      {
        entityTypes: entityTypes.persons,
        pathIdentifier: FilterPathIdentifiers.PERSON,
        propertyLabel: t('terms:person', { count: T_ONE }),
        typePropertyName: FilterTypes.PERSON,
      },
      {
        entityTypes: entityTypes.things,
        pathIdentifier: FilterPathIdentifiers.THING,
        propertyLabel: t('terms:thing', { count: T_ONE }),
        typePropertyName: FilterTypes.THING,
      },
    ],
    things: [
      {
        entityTypes: entityTypes.thingTypes,
        propertyLabel: t('common:common.labels.type'),
        typePropertyName: FilterTypes.THING_TYPE,
      },
      {
        entityTypes: entityTypes.group,
        propertyLabel: t('common:terms.group', { count: T_ONE }),
        typePropertyName: FilterTypes.GROUP,
      },
    ],
    persons: [
      {
        entityTypes: entityTypes.personTypes,
        propertyLabel: t('common:common.labels.type'),
        typePropertyName: FilterTypes.PERSON_TYPE,
      },
      {
        entityTypes: entityTypes.group,
        propertyLabel: t('common:terms.group', { count: T_ONE }),
        typePropertyName: FilterTypes.GROUP,
      },
    ],
  }), [entityTypes, t]);

  const {
    dateRange,
    isLoading,
    pagination,
    result: resultData,
    savedSelection,
    setDateRange,
    setIsLoading,
    setPagination,
    setResult,
    text,
  } = useSearch();

  const onError = (error: Error): void => {
    enqueueSnackbar(<ErrorMessage error={error} />, { variant: 'error' });
  };

  const removeFilter = useCallback((filterToRemove: AppliedFilter, key: AlertTabKey) => {
    const updateFilters = tabFilters[key].filter((filterItem) => filterItem !== filterToRemove);
    addLocalStorageItem(FILTERS_KEY, { [key]: updateFilters });
    setTabFilters({ ...tabFilters, [key]: updateFilters });
  }, [tabFilters]);

  const setNewFilter = (filters: AppliedFilter<AppliedFilterType>[]): void => {
    setTabFilters({ ...tabFilters, alerts: filters });
  };

  const { handleQueryChanged, observable } = useMemo(() => {
    const subject = new Subject<AlertsQuery>();

    return {
      handleQueryChanged: (query: AlertsQuery): void => subject.next(query),
      observable: subject.pipe(
        debounceTime(SEARCH_DEBOUNCE),
        switchMap((query: AlertsQuery) => {
          const deferred = onQueryChanged(query);
          if (!deferred) return of(undefined);

          let complete = false;

          return from(deferred.promise).pipe(
            tap(({ result }) => {
              setResult({ itemCount: result.results.length, matchCount: result.itemCount });
              complete = true;
            }),
            catchError((err: Error) => {
              onError(err);
              return of(undefined);
            }),
            finalize(() => complete || deferred.cancel()),
          );
        }),
        tap(() => setIsLoading(false)),
      ),
    };
  }, [onQueryChanged]);

  const data = useObservable(observable, onError);

  useEffect(() => {
    const savedPagination = getLocalStorageItem(ALERT_LIST_KEY, { pagination }).pagination;
    savedPagination && setPagination(savedPagination);
  }, [account]);

  useEffect(() => {
    setTabFilters({
      things: useNewFilter ? [] : getLocalStorageItem(FILTERS_KEY, { things: [] }).things ?? [],
      persons: useNewFilter ? [] : getLocalStorageItem(FILTERS_KEY, { persons: [] }).persons ?? [],
      alerts: getLocalStorageItem(FILTERS_KEY, { alerts: [] }).alerts ?? [],
    });
  }, [account]);

  useEffect(() => {
    setIsLoading(true);
    handleQueryChanged({ filters: tabFilters, pagination, search: text, dateRange, savedSelection });
  }, [tabFilters, pagination, text, dateRange, onRefresh, savedSelection, userInfo.accountId]);

  useEffect(() => {
    setLocalStorageItem(ALERT_LIST_KEY, {
      pagination,
    });
  }, [pagination]);

  return (
    <KeyHandlerWrapper
      filterOpen={filterOpen}
      pagination={pagination}
      resultData={resultData}
      setFilterOpen={setFilterOpen}
      setPagination={setPagination}
    >
      <AlertListPageView
        actions={actions}
        alertTabFilters={alertTabFilters}
        appliedFilters={tabFilters}
        data-testid={props['data-testid']}
        dateOptions={dateOptions}
        dateRange={dateRange}
        defaultDateLabel={defaultDateLabel}
        defaultDateRange={defaultDateRange}
        filterDrawerComponent={renderFilterContent?.(tabFilters.alerts, setFilterOpen, setNewFilter)}
        icon={icon}
        listContent={renderContent(data?.result.results ?? [], isLoading, data?.result.itemCount ?? 0, pagination, setPagination)}
        onDateRangeChanged={(startToDate, endToDate) => {
          setDateRange({
            startTime: DateTime.fromJSDate(startToDate),
            endTime: DateTime.fromJSDate(endToDate),
          });
          setRefresh((prev) => !prev);
          setLastRefresh(new Date());
        }}
        onFiltersChanged={setTabFilters}
        openDateRange={searchOpen}
        openFilter={filterOpen}
        removeAllFilters={() => {
          if (useNewFilter) {
            addLocalStorageItem(FILTERS_KEY, { alerts: [] });
            setTabFilters({ ...tabFilters, alerts: [] });
            return;
          }
          setLocalStorageItem(FILTERS_KEY, { alerts: [], things: [], persons: [] });
          setTabFilters({ alerts: [], things: [], persons: [] });
        }}
        removeFilter={removeFilter}
        setDateRangeOpen={setSearchOpen}
        setFilterOpen={setFilterOpen}
        subtitle={data?.resultDescription}
        title={title}
      />
    </KeyHandlerWrapper>
  );
};

export const AlertListPageController = (props: Props): JSX.Element => {
  return (
    <SearchProvider dataKey="alert-list" pagination={{ limit: 50, skip: 0 }}>
      <InternalAlertListPageController {...props} />
    </SearchProvider>
  );
};
