import { Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { compact } from 'lodash';
import { FC } from 'react';

export const isValidRegex = (pattern: string): boolean => {
  if (!pattern.trim().length) return false;
  try {
    RegExp(pattern);
  } catch {
    return false;
  }
  return true;
};

export const BoldMatchedText: FC<{ text: string; query: string; sx?: SxProps<Theme> }> = ({ text, query, sx }) => {
  const cleanInput = query.replace(/[^a-zA-Z0-9\s&]/g, (match) => `\\${match}`);
  const regex = new RegExp(`(${cleanInput.replace(/(\s|&)(?!\1*$)/g, '|')})`, 'gi');
  const stringArray = compact(text.split(regex));
  return <Typography component="span" noWrap>
    {stringArray.map((matches, i) => (
      <Typography
        component="span"
        key={`${matches}-${i}`}
        sx={{ fontWeight: regex.test(matches) ? '800' : 'inherit', ...sx }}
      >
        {matches}
      </Typography>
    ))}
  </Typography>;
};

export const HeaderWithSpaces: FC<{ header: string }> = ({ header }) => (
  <span style={{ textTransform: 'capitalize' }}>{header.replace(/([A-Z])/g, ' $1').trim()}</span>
);
