import { PropertiesDefinition } from '@eagle/common';
import { PersonThingRange, Thing, ThingType } from '@eagle/core-data-types';
import { Chip, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { CacheDataTypes } from '../../types';
import { FetchOne, FetchOneOfAll } from '../fetch';
import { FormatTimestamp } from '../format';
import { Label } from '../label';
import { DataRow, LinkButton, PropertiesRows } from '../static-data';

interface PersonThingDetailProps {
  personThing: PersonThingRange;
  thing?: Thing;
}

export const PersonThingDetail: FC<PersonThingDetailProps> = ({ personThing }) => {
  const { t } = useTranslation(['common']);

  const personThingDetails = (thing: Thing, thingType: ThingType, properties: PropertiesDefinition, numberOfProperties = 2): JSX.Element => {
    return (
      <Stack direction="column" spacing={2}>
        <DataRow
          action={<LinkButton link={{ display: t('common:common.action.view'), url: `/thing/${thing._id}` }} />}
          label={thingType.display}
          value={<Typography>{thing.display}</Typography>}
        />
        <PropertiesRows properties={thing.properties} propertyDefinitions={properties} maxProperties={numberOfProperties} />
        {(thing.tags.length > 0 && thing.tags[0])
          && <DataRow
            label={t('common:terms.tag')}
            value={<>{thing.tags.map((tag, i: number) => {
              return <Chip key={i} label={tag} size="small" sx={{ marginRight: '4px' }} />;
            })}
            </>}
          />
        }
        {Object.keys(thing.labels).length > 0
          && <DataRow
            label={t('common:terms.label')}
            value={<>
              {Object.entries(thing.labels).map(([key, value], i: number) => {
                return <Label key={i} label={key} value={value} size="small" />;
              })}
            </>}
          />
        }
        {personThing.start
          && <Typography
            color="text.secondary"
            display="flex"
            fontStyle="italic"
            justifyContent="right"
            variant="body2"
          >
            {t('common:common.labels.assigned')}&nbsp;
            <FormatTimestamp value={personThing.start} format="relative" />
          </Typography>
        }
      </Stack>
    );
  };

  return (
    <FetchOne
      id={personThing.thingId}
      dataType={CacheDataTypes.THING}
      renderFactory={(thing: Thing) => (
        <FetchOneOfAll
          id={thing.thingTypeId}
          dataType={CacheDataTypes.THING_TYPE}
          renderFactory={(thingType: ThingType) => (
            personThingDetails(thing, thingType, thingType.properties)
          )}
        />
      )}
    />
  );
};
