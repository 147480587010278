import { ListItemButton, Paper } from '@mui/material';
import { SxProps } from '@mui/system';
import cx from 'classnames';
import { FC, PropsWithChildren } from 'react';
import { useHref } from 'react-router-dom';
import { makeStyles } from '../../theme';

const useStyles = makeStyles()((theme) => ({
  item: {
    cursor: 'pointer',
    '&:hover': {
      boxShadow: theme.shadows[5],
    },
  },
  selected: {
    borderLeft: '4px solid',
    borderLeftColor: theme.palette.primary.main,
    borderRight: '4px solid',
    borderRightColor: theme.palette.primary.main,
  },
}));

interface Props extends PropsWithChildren {
  'data-testid'?: string;
  className?: string;
  elevation?: number;
  href?: string;
  selected?: boolean;
  sx?: SxProps;
  deleted?: boolean;
  disableRipple?: boolean;
}

export const ListPaper: FC<Props> = ({ children, className, elevation = 1, href, selected = false, sx, deleted = false, disableRipple = false, ...props }): JSX.Element => {
  const { classes } = useStyles();
  const fullHref = useHref(href ?? '');

  return (
    <Paper
      data-testid={props['data-testid']}
      className={cx('ListPaper-root', { [classes.item]: !!href && !deleted, [classes.selected]: selected }, className)}
      elevation={elevation}
      sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
    >
      <ListItemButton
        data-testid="action-button"
        component={href ? 'a' : 'div'}
        href={fullHref}
        role="button"
        sx={{ ...sx, cursor: href ? 'pointer' : 'default' }}
        disableRipple={disableRipple}
      >
        {children}
      </ListItemButton>
    </Paper>
  );
};
