import { makeStyles } from '../../theme';

export const modalStyles = makeStyles()(() => ({
  calendarPicker: {
    marginTop: '-0.5rem',
    maxHeight: '330px',
    '& .MuiCalendarPicker-viewTransitionContainer': {
      overflowY: 'hidden',
    },
    overflow: 'hidden',
  },
}));
