import { Statuses } from '@eagle/common';
import { CssBaseline, Theme, ThemeOptions, ThemeProvider } from '@mui/material';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import axios from 'axios';
import { ResourceKey } from 'i18next';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { SnackbarProvider } from 'notistack';
import { createContext, FC, lazy, PropsWithChildren, StrictMode, Suspense, useContext, useEffect, useState } from 'react';
import { getI18n, I18nextProvider } from 'react-i18next';
import { createRoutesFromChildren, HashRouter as Router, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import { DomainCustomConfig, ProvideAuth } from '../../auth';
import { ConfigProvider, TitleProvider, useTitle, useUnregisterServiceWorker } from '../../hooks';
import { theme } from '../../theme';
import { Undefinable } from '../../types';
import { DEFAULT_CONFIG } from '../../util/config';
import { LD_DEFAULT_CONTEXT } from '../flags';
import { MiddleSpinner } from '../middle-spinner';
import { Snackbar } from '../snackbar';

const Loading: FC = () => {
  useTitle('⌛ ...');
  return <MiddleSpinner />;
};

const MATCH_FEATURE_FLAG = /\/featureflag\//;

interface ThemeContextMap {
  setDomainTheme: (domain: string) => void;
  domainTheme: string;
}

export const domainThemeContext = createContext<Undefinable<ThemeContextMap>>(undefined);

export const DomainThemeProvider: FC<PropsWithChildren> = ({ children }) => {
  const [createdTheme, setCreatedTheme] = useState<Theme>();
  const [domainTheme, setDomainTheme] = useState<string>('');

  const snackbarComponents = {
    success: Snackbar,
    error: Snackbar,
    info: Snackbar,
  };

  useEffect(() => {
    const getThemeJson = async (): Promise<ThemeOptions | undefined> => {
      const response = await axios.get<ThemeOptions>(`/mui/theme.json?domain=${domainTheme}`, { validateStatus: () => true });
      if (response.status === Statuses.OK) {
        return response.data;
      }
    };

    getThemeJson().then((themeData) => {
      setCreatedTheme(theme(themeData));
    }).catch(() => {
      setCreatedTheme(undefined);
    });
  }, [domainTheme]);

  return <domainThemeContext.Provider
    value={{
      setDomainTheme,
      domainTheme,
    }}
  ><ThemeProvider theme={createdTheme || {}}>
      <SnackbarProvider maxSnack={3} Components={snackbarComponents}>
        {children}
      </SnackbarProvider>
    </ThemeProvider>
  </domainThemeContext.Provider>;
};

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export const useDomainTheme = function (): ThemeContextMap {
  const data = useContext(domainThemeContext);
  if (!data) throw new Error('Missing DomainThemeProvider in tree above useDomainThemeContext');
  return data;
};

const DomainConfigProvider = lazy(async () => {
  const response = await axios.get<DomainCustomConfig>('/api/config', { validateStatus: () => true });
  const config = response.status === Statuses.OK ? response.data : DEFAULT_CONFIG;
  const commitSHA = import.meta.env.VITE_COMMIT_SHA || import.meta.env.REACT_APP_COMMIT_SHA; // TODO: DEV-371
  const baseUrl = import.meta.env.BASE_URL || '/';

  const DomainI18nProvider: FC<PropsWithChildren> = ({ children }) => {
    const i18n = getI18n();
    const locale = i18n.language || 'en';

    useEffect(() => {
      const loadTranslations = async (): Promise<void> => {
        if (config.localazy && config.localazy.terms) {
          const urls = [`${baseUrl}/locales/${config.localazy.terms}/${locale}/terms.json`];

          const parts = locale.split('-');
          if (parts.length === 2) {
            urls.push(`${baseUrl}/locales/${config.localazy.terms}/${parts[0]}/terms.json`);
          }

          const responses = await Promise.all(urls.map((url) => axios.get<ResourceKey>(url, { validateStatus: () => true })));

          const translations = responses
            .map((response) => response.status === Statuses.OK ? response.data : {})
            .reduce((acc, response) => Object.assign({}, response, acc) as ResourceKey, {} as ResourceKey);

          // Override the terms resource bundle only if translations are available
          if (Object.keys(translations).length > 0) {
            i18n.addResourceBundle(locale, 'terms', translations, false, true);
          }
        }
      };

      void loadTranslations();
    }, [i18n, locale]);

    return (
      <I18nextProvider i18n={i18n}>
        {children}
      </I18nextProvider>
    );
  };

  if (config.sentry?.dsn && commitSHA && config.environment !== 'test') {
    const dsn = config.sentry.dsn
      .replace('https://', `${location.protocol}//`)
      .replace(/[a-z0-9]+\.ingest\.sentry\.io\//, `${location.host}/performance/`);
    Sentry.init({
      dsn,
      environment: config.environment,
      release: `ethings-portals-${commitSHA}`,

      integrations: [
        new BrowserTracing({
          routingInstrumentation: Sentry.reactRouterV6Instrumentation(
            useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
          ),
          shouldCreateSpanForRequest: (url) => {
            // Do not create spans for outgoing requests to a `/featureflag/` endpoint
            return !url.match(MATCH_FEATURE_FLAG);
          },
        }),
        new Sentry.Replay({
          maskAllText: false,
          maskAllInputs: false,
        }),
      ],

      denyUrls: [MATCH_FEATURE_FLAG],

      beforeBreadcrumb: (breadcrumb, hint) => {
        const xhr = hint?.xhr as XMLHttpRequest | undefined;
        return breadcrumb.category === 'xhr' && xhr?.responseURL.match(MATCH_FEATURE_FLAG) ? null : breadcrumb;
      },

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: config?.sentry?.tracesSampleRate ?? 0.2,

      // This sets the sample rate to be 10%. You may want this to be 100% while
      // in development and sample at a lower rate in production
      replaysSessionSampleRate: config?.sentry?.replaysSessionSampleRate ?? 0.1,

      // If the entire session is not sampled, use the below sample rate to sample
      // sessions when an error occurs.
      replaysOnErrorSampleRate: config?.sentry?.replaysOnErrorSampleRate ?? 0.5,
    });
  }

  if (!config.launchDarkly) {
    const Provider: FC<PropsWithChildren> = ({ children }) => <ConfigProvider config={config}><DomainI18nProvider>{children}</DomainI18nProvider></ConfigProvider>;
    return { default: Provider };
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const noop = () => { }; // No Operation function, does nothing

  const silentLogger = {
    debug: noop,
    error: noop,
    info: noop,
    warn: noop,
  };

  // cSpell: ignore featureflag
  const FlagProvider = await asyncWithLDProvider({
    clientSideID: config.launchDarkly.clientSideId,
    context: LD_DEFAULT_CONTEXT,
    options: {
      baseUrl: '/featureflag',
      bootstrap: 'localStorage',
      eventsUrl: '/featureflag',
      logger: silentLogger,
      streamUrl: '/featureflag',
    },
    reactOptions: {
      useCamelCaseFlagKeys: false,
    },
  });

  const Provider: FC<PropsWithChildren> = ({ children }) => (
    <ConfigProvider config={config}>
      <Suspense fallback={<Loading />}>
        {config.launchDarkly ? (
          <FlagProvider>
            <DomainI18nProvider>{children}</DomainI18nProvider>
          </FlagProvider>
        ) : (
          <DomainI18nProvider>{children}</DomainI18nProvider>
        )}
      </Suspense>
    </ConfigProvider>
  );

  return { default: Provider };
});

export const AppWrapper: FC<PropsWithChildren> = ({ children }) => {
  useUnregisterServiceWorker();

  return (
    <StrictMode>
      <TitleProvider>
        <Suspense fallback={<Loading />}>
          <DomainConfigProvider>
            <DomainThemeProvider>
              <CssBaseline />
              <ProvideAuth>
                <Router>
                  {children}
                </Router>
              </ProvideAuth>
            </DomainThemeProvider>
          </DomainConfigProvider>
        </Suspense>
      </TitleProvider>
    </StrictMode>
  );
};
