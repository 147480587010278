/* eslint-disable react-hooks/rules-of-hooks */
import { Person, PersonThingRange, Thing, ThingType } from '@eagle/core-data-types';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { CardHeader, IconButton, Menu, MenuItem, Stack, Typography } from '@mui/material';
import { MouseEvent, useState } from 'react';
import { CacheDataTypes, Nullable, Portal, PortalTypes, Undefinable } from '../../types';
import { getMenuItems, switchToPortal } from '../../util';
import { FetchOne, FetchOneOfAll } from '../fetch';
import { useBoolFlag } from '../flags';

interface DefaultProps {
  actions?: JSX.Element;
  excludePortals?: Portal[];
  checkFeatureFlag?: boolean;
  portal: Portal;
  title?: string;
}

interface Props<T extends Thing | PersonThingRange | Person> extends DefaultProps {
  'data-testid'?: string;
  entity: T;
  type?: typeof PortalTypes[keyof typeof PortalTypes];
}

export const PortalSwitchingDropdown = <T extends Thing | PersonThingRange | Person>({ actions, excludePortals = [], entity, type, title, portal: selectedPortal, checkFeatureFlag = true, ...props }: Props<T>): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<Nullable<HTMLElement>>(null);
  const portalFeature = checkFeatureFlag ? useBoolFlag('portals-portal-switching-component') : true;

  const allPortals = getMenuItems();
  const filteredPortals = allPortals.filter(({ portal }) => portal !== selectedPortal && !excludePortals?.includes(portal));
  const portalWithPermission = filteredPortals.some(({ permissions }) => permissions);

  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLButtonElement>): void => setAnchorEl(event.currentTarget);
  const handleClose = (): void => setAnchorEl(null);
  const redirectToPortal = (portal: Portal): Undefinable<string> => {
    const entityId = type === PortalTypes.THING_PERSON_TYPE ? (entity as PersonThingRange).personId : (entity as Person | Thing)._id;
    return switchToPortal(selectedPortal, portal, `${type === PortalTypes.THING_TYPE ? 'thing' : 'person'}/${entityId}`);
  };

  const cardActions = (data: ThingType | Person): JSX.Element => <>
    {actions}
    {portalFeature && portalWithPermission
      && <>
        <IconButton onClick={handleClick} data-testid="portal-switching-button">
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          onClose={handleClose}
          open={open}
        >
          {filteredPortals.map(({ icon, permissions, portal, portalText, text, sx }, i) => {
            if (!permissions) return <></>;
            return <MenuItem
              data-testid={`portal-switching-menu-item-${portal}`}
              key={`${i}`}
              onClick={() => {
                const redirect = redirectToPortal(portal);
                if (redirect) {
                  window.location.href = redirect;
                }
              }}
              sx={{ ...sx, mt: 0, pl: 1.5 }}
            >
              <>
                {icon}
                <Stack sx={{ pl: 1 }}>
                  <Typography>{text(data.display)}</Typography>
                  <Typography variant="body2" color="text.secondary">{portalText}</Typography>
                </Stack>
              </>
            </MenuItem>;
          })}
        </Menu>
      </>
    }
  </>;

  if (!entity) return <></>;

  const renderData = (data: ThingType | Person): JSX.Element => (
    <CardHeader
      action={cardActions(data)}
      title={
        <Typography variant="h5" sx={{ textTransform: 'capitalize' }} {...props}>{title ?? data.display}</Typography>
      }
    />
  );

  if (type !== CacheDataTypes.THING_TYPE) {
    return <FetchOne
      id={type === PortalTypes.THING_PERSON_TYPE ? (entity as PersonThingRange).personId : (entity as Person)._id}
      dataType={CacheDataTypes.PERSON}
      renderFactory={renderData}
    />;
  }

  return (
    <FetchOneOfAll
      id={(entity as Thing).thingTypeId}
      dataType={CacheDataTypes.THING_TYPE}
      renderFactory={renderData}
    />
  );
};
