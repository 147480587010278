/* eslint-disable react-hooks/exhaustive-deps */
import { Account, UiTemplate } from '@eagle/core-data-types';
import { useState } from 'react';
import { useAuthenticated } from '../auth';
import { CacheDataTypes } from '../types';
import { useFetchAllCache } from './use-cache';
import { usePromiseConditional } from './use-promise';

interface UiTemplateResponse {
  template: object;
  loaded: boolean;
}

export const useUiTemplate = (id: string, fallback: object, additionalParams?: Record<string, string>, deps: string[] = [], enabled = true): UiTemplateResponse => {
  const [template, setTemplate] = useState<object>(fallback);
  const [loaded, setLoaded] = useState<boolean>(false);
  const { axios, userInfo } = useAuthenticated();
  const accountDetailCache = useFetchAllCache(CacheDataTypes.ACCOUNT);

  usePromiseConditional(async () => {
    const account = await accountDetailCache.one<Account>(userInfo.accountId);

    axios.get<UiTemplate[]>(`/api/v1/ui-template/${id}`, {
      params: {
        accountId: userInfo.accountId,
        domain: account?.homeDomain,
        ...additionalParams,
      },
    }).then((templateResponse) => {
      const uiTemplate = templateResponse.data[0].data || fallback;
      setTemplate(uiTemplate);
      setLoaded(true);
    }).catch(() => {
      setTemplate(fallback);
      setLoaded(true);
    });
  }, () => enabled, [id, ...deps]);
  return { template, loaded };
};
