/* eslint-disable react-hooks/exhaustive-deps */
import { Thing } from '@eagle/core-data-types';
import { ThingEvent } from '@eagle/data-function-types';
import { useSnackbar } from 'notistack';
import { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { from, of, Subject } from 'rxjs';
import { catchError, debounceTime, finalize, switchMap, tap } from 'rxjs/operators';
import { useObservable } from '../../hooks';
import { Query } from '../../pages/list/types';
import { SearchProvider, useSearch } from '../../pages/list/use-search';
import { FindItemsDeferredPaginatedResponse } from '../alerts-table/types';
import { AppliedFilter, Pagination } from '../entity-search/types';
import { ErrorMessage } from '../error-message';
import { EventsTableView } from './view';

export interface Props {
  appliedFilters: AppliedFilter[];
  fixHeight?: boolean;
  hideCategory?: boolean;
  onQueryChanged: (query: Query, pagination: Pagination) => FindItemsDeferredPaginatedResponse<ThingEvent>;
  rowChildren?: (event: ThingEvent) => JSX.Element;
  thing: Thing;
}

const InternalEventsTableController: FC<Props> = ({ appliedFilters, fixHeight = false, hideCategory = false, onQueryChanged, rowChildren, thing }): JSX.Element => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(['common']);
  const {
    filters,
    setFilters,
    isLoading,
    pagination,
    result,
    setIsLoading,
    setResult,
    text,
  } = useSearch();

  const onError = (error: Error): void => {
    enqueueSnackbar(<ErrorMessage error={error} />, { variant: 'error' });
  };

  const { handleQueryChanged, observable } = useMemo(() => {
    const subject = new Subject<Query>();

    return {
      handleQueryChanged: (query: Query): void => subject.next(query),
      observable: subject.pipe(
        tap(() => setIsLoading(true)),
        debounceTime(350),
        switchMap((query: Query) => {
          if (!appliedFilters.length) return of(undefined);
          const deferred = onQueryChanged(query, pagination);
          if (!deferred) return of(undefined);

          let complete = false;

          return from(deferred.promise).pipe(
            tap(({ count, items }) => {
              setResult({ itemCount: items.length, matchCount: count ?? 0 });
              complete = true;
            }),
            catchError(() => {
              onError(new Error(t('common:component.events.hint.error', { display: thing.display })));
              return of(undefined);
            }),
            finalize(() => complete || deferred.cancel()),
          );
        }),
        tap(() => setIsLoading(false)),
      ),
    };
  }, [onQueryChanged, pagination, appliedFilters]);
  const data = useObservable(observable, onError);

  useEffect(() => {
    handleQueryChanged({ filters, pagination, search: text });
  }, [filters, pagination, text]);

  useEffect(() => {
    setFilters(appliedFilters);
  }, [appliedFilters]);

  return (
    <EventsTableView
      events={data?.items || []}
      fixHeight={fixHeight}
      hasMore={data?.hasMore ?? false}
      hideCategory={hideCategory}
      isLoading={isLoading}
      matchCount={result?.matchCount ?? 0}
      rowChildren={rowChildren}
    />
  );
};

export const EventsTableController = (props: Props): JSX.Element => {
  return (
    <SearchProvider dataKey="events-table" pagination={{ limit: 5, skip: 0 }}>
      <InternalEventsTableController {...props} />
    </SearchProvider>
  );
};
