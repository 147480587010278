import { captureException } from '@sentry/react';
import { LDMultiKindContext } from 'launchdarkly-js-client-sdk';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import { useEffect } from 'react';
import UAParser from 'ua-parser-js';
import { AuthStatus, useAuth } from '../../auth';

export { useFlags } from 'launchdarkly-react-client-sdk';

const parser = new UAParser();

const toKey = (...rest: (string | undefined)[]): string => {
  const defined = rest.filter((item) => !!item);
  if (!defined.length) return 'unknown';
  return defined.join(' ');
};

const agent = parser.getResult();
const commonContext = {
  browser: {
    kind: 'browser',
    key: toKey(agent.browser.name, agent.browser.version),
    name: agent.browser.name,
    version: agent.browser.version,
  },
  engine: {
    kind: 'engine',
    key: toKey(agent.engine.name, agent.engine.version),
    name: agent.engine.name,
    version: agent.engine.version,
  },
  os: {
    kind: 'os',
    key: toKey(agent.os.name, agent.os.version),
    name: agent.os.name,
    version: agent.os.version,
  },
  device: {
    kind: 'device',
    key: toKey(agent.device.vendor, agent.device.model, agent.device.type),
    vendor: agent.device.vendor,
    model: agent.device.model,
    type: agent.device.type,
  },
  domain: {
    kind: 'domain',
    key: toKey(location.hostname),
    hostname: location.hostname,
  },
};

export const LD_DEFAULT_CONTEXT: LDMultiKindContext = {
  kind: 'multi',
  ...commonContext,
  user: {
    kind: 'user',
    key: 'anonymous',
    anonymous: true,
  },
};

export const useFlagIdentity = (): void => {
  const { state } = useAuth();
  const client = useLDClient();

  useEffect(() => {
    if (!client) {
      console.log('useFlagIdentity', 'Flag provider not yet set', state);
      return;
    }
    if (!state || state.status !== AuthStatus.AUTHENTICATED) {
      client.identify(LD_DEFAULT_CONTEXT).catch(captureException);
      return;
    }

    const { user, account } = state;
    client.identify({
      kind: 'multi',
      ...commonContext,
      user: {
        kind: 'user',
        key: user._id,
        email: user.email,
        labels: user.labels,
        name: user.display,
        tags: user.tags,
      },
      account: {
        kind: 'account',
        key: account._id,
        labels: account.labels,
        name: account.display,
        tags: account.tags,
      },
    }).catch(captureException);
  }, [state, client]);
};

export const useBoolFlag = (flag: string): boolean | undefined => {
  const flags = useFlags();
  if (flag in flags) return Boolean(flags[flag]);
  return undefined;
};

export const useNumberFlag = (flag: string): number | undefined => {
  const flags = useFlags();
  if (flag in flags) return Number(flags[flag]);
  return undefined;
};

export const useStringFlag = (flag: string): string | undefined => {
  const flags = useFlags();
  if (flag in flags) return String(flags[flag]);
  return undefined;
};
