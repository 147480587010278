import { Container, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Player } from '../../components';
import { useTitle } from '../../hooks';

export const NotFound: FC = () => {
  const { t } = useTranslation(['common']);
  useTitle(t('common:page.errors.not-found.title'));

  return (
    <Container>
      <Typography
        sx={{ mt: '15vh', textAlign: 'center' }}
      >
        {t('common:page.errors.not-found.hint')}
      </Typography>

      <Player
        src="https://assets4.lottiefiles.com/packages/lf20_j3gumpgp.json"
        style={{ maxWidth: 400, maxHeight: 400, marginTop: '5vh' }}
      />
    </Container>
  );
};
