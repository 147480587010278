import { Skeleton } from '@mui/material';
import { get, isString } from 'lodash';
import { FC, useEffect, useState } from 'react';
import { useConfig } from '../../hooks';
import { getLocationWeather } from '../../util';

interface Props {
  append?: string;
  latLng: L.LatLng;
  path: string;
  units?: 'metric' | 'imperial';
}

export const WeatherField: FC<Props> = ({ append = '', latLng, path = 'temperature', units }) => {
  const [value, setValue] = useState<string | number>();
  const [isLoading, setLoading] = useState<boolean>(true);
  const config = useConfig();

  useEffect(() => {
    setLoading(true);
    getLocationWeather(latLng, units, config.hereMaps?.apiKey)
      .then((data) => {
        const fieldInfo = get(data, path);
        setValue(fieldInfo);
      })
      .catch(() => {
        setValue('');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [latLng, path, config, units]);

  if (!isString(path) || !value) {
    return null;
  }

  if (isLoading) {
    return <Skeleton />;
  }

  return <>{`${value}${append}`}</>;
};
