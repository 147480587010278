import { Box, CircularProgress, Fade, Paper } from '@mui/material';
import { SxProps } from '@mui/system';
import { visuallyHidden } from '@mui/utils';
import { FC, PropsWithChildren } from 'react';
import { centered } from '../../style/sx';

interface Props extends PropsWithChildren {
  size?: string | number;
  sx?: SxProps;
}

const DEFAULT_FADE_TIMEOUT = 1000;

export const Spinner: FC<Props> = ({ size, sx }) => {
  return <>
    <CircularProgress role="status" size={size} sx={sx} />
    <span style={visuallyHidden}>Loading...</span>
  </>;
};

export const MiddleSpinner: FC<Props> = ({ children, size, sx }) => {
  const sxProps = { ...centered, ...sx };
  return (
    <Box sx={sxProps}>
      <Spinner size={size} />
      {children}
    </Box>
  );
};

export const MiddleSpinnerOverlay: FC<Props & { fade?: boolean; timeout?: number }> = ({ children, fade = false, size, sx, timeout = DEFAULT_FADE_TIMEOUT }) => (
  <Fade in={fade} timeout={timeout}>
    <Paper
      sx={{
        position: 'absolute',
        height: '100%',
        width: '100%',
        zIndex: 1,
      }}
    >
      <MiddleSpinner size={size} sx={{ flexDirection: 'column', ...sx }}>
        {children}
      </MiddleSpinner>
    </Paper>
  </Fade>
);
