/* eslint-disable react-hooks/exhaustive-deps */
import { PaginatedResponse } from '@eagle/api-types';
import { MediaSegmentData, MediaType } from '@eagle/core-data-types';
import { TabList, TabPanel } from '@mui/lab';
import { Button, Stack, Tab, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useAuthenticated } from '../../auth';
import { usePromise } from '../../hooks';
import { Undefinable } from '../../types';
import { getSignedUrl } from '../../util';
import { CarouselOverlay } from '../carousel-overlay';
import { AlertPageMediaRow } from './alert-page-media-row';
import { MediaCardView } from './media-card-view';
import { MediaBundler, MediaData, MediaHasMoreBundle, MediaListPageType, MediaTabs, NavigateState } from './media-data.types';
import { ThingPageMediaRow } from './thing-page-media-row';

interface Props {
  'data-testid'?: string;
  displayOnCard?: boolean;
  entityDisplay: string;
  entityId: string;
  eventIndex?: number;
  features?: string[];
  getMediaData: (feature: Undefinable<string>, mediaType: MediaType, skip?: number, isDaysLimit?: boolean) => Promise<PaginatedResponse<MediaSegmentData>>;
  navigateState?: NavigateState;
  pageType: MediaListPageType;
  showButton?: boolean;
  timeZone?: string;
}

export const MediaCardController: FC<Props> = ({
  displayOnCard = false,
  entityDisplay,
  entityId,
  eventIndex,
  features = [],
  getMediaData,
  navigateState,
  pageType,
  showButton = true,
  timeZone,
  ...props
}) => {
  const navigate = useNavigate();
  const { axios } = useAuthenticated();
  const { t } = useTranslation(['common']);
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);
  const [mediaFeature, setMediaFeature] = useState('');
  const [mediaIndex, setMediaIndex] = useState(0);
  const [hasMoreBundle, setHasMoreBundle] = useState<MediaHasMoreBundle>({ images: {}, videos: {} });
  const [isLoading, setIsLoading] = useState(true);
  const [selectedTab, setSelectedTab] = useState<MediaTabs>(MediaTabs.IMAGES);
  const mediaDaysLimit = pageType === MediaListPageType.THINGS;
  const [mediaData, error, status] = usePromise<MediaData>(
    async () => {
      const requests = Object.values(MediaType).map((mediaType) => {
        if (!features.length) return getMediaData(undefined, mediaType, undefined, mediaDaysLimit);
        return features.map((feature) => getMediaData(feature, mediaType, undefined, mediaDaysLimit));
      });

      const response = (await Promise.all(requests.flat()));
      const responseItems = response.flatMap((obj) => obj.items);
      const imageItems = await getImageItems(responseItems.filter(({ mediaType }) => mediaType === MediaType.JPEG));

      setHasMoreBundle(createHasMoreBundle(response));
      return {
        imageItems,
        videoItems: responseItems.filter((item) => item.mediaType === MediaType.MP4),
      };
    }
    , [axios, eventIndex], // adding features will cause unneeded api re-call
  );

  const createHasMoreBundle = (responseData: PaginatedResponse<MediaSegmentData>[]): MediaHasMoreBundle => {
    const bundle = { ...hasMoreBundle };
    responseData.forEach(({ items, hasMore }) => {
      items.forEach(({ feature, mediaType }) => {
        if (!mediaType) return;
        const itemsMediaType = mediaType.replace(MediaType.JPEG, MediaTabs.IMAGES).replace(MediaType.MP4, MediaTabs.VIDEOS) as MediaTabs;
        bundle[itemsMediaType][feature] = hasMore;
      });
    });
    return bundle;
  };

  const [mediaItems, setMediaItems] = useState<MediaBundler[]>([
    { items: mediaData?.imageItems ?? [], tab: MediaTabs.IMAGES },
    { items: mediaData?.videoItems ?? [], tab: MediaTabs.VIDEOS },
  ]);

  const getImageItems = async (dataItems: MediaSegmentData[]): Promise<MediaSegmentData[]> => {
    const imageSignedUrlRequests = dataItems.map(({ url }) => getSignedUrl(axios, url?.toString()));
    const imageSignedUrls = await Promise.all(imageSignedUrlRequests);

    return dataItems.map((item, i) => {
      const signedUrl = imageSignedUrls[i].data.signedUrl;
      return {
        ...item,
        url: signedUrl ? new URL(signedUrl) : item.url,
      };
    });
  };

  const handleOverlayClose = (): void => setIsOverlayOpen(false);
  const handleTabChange = (_: React.SyntheticEvent, selected: MediaTabs): void => setSelectedTab(selected);
  const handleViewButtonClick = (): void => navigate(`/camera/${entityId}`, { state: navigateState });

  const handleItemClick = (currentIndex: number, feature: string): void => {
    setIsOverlayOpen(true);
    setMediaFeature(feature);
    setMediaIndex(currentIndex);
  };

  const handleShowMore = async (itemFeature: string, isDaysLimit?: boolean): Promise<void> => {
    const type = selectedTab === MediaTabs.IMAGES ? MediaType.JPEG : MediaType.MP4;
    const count = mediaItems.find((o) => o.tab === selectedTab)?.items.filter((item) => item.feature === itemFeature).length;

    const response = await getMediaData(itemFeature, type, count, isDaysLimit);
    const newDataItems = response.items;
    const newMediaItems = (type === MediaType.JPEG) ? await getImageItems(newDataItems.filter(({ mediaType }) => mediaType === MediaType.JPEG)) : newDataItems;

    setHasMoreBundle(createHasMoreBundle([response]));

    setMediaItems((prevMediaItems) => {
      const updatedItems = prevMediaItems?.map((mediaBundler) => {
        if (mediaBundler.tab === selectedTab) {
          return {
            ...mediaBundler,
            items: [...mediaBundler.items, ...newMediaItems],
          };
        }
        return mediaBundler;
      });
      return updatedItems;
    });
  };

  const renderTabs = (bundlers: MediaBundler[]): JSX.Element => (
    <TabList onChange={handleTabChange}>
      {bundlers.map(({ tab }) =>
        <Tab
          key={tab}
          data-testid={`action-tab-${tab}`}
          label={t(`common:common.labels.${tab}`)}
          sx={{ width: '25%' }}
          value={tab}
        />,
      )}
    </TabList>
  );

  const renderTabPanels = (bundlers: MediaBundler[]): JSX.Element => {
    return <>
      {bundlers.map(({ tab, items }) =>
        <TabPanel key={`${tab}-panel`} sx={{ m: 0, p: 0 }} value={tab}>
          {pageType !== MediaListPageType.ALERTS &&
            <Typography color="text.secondary" fontStyle="italic" variant="body2" sx={{ mx: 3, my: 2 }}>
              {t('common:component.media-data.hint.displaying-last', { count: 30 })}
            </Typography>
          }
          <Stack spacing={2} sx={{ mt: 2 }}>
            {pageType === MediaListPageType.ALERTS
              ? <AlertPageMediaRow
                data-testid="media-row-scroll"
                error={error}
                handleItemClick={handleItemClick}
                isEvent={Number(eventIndex) >= 0}
                isLoading={isLoading}
                mediaItems={items}
                timeZone={timeZone}
              />
              : features.map((feature) =>
                <ThingPageMediaRow
                  key={`${tab}-${feature}-media-row`}
                  error={error}
                  feature={feature}
                  handleItemClick={handleItemClick}
                  isEvent={Number(eventIndex) >= 0}
                  isLoading={isLoading}
                  mediaItems={items.filter((item) => item.feature === feature)}
                  hasMore={hasMoreBundle[tab]?.[feature] || false}
                  handleShowMore={handleShowMore}
                  mediaType={tab}
                />,
              )
            }
          </Stack>
        </TabPanel>,
      )}
    </>;
  };

  const renderMediaOverlay = (dataItems: MediaSegmentData[], tab: MediaTabs): JSX.Element => {
    if (!isOverlayOpen) return <></>;

    return (
      <CarouselOverlay
        currentIndex={mediaIndex}
        entityDisplay={entityDisplay}
        feature={mediaFeature}
        handleClose={handleOverlayClose}
        mediaItems={dataItems}
        open={isOverlayOpen}
        pageType={pageType}
        tab={tab}
      />
    );
  };

  const renderViewButton = (): JSX.Element => {
    if (!showButton) return <></>;

    return (
      <Button
        data-testid="action-button-view-camera-content"
        onClick={handleViewButtonClick}
        variant="text"
      >
        {t('common:component.media-data.action.view-camera-content')}
      </Button>
    );
  };

  useEffect(() => {
    setIsLoading(status === 'pending');
    if (!mediaData) return;
    setMediaItems([
      { items: mediaData.imageItems ?? [], tab: MediaTabs.IMAGES },
      { items: mediaData.videoItems ?? [], tab: MediaTabs.VIDEOS },
    ]);
    const { imageItems, videoItems } = mediaData;
    if (imageItems.length || !videoItems.length) return;
    setSelectedTab(MediaTabs.VIDEOS);
  }, [mediaData]);

  return (
    <MediaCardView
      displayOnCard={displayOnCard}
      mediaBundlers={mediaItems}
      renderMediaOverlay={renderMediaOverlay}
      renderTabPanels={renderTabPanels}
      renderTabs={renderTabs}
      renderViewButton={renderViewButton}
      selectedTab={selectedTab}
      data-testid={props['data-testid'] || 'media-card'}
    />
  );
};
