import { Theme } from '@mui/material';
import chroma from 'chroma-js';
import { addMapLangParams } from './utils';
import { vectorLayerStyles } from './vector-layer-style';

// cSpell:ignore camelcase, landuse
/* eslint-disable camelcase */

export const classicMapStyle = (theme: Theme, apiKey: string, enableSource = false, langCode = 'en'): Record<string, unknown> => {
  const isDarkBackground = chroma(theme.palette.background.paper).luminance() < 0.5;
  const opposite = isDarkBackground ? 'white' : 'black';
  const mix = (amount: number, towards = opposite): string => chroma.mix(theme.palette.background.paper, towards, amount, 'hsl').css();
  const mixAlpha = (amount: number, towards = opposite, alpha: number): string => chroma.mix(theme.palette.background.paper, towards, amount, 'hsl').alpha(alpha).css();
  const omvUrl = addMapLangParams(`https://vector.hereapi.com/v2/vectortiles/core/mc/{z}/{x}/{y}/omv?apikey=${apiKey}`, langCode, true);

  return {
    global: {
      language: navigator.language,
      language_text_source: "function() { return (global.language && feature['name:'+global.language]) || feature.name; }",
      sort_rank: 'function() { return (feature.sort_rank); }',
      baseColor: theme.palette.background.paper,
      trainTrack: mix(0.35),
      pedestrian: mix(0.35),
      parks: mix(0.15, '#7CB342'),
      roadLine: mix(0.1),
      regionBorderLine: '#A7A291',
      majorRoadLine: mix(0.17),
      roadCasing: mix(0.35),
      water: mix(0.15, '#1E88E5'),
      waterText: mix(0.55, '#1E88E5'),
      waterTextOutline: mixAlpha(0.25, '#1E88E5', 0.5),
      beach: mix(0.15, '#FFEB3B'),
      minorRoadText: mix(0.51),
      roadText: mix(0.6),
      roadTextOutline: mixAlpha(0.02, opposite, 0.5),
      placeText: mix(0.6),
      placeTextOutline: mixAlpha(0.02, opposite, 0.5),
      building: mix(0.04),
      suburbText: mix(0.75),
    },
    scene: {
      background: {
        color: 'global.baseColor',
      },
    },
    sources: enableSource ? {
      omv: {
        type: 'MVT',
        url: omvUrl,
        tile_size: 512,
      },
    } : {},
    styles: {
      _transparent: {
        base: 'polygons',
        blend: 'overlay',
      },
    },
    layers: vectorLayerStyles,
  };
};
