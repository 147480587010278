/* eslint-disable react-hooks/exhaustive-deps */
import { Group, Person, Thing } from '@eagle/core-data-types';
import { Card, CardContent, Stack, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthenticated } from '../../auth';
import { ConfirmDialogRadio, MiddleSpinner } from '../../components';
import { T_ONE } from '../../constants';
import { usePromise } from '../../hooks';
import { FILTER_OUT } from '../../util';
import { defaultEntity, EntityAutocomplete } from './entity-autocomplete';
import { EntityLookup } from './entity-lookup';
import { EntityDetail, EntityState, EntityTypes, FiltersRadioGroupState, RadioButtons, ThingPeopleFiltersCardProps } from './subscription-detail.types';

export const ThingPeopleFiltersCard: FC<ThingPeopleFiltersCardProps> = ({ alertFilters, fieldDisabled, getThingsPeopleState, setThingPeopleIsValid }) => {
  const { t } = useTranslation(['admin', 'common', 'terms']);
  const { groupId, personId, thingId } = alertFilters;
  const { axios } = useAuthenticated();

  const [selectedOption, setSelectedOption] = useState<EntityState>({
    group: {
      _id: groupId,
      display: '',
    },
    person: {
      _id: personId,
      display: '',
    },
    thing: {
      _id: thingId,
      display: '',
    },
  });

  const thingsOrPeopleOptions = [
    {
      label: t('admin:page.subscriptions-list.all-things-people.label'),
      value: RadioButtons.ALL_THING_PEOPLE,
    },
    {
      label: t('terms:thing', { count: T_ONE }),
      value: RadioButtons.THING,
    },
    {
      label: t('terms:person', { count: T_ONE }),
      value: RadioButtons.PERSON,
    },
  ];

  const individualOrGroupsOptions = [
    {
      label: t('admin:common.labels.individual'),
      value: RadioButtons.INDIVIDUAL,
    },
    {
      label: t('common:terms.group', { count: T_ONE }),
      value: RadioButtons.GROUP,
    },
  ];

  const [selectedRadio, setSelectedRadio] = useState<FiltersRadioGroupState<RadioButtons>>({
    thingsOrPeople: thingId || groupId ? RadioButtons.THING : personId ? RadioButtons.PERSON : RadioButtons.ALL_THING_PEOPLE,
    individualOrGroup: groupId ? RadioButtons.GROUP : RadioButtons.INDIVIDUAL,
  });

  const handleSelectedRadio = (propertyPath: keyof FiltersRadioGroupState<RadioButtons>, value: string): void => {
    setSelectedRadio((prev) => {
      return {
        ...prev,
        [propertyPath]: value,
      };
    });
  };

  const handleSelectedEntity = (entity: Thing | Person, key: string): void => {
    setSelectedOption((prev) => {
      return {
        ...prev,
        [key]: { _id: entity._id, display: entity.display },
      };
    });
  };

  const [groups, groupsError, groupsState] = usePromise<Group[]>(
    async () => {
      const result = await axios.get<Group[]>('/api/v1/group', {
        params: {
          filter: FILTER_OUT.deleted,
          sort: { display: 'asc' },
        },
      });
      return result.data;
    },
    [axios],
  );

  const computeSelectedRadio = (): EntityDetail => {
    if (selectedRadio.individualOrGroup === RadioButtons.INDIVIDUAL) {
      if (selectedRadio.thingsOrPeople === RadioButtons.THING) {
        return {
          key: EntityTypes.THING,
          alertFilterId: selectedOption.thing?._id,
          apiUrl: '/api/v1/thing',
          label: t('common:component.filter.labels.select-a-thing'),
          noResults: t('common:common.hint.list.no-results'),
        };
      }
      if (selectedRadio.thingsOrPeople === RadioButtons.PERSON) {
        return {
          key: EntityTypes.PERSON,
          alertFilterId: selectedOption.person?._id,
          apiUrl: '/api/v1/person',
          label: t('common:component.filter.labels.select-a-person'),
          noResults: t('common:common.hint.list.no-results'),
        };
      }
    }
    if (selectedRadio.individualOrGroup === RadioButtons.GROUP) {
      return {
        key: EntityTypes.GROUP,
        alertFilterId: selectedOption.group?._id,
        label: t('common:component.filter.labels.select-a-group'),
        options: { [EntityTypes.GROUP]: groups },
      };
    }
    return {};
  };

  const entityData = computeSelectedRadio();

  useEffect(() => {
    getThingsPeopleState(selectedOption);
  }, [selectedOption]);

  const isInvalid = (): boolean => {
    if (selectedRadio.thingsOrPeople === RadioButtons.ALL_THING_PEOPLE) return false;
    if (selectedRadio.individualOrGroup === RadioButtons.GROUP) return (!selectedOption.group?._id);
    return ((selectedRadio.thingsOrPeople === RadioButtons.THING && !selectedOption.thing?._id)
      || (selectedRadio.thingsOrPeople === RadioButtons.PERSON && !selectedOption.person?._id));
  };

  useEffect(() => {
    setThingPeopleIsValid(!isInvalid());
  }, [selectedRadio, selectedOption]);

  if (groupsState === 'pending') {
    return (
      <Card>
        <CardContent>
          <MiddleSpinner />
        </CardContent>
      </Card>
    );
  }

  if (groupsError) {
    return (
      <Card>
        <CardContent>
          <Stack spacing={2}>
            <Typography variant="h5">{t('admin:page.subscriptions-list.all-things-people.label')}</Typography>
            <Typography>{t('admin:page.subscriptions-list.all-things-people.hint')}</Typography>
          </Stack>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card>
      <CardContent>
        <Stack spacing={2}>
          <Typography variant="h5">
            {t('admin:page.create-subscription.thing-people.labels')}
          </Typography>
          <ConfirmDialogRadio
            confirmPrompt={<></>}
            data-testid="alerts-source"
            disabled={fieldDisabled}
            heading={t('admin:page.subscription-detail.alerts-source.label')}
            onConfirm={() => Promise.resolve()}
            onFailure={() => { }}
            onSuccess={() => { }}
            open={false}
            options={thingsOrPeopleOptions}
            selected={selectedRadio.thingsOrPeople}
            setOpen={() => { }}
            setSelected={(value) => {
              setSelectedOption({ group: defaultEntity, person: defaultEntity, thing: defaultEntity });
              handleSelectedRadio('thingsOrPeople', value);
            }}
          />
          {selectedRadio.thingsOrPeople !== RadioButtons.ALL_THING_PEOPLE
            && <>
              <ConfirmDialogRadio
                confirmPrompt={<></>}
                data-testid="individual-or-group"
                disabled={fieldDisabled}
                heading={t('admin:page.subscriptions-list.individual-group.label')}
                onConfirm={() => Promise.resolve()}
                onFailure={() => { }}
                onSuccess={() => { }}
                open={false}
                options={individualOrGroupsOptions}
                selected={selectedRadio.individualOrGroup}
                setOpen={() => { }}
                setSelected={(value) => {
                  setSelectedOption({ group: defaultEntity, person: defaultEntity, thing: defaultEntity });
                  handleSelectedRadio('individualOrGroup', value);
                }}
              />
              {selectedRadio.individualOrGroup === RadioButtons.GROUP
                ? <EntityAutocomplete
                  data={entityData}
                  fieldDisabled={fieldDisabled}
                  onOptionChanged={setSelectedOption}
                  selectedOption={selectedOption}
                  data-testid="select-group"
                />
                : <EntityLookup
                  disabled={fieldDisabled}
                  entityData={entityData}
                  handleSelectedEntity={handleSelectedEntity}
                  data-testid="select-entity"
                />
              }
            </>
          }
        </Stack>
      </CardContent>
    </Card>
  );
};
