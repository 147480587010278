/* eslint-disable react-hooks/exhaustive-deps */
import { PaginatedResponse } from '@eagle/api-types';
import { AxiosResponse } from 'axios';
import { useEffect, useMemo, useReducer } from 'react';
import { concat, concatMap, defer, EMPTY, expand, from, map, mergeMap, Observable, of, reduce, retry, Subject, switchMap, throwError } from 'rxjs';
import { useAuthenticated } from '../auth';
import { THING_EVENT_API_LIMIT } from '../constants';
import { isAxiosError } from '../util';
import { useObservable } from './use-observable';
import { Action, reducer, Resolution, State } from './use-promise';

interface ExpectedParams {
  dateRangeFinish: Date;
  dateRangeStart: Date;
  filters?: Record<string, unknown>;
  sort?: Record<string, unknown>;
  limit: number;
}

type Params = Record<string, unknown> & ExpectedParams;
type Response<T> = AxiosResponse<PaginatedResponse<T>>;

interface Query { params?: Params }

const defaultState: State<any> = {
  error: undefined,
  result: undefined,
  state: 'pending',
};

export const useRecursive = <T extends { occurred: string | Date }>(
  api: string,
  params?: Params,
  transformData: (data: AxiosResponse<PaginatedResponse<T>, any>) => Response<T> = (data) => data,
  inputs: unknown[] = [],
  batchSize?: number,
  retryCount?: number,
): Resolution<PaginatedResponse<T>> => {
  const [{ error, result, state }, dispatch] = useReducer((reducerState: State<PaginatedResponse<T>>, action: Action<PaginatedResponse<T>>) => reducer(reducerState, action), defaultState);
  const { axios } = useAuthenticated();
  const limit = params?.limit ?? THING_EVENT_API_LIMIT;
  const filters = params?.filters;
  const dateRangeStart = params?.dateRangeStart;
  const dateRangeFinish = params?.dateRangeFinish;

  const onError = (observerError: Error): void => {
    dispatch({ payload: observerError, type: 'rejected' });
  };

  const get = (query: Query, skip = 0): Observable<Response<T>> => {
    return from(axios
      .get<PaginatedResponse<T>>(api, { params: { ...query.params, limit, skip, sort: query.params?.sort ?? { occurred: 1 } } })
      .then(transformData),
    );
  };

  const { handleQueryChanged, observable } = useMemo(() => {
    if (batchSize) {
      const subject = new Subject<Query>();
      return {
        handleQueryChanged: (query: Query) => subject.next(query),
        observable: subject.pipe(
          switchMap((query) => {
            const getData = retryCount ? createRetryingObservableFactory(get, retryCount, (error) => {
              return isAxiosError(error) ? error.code === 'ECONNABORTED' : false;
            }) : get;

            return getData(query).pipe(
              switchMap((firstResult) => {
                const batches = createBatches({ batchSize, limitPerRequest: limit, total: firstResult.data.count ?? 0 });

                const batchesObservable = from(batches).pipe(
                  concatMap((batch) => {
                    return from(batch).pipe(
                      mergeMap((page) => {
                        return getData(query, page * limit);
                      }),
                    );
                  }),
                );

                const accumulateData = reduce<Response<T>>((acc, { data }) => {
                  dispatch({ payload: { ...data, items: acc.data.items }, type: 'pending' });
                  return {
                    ...acc,
                    data: { ...acc.data, items: [...acc.data.items, ...data.items], hasMore: false },
                  };
                });

                const sortData = map<Response<T>, Response<T>>((response) => {
                  const sortedItems = response.data.items.sort(
                    (a, b) => new Date(a.occurred).getTime() - new Date(b.occurred).getTime(),
                  );
                  return { ...response, data: { ...response.data, items: sortedItems } };
                });

                return concat(of(firstResult), batchesObservable)
                  .pipe(accumulateData)
                  .pipe(sortData);
              }),
            );
          }),
        ),
      };
    }

    const subject = new Subject<Query>();
    return {
      handleQueryChanged: (query: Query): void => subject.next(query),
      observable: subject.pipe(
        switchMap((query: Query) => get(query).pipe(
          expand((response, i) => response.data.hasMore ? get(query, i * limit) : EMPTY),
          reduce((acc, { data }, i) => {
            dispatch({ payload: { ...data, items: acc.data.items }, type: 'pending' });
            return {
              ...acc, data: { ...acc.data, items: i !== 1 ? [...acc.data.items, ...data.items] : acc.data.items, hasMore: data.hasMore },
            };
          }),
        )),
      ),
    };
  }, [api, limit, dispatch, batchSize]);

  const paginatedData = useObservable(observable, onError);

  useEffect(() => {
    if (!paginatedData) return;
    const { data } = paginatedData;
    dispatch({ payload: data, type: data.hasMore ? 'pending' : 'resolved' });
  }, [paginatedData]);

  useEffect(() => {
    handleQueryChanged({ params });
    // Params in dependencies causes constant updates and constant api calls.
  }, [limit, filters, dateRangeStart, dateRangeFinish, ...inputs]);

  return [result, error, state] as Resolution<PaginatedResponse<T>>;
};

const createBatches = ({ batchSize, limitPerRequest, total }: { batchSize: number; limitPerRequest: number; total: number }): number[][] => {
  const result: number[][] = [];

  const numberOfRequests = Math.ceil(total / limitPerRequest) - 1;
  for (let i = 0; i < numberOfRequests; i += batchSize) {
    const currentBatch: number[] = [];
    const endIndex = Math.min(i + batchSize, numberOfRequests);
    for (let j = i; j < endIndex; j++) {
      currentBatch.push(j + 1);
    }
    result.push(currentBatch);
  }
  return result;
};

type ObservableFactory<T, Args extends any[]> = (...args: Args) => Observable<T>;

const createRetryingObservableFactory = <T, Args extends any[]>(
  observableFactory: ObservableFactory<T, Args>,
  retryCount: number,
  shouldRetry: (error: Error) => boolean,
): ObservableFactory<T, Args> => {
  return (...args: Args) =>
    defer(() => observableFactory(...args)).pipe(
      retry({
        count: retryCount,
        delay: (error: Error) => {
          return shouldRetry(error) ? of(true) : throwError(() => error);
        },
      }),
    );
};
