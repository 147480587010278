/* eslint-disable react-hooks/exhaustive-deps */
import { Time } from '@eagle/common';
import { Theme, useTheme } from '@mui/material';
import { Box, SxProps } from '@mui/system';
import Axios, { AxiosResponse } from 'axios';
import L from 'leaflet';
import 'leaflet.markercluster';
import { debounce, throttle } from 'lodash';
import { FC, PropsWithChildren, useCallback, useEffect } from 'react';
import { useMap } from 'react-leaflet';
import { IncidentIcons } from '../../../components';
import { useConfig, useInterval } from '../../../hooks';
import { getBoundingBox, getIcon } from '../../../util';
import { IncidentDetails, IncidentResult } from './incident-layer.types';

const DiamondIcon: FC<PropsWithChildren & { sx?: SxProps; theme: Theme }> = ({ children, sx, theme }) => {
  return (
    <Box
      sx={{
        alignItems: 'center',
        backgroundColor: theme.incident.clusterBackgroundColor,
        display: 'flex',
        height: theme.incident.size,
        justifyContent: 'center',
        m: 0,
        outline: theme.incident.clusterBorder,
        p: 0,
        textAlign: 'center',
        transform: 'rotate(45deg)',
        width: theme.incident.size,
        ...sx,
      }}
    >
      <Box sx={{ m: 0, p: 0, transform: 'rotate(-45deg)' }}>
        {children}
      </Box>
    </Box>
  );
};

const createIncidentMarker = (cluster: L.MarkerClusterGroup, position: L.LatLng, { summary, type }: IncidentDetails): void => {
  const icon = (): JSX.Element => {
    switch (type) {
      case 'accident': return <IncidentIcons.Accident />;
      case 'construction': return <IncidentIcons.Construction />;
      case 'congestion': return <IncidentIcons.Congestion />;
      case 'disabledVehicle': return <IncidentIcons.DisabledVehicle />;
      case 'plannedEvent': return <IncidentIcons.PlannedEvent />;
      case 'roadClosure': return <IncidentIcons.RoadClosed />;
      case 'weather': return <IncidentIcons.Weather />;
      default: return <IncidentIcons.Other />;
    }
  };
  const marker = new L.Marker(
    position,
    {
      title: summary.value,
      icon: getIcon(icon()),
    },
  );
  marker.bindTooltip(summary.value);
  cluster.addLayer(marker);
};

export const IncidentLayer: FC = () => {
  const config = useConfig();
  const map = useMap();
  const polylineLayer = L.layerGroup();
  const cancelToken = Axios.CancelToken.source();
  const theme = useTheme();
  const THREE_MINUTES = Time.minutes(3);
  const clusterGroup = new L.MarkerClusterGroup(
    {
      maxClusterRadius: 20,
      iconCreateFunction: (cluster) => getIcon(<DiamondIcon theme={theme}><span>{cluster.getChildCount()}</span></DiamondIcon>),
    },
  );

  const clearMap = (): void => {
    polylineLayer.clearLayers();
    clusterGroup.clearLayers();
  };

  const getIncidents = (): Promise<AxiosResponse<IncidentResult>> => {
    const bounds = getBoundingBox(map);
    const params = {
      apiKey: config.hereMaps?.apiKey,
      in: bounds.toHereMapsIn(),
      locationReferencing: 'shape',
    };
    return Axios.get<IncidentResult>('https://data.traffic.hereapi.com/v7/incidents', { params });
  };

  const debouncedFindIncidents = useCallback(debounce(() => {
    if (!getBoundingBox(map).isInBounds(1)) return clearMap();
    void getIncidents()
      .then(({ data }) => {
        clearMap();
        if (!getBoundingBox(map).isInBounds(1)) return;
        data.results.map(({ incidentDetails, location }) => {
          location.shape.links.map(({ points }, i) => {
            const incidentPoints = points.map(({ lat, lng }) => new L.LatLng(lat, lng));
            const polyline = L.polyline(incidentPoints, { color: theme.incident.roadColor });
            polylineLayer.addLayer(polyline);
            if (i === 0) createIncidentMarker(clusterGroup, incidentPoints[0], incidentDetails);
          });
        });
      });
  }), [polylineLayer]);

  useInterval(debouncedFindIncidents, THREE_MINUTES);

  const throttledFindIncidents = useCallback(throttle(debouncedFindIncidents, 1000), [debouncedFindIncidents]);

  useEffect(() => {
    debouncedFindIncidents();
    map.on('zoomend', throttledFindIncidents);
    map.on('moveend', throttledFindIncidents);
    map.addLayer(clusterGroup);
    return () => {
      cancelToken.cancel();
      clearMap();
      map.off('zoomend', throttledFindIncidents);
      map.off('moveend', throttledFindIncidents);
    };
  }, []);

  return <></>;
};
