/* eslint-disable react-hooks/exhaustive-deps */
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { AutocompleteValue, Checkbox, Divider, PopperProps } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { isEmpty, isString } from 'lodash';
import { HTMLAttributes, PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { from, of, Subject } from 'rxjs';
import { catchError, debounceTime, switchMap, tap } from 'rxjs/operators';
import { ulid } from 'ulid';
import { API_CALL_TEXT_LENGTH } from '../../constants';
import { useObservable } from '../../hooks';
import { testid } from '../../util/test-id';
import { ErrorMessage } from '../error-message';
import { MiddleSpinner } from '../middle-spinner';
import { LookupProps } from './types';

interface OptionState {
  selected: boolean;
}

const SELECT_ALL = 'SELECT_ALL';

/**
 * FUNCTION: Note this is required to be a function in React to achieve strong generic typing :(
 */
// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function Lookup<T, Multiple extends boolean | undefined = undefined>({
  debounceTime: debounce = 0,
  disabled,
  disableFilter,
  displaySelectAll,
  fullWidth,
  getOptionTestId,
  handleCompareItems,
  handleFindItems,
  handleFormatListItem = (item) => <span>{String(item)}</span>,
  handleFormatSelectedItem = (item) => String(item),
  handleRenderTags,
  initialInstructions,
  isSearch = false,
  label,
  loadOnChange = true,
  loadOnRender,
  multiple,
  noResultsInstructions,
  onInputChange,
  onItemSelected = () => { },
  openOnFocus,
  placeholder,
  required,
  selectedItem,
  size,
  textFieldStyles,
  updatableData = false,
  disablePortal = true,
  ...props
}: PropsWithChildren<LookupProps<T, Multiple>>): JSX.Element {
  const [inputLength, setInputLength] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error>();
  const clearError = (): void => setError(undefined);
  const renderError = (): void => setError(new Error());
  const { t } = useTranslation(['common']);

  const { handleQueryChanged, observable } = useMemo(() => {
    const subject = new Subject<string>();

    return {
      handleQueryChanged: (query = ''): void => subject.next(query),
      observable: subject.pipe(
        tap(() => {
          setIsLoading(true);
          clearError();
        }),
        debounceTime(debounce),
        switchMap((query: string) => {
          if (isSearch && query.length < API_CALL_TEXT_LENGTH) return of(undefined);
          const deferred = handleFindItems(query);

          return from(deferred).pipe(
            catchError((err: Error) => {
              setError(err);
              return of(undefined);
            }),
          );
        }),
        tap(() => setIsLoading(false)),
      ),
    };
  }, [handleFindItems]);
  const data = useObservable(observable);

  const renderOption = (optionProps: HTMLAttributes<HTMLLIElement>, option: T, optionState: OptionState): JSX.Element => {
    const key = ulid();
    const testId = getOptionTestId ? getOptionTestId(option) : testid`${key}`;

    if (multiple) {
      return <>
        <li {...optionProps} key={key} data-testid={testId}>
          <Checkbox
            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
            checkedIcon={<CheckBoxIcon fontSize="small" />}
            sx={{ mr: 1 }}
            checked={option === SELECT_ALL ? data?.length === (selectedItem as any[]).length : optionState.selected}
          />
          {option === SELECT_ALL ? <strong>{t('common:component.filter-dropdown.labels.select-all')}</strong> : handleFormatListItem(option)}
        </li>
        {option === SELECT_ALL && <Divider />}
      </>;
    }
    return <li {...optionProps} key={key} data-testid={testId}>{handleFormatListItem(option)}</li>;
  };

  const labelWithChildren = label as { props?: { children?: string } };
  const dataTestId = props['data-testid'] || testid`lookup-${isString(label) ? label : labelWithChildren?.props?.children}`;

  useEffect(() => {
    if (!loadOnRender) return;
    handleQueryChanged();
  }, [loadOnRender, handleQueryChanged]);

  return (
    <Autocomplete<T, Multiple>
      blurOnSelect="touch"
      clearOnEscape
      componentsProps={{
        popper: {
          'data-testid': `${dataTestId}-popper`,
        } as Partial<PopperProps>, // Incorrect type in @mui/material doesn't allow passing data attributes, so we need the assertion.
      }}
      data-testid={dataTestId}
      disableCloseOnSelect={multiple}
      disabled={disabled}
      filterOptions={disableFilter ? undefined : (options) => {
        if (multiple && displaySelectAll) {
          return [SELECT_ALL as T, ...options];
        }
        return options;
      }}
      fullWidth={fullWidth}
      getOptionLabel={handleFormatSelectedItem}
      isOptionEqualToValue={handleCompareItems}
      loading={isLoading}
      loadingText={<MiddleSpinner />}
      multiple={multiple}
      noOptionsText={(isSearch && inputLength < API_CALL_TEXT_LENGTH)
        ? t('common:component.search.hint.less-than-count-map', { count: API_CALL_TEXT_LENGTH })
        : noResultsInstructions
      }
      options={isLoading && updatableData ? [] : data ?? []}
      onChange={(_event, newValue) => {
        if (multiple && (newValue as any[]).includes(SELECT_ALL)) {
          const isAllSelected = data?.length === (selectedItem as any[]).length;
          onItemSelected(((isAllSelected ? [] : data) ?? []) as AutocompleteValue<T, Multiple, false, false>);
        }
        else {
          onItemSelected(newValue);
        }
        if (required && (isEmpty(newValue) || !newValue)) return renderError();
        clearError();
      }}
      onError={() => renderError()}
      onInputChange={(event, newInputValue, reason) => {
        onInputChange?.(event, newInputValue, reason);
        setInputLength(newInputValue.length);
        if (!loadOnChange) return;
        handleQueryChanged(newInputValue);
      }}
      onOpen={() => !loadOnRender && handleQueryChanged()}
      openOnFocus={openOnFocus}
      placeholder={placeholder}
      renderInput={(params) => (
        <TextField
          {...params}
          data-testid="text-input"
          error={!!error}
          helperText={error ? <ErrorMessage error={error} /> : initialInstructions}
          label={label}
          required={required}
          sx={textFieldStyles}
          variant="outlined"
        />
      )}
      renderOption={renderOption}
      renderTags={handleRenderTags}
      size={size}
      value={selectedItem}
      disablePortal={disablePortal}
    />
  );
}
