import { Box, CircularProgress, Tooltip, Typography } from '@mui/material';
import { FC } from 'react';
import { CircularProgressIndicatorProps, CircularProgressWithLabelProps } from './circular-progress-indicator.types';

const CircularProgressWithLabel: FC<CircularProgressWithLabelProps> = ({ customColor, loadingHint, value, ...rest }) => (
  <Box sx={{ position: 'relative', display: 'inline-flex' }}>
    <CircularProgress
      {...rest}
      sx={{ color: customColor }}
      value={value}
      variant="determinate"
    />
    <Box
      sx={{
        alignItems: 'center',
        bottom: 0,
        display: 'flex',
        justifyContent: 'center',
        left: 0,
        position: 'absolute',
        right: 0,
        top: 0,
      }}
    >
      <Tooltip title={loadingHint} arrow>
        <Typography
          color="text.secondary"
          component="div"
          variant="caption"
        >
          {`${Math.round(value)}%`}
        </Typography>
      </Tooltip>
    </Box>
  </Box>
);

const CircularProgressIndicator: FC<CircularProgressIndicatorProps> = ({
  color,
  isLoadingOthers,
  loadingHint,
  retrievedCount,
  totalCount,
}) => {
  const progress = (): number => {
    if (!totalCount && !retrievedCount) return 0;
    if (retrievedCount !== totalCount) return (retrievedCount / totalCount) * 100;
    if (isLoadingOthers) return 99;
    return 100;
  };
  return <CircularProgressWithLabel customColor={color} loadingHint={loadingHint} value={progress()} />;
};

export default CircularProgressIndicator;
