/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import { Autocomplete, TextField } from '@mui/material';
import { uniqBy } from 'lodash';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CommonEntity, Nullable } from '../../types';
import { EntityAutocompleteProps, EntityState } from './subscription-detail.types';

export const defaultEntity: CommonEntity<Nullable<string>> = {
  _id: null,
  display: '',
};

export const EntityAutocomplete: FC<EntityAutocompleteProps> = ({ data, fieldDisabled, onOptionChanged, selectedOption, size = 'small', ...props }) => {
  const { alertFilterId, key, label, options } = data;
  const { t } = useTranslation(['admin']);
  const [displayHelper, setDisplayHelper] = useState('');
  const [displayError, setDisplayError] = useState(false);
  const [entityOptions, setEntityOptions] = useState<CommonEntity<Nullable<string>>[]>([]);
  const entityKeys = Object.keys(selectedOption) as (keyof EntityState)[];

  if (!key || !options) return <></>;

  const getEntityOptions = (): CommonEntity<Nullable<string>>[] => {
    // TODO: Is there a reason this rule is enabled in react-common but disabled in portals?
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const entityOptions = options[key]?.map(({ _id, display }) => {
      return {
        _id,
        display,
      };
    });
    return uniqBy(entityOptions, 'display');
  };

  // TODO: Is there a reason this rule is enabled in react-common but disabled in portals?
  // eslint-disable-next-line @typescript-eslint/no-shadow
  const updatedEntityChanged = (value: Nullable<CommonEntity<Nullable<string>>>, key: keyof EntityState): void => {
    onOptionChanged({ ...selectedOption, [key]: value });
  };

  useEffect(() => {
    const initialSelectedOption = getEntityOptions()?.find((entity) => entity._id === alertFilterId) ?? selectedOption[key];
    updatedEntityChanged(initialSelectedOption, key);
    setEntityOptions(getEntityOptions());
  }, []);

  const isValidDisplay = (entities: CommonEntity<Nullable<string>>[], display: string): boolean => {
    return entities.some((entity) => entity.display === display);
  };

  return (
    <Autocomplete<CommonEntity<Nullable<string>>, false, true, true>
      disabled={fieldDisabled}
      disableClearable
      filterSelectedOptions
      forcePopupIcon
      freeSolo
      autoHighlight
      onChange={(_event, newValue) => {
        if (typeof newValue === 'string') return;
        setDisplayError(false);
        setDisplayHelper('');
        const filteredStateKeys = entityKeys?.filter((filterKey) => filterKey !== key);
        // TODO: Is there a reason this rule is enabled in react-common but disabled in portals?
        // eslint-disable-next-line @typescript-eslint/no-shadow
        const toggledEntities = filteredStateKeys.reduce((values, key) => ({ ...values, [key]: defaultEntity }), {} as EntityState);
        onOptionChanged({ ...toggledEntities, [key]: newValue });
      }}
      options={getEntityOptions()}
      renderInput={(params) => (
        <TextField
          {...params}
          error={displayError}
          helperText={displayHelper}
          label={label}
          variant="outlined"
          onChange={(val) => {
            if (val.target.value === '' || !isValidDisplay(entityOptions, val.target.value)) {
              setDisplayError(true);
              setDisplayHelper(t('admin:page.create-subscription.thing-people.hint'));
            }
            else {
              setDisplayError(false);
              setDisplayHelper('');
            }
          }}
          data-testid={props['data-testid']}
        />
      )}
      includeInputInList
      getOptionLabel={(option) => {
        if (typeof option === 'string') {
          return option;
        }
        return option.display ?? '';
      }}
      renderOption={(props, option) => <li {...props}>{option.display}</li>}
      size={size}
      value={selectedOption[key] ?? defaultEntity}
    />
  );
};
