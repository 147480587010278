import { ChannelType, CreateMySubscriptionRequest, CreateSubscriptionRequest, Recipient, RecipientType, Subscription } from '@eagle/core-data-types';
import { useSnackbar } from 'notistack';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAuthenticated } from '../../auth';
import { getBrowserLocale, getCanonicalTimeZone } from '../../components';
import { T_ONE } from '../../constants';
import { useCustomRoutes } from '../../hooks';
import { isIndividualUserRecipient } from '../subscription/subscription-detail.utils';
import { CreateSubscriptionView } from './create-subscription-view';
import { FeatureAlertSettings, ThingPersonFilterIds } from './create-subscription.types';

interface CreateSubscriptionControllerProps {
  isProfilePage: boolean;
}

export const CreateSubscriptionController: FC<CreateSubscriptionControllerProps> = ({ isProfilePage }) => {
  const { subscription: subscriptionRoute } = useCustomRoutes();
  const { axios, userInfo } = useAuthenticated();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(['admin', 'common']);
  const [activeStep, setActiveStep] = useState<number>(0);
  const [createInProgress, setCreateInProgress] = useState(false);

  const onActiveNext = (): void => setActiveStep((prevStep) => prevStep + 1);
  const onActiveBack = (): void => setActiveStep((prevStep) => prevStep - 1);

  const defaultRecipient: Recipient = {
    type: RecipientType.INTERNAL_INDIVIDUAL_USER,
    localization: {
      locale: getBrowserLocale(),
      timezone: getCanonicalTimeZone(Intl.DateTimeFormat().resolvedOptions().timeZone),
    },
    referenceId: '',
    channel: {
      type: ChannelType.EMAIL,
      address: null,
    },
  };

  const [subscription, setSubscription] = useState<CreateSubscriptionRequest>({
    accountId: userInfo.accountId,
    recipient: defaultRecipient,
    alertFilters: {
      alertTypeId: null,
      feature: null,
      featureTypeIdPrefix: null,
      groupId: null,
      personId: null,
      thingId: null,
    },
  });

  const updateAlertFilters = (values: ThingPersonFilterIds | FeatureAlertSettings): void => {
    setSubscription({
      ...subscription,
      alertFilters: {
        ...subscription.alertFilters,
        ...values,
      },
    });
  };

  const updateRecipient = (recipient: Recipient): void => {
    setSubscription({
      ...subscription,
      recipient,
    });
  };

  const createSubscription = async (): Promise<void> => {
    const createRequestBody = (): CreateSubscriptionRequest | CreateMySubscriptionRequest => {
      if (isProfilePage) {
        if (!isIndividualUserRecipient(subscription.recipient)) {
          throw new Error('Invalid state.');
        }
        return {
          ...subscription,
          recipient: {
            channel: subscription.recipient.channel,
            localization: subscription.recipient.localization,
          },

        };
      }
      return subscription;
    };

    try {
      const endpoint = isProfilePage ? '/api/v1/my/subscription' : '/api/v1/recipient/subscription';

      setCreateInProgress(true);
      const createdSubscription = (await axios.post<Subscription>(endpoint, createRequestBody())).data;
      enqueueSnackbar(t('common:common.hint.create-success', { display: t('common:terms.subscription', { count: T_ONE }) }), { variant: 'success' });
      navigate(`${isProfilePage ? `/profile/${subscriptionRoute}` : `/recipient/${subscriptionRoute}`}/${createdSubscription._id}`);
    } catch (err) {
      enqueueSnackbar(t('common:common.hint.create-failure', { display: t('common:terms.subscription', { count: T_ONE }) }), { variant: 'error' });
    } finally {
      setCreateInProgress(false);
    }
  };

  return (
    <CreateSubscriptionView
      activeStep={activeStep}
      createInProgress={createInProgress}
      createSubscription={createSubscription}
      isProfilePage={isProfilePage}
      onActiveBack={onActiveBack}
      onActiveNext={onActiveNext}
      updateAlertFilters={updateAlertFilters}
      updateRecipient={updateRecipient}
    />
  );
};
