import {
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Paper,
  Stack,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { ChangeEvent, FC, FocusEvent, KeyboardEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMaxScreen } from '../../hooks';
import { DeleteButton } from '../delete-button';

interface Props {
  'data-testid'?: string;
  disabled?: boolean;
  invalidLabels?: [string, string][];
  labelColor?: string;
  labels: Record<string, string>;
  onChange: (labels: Record<string, string>) => Promise<void>;
  placeholder?: string;
  required?: boolean;
  size?: 'small' | 'medium';
  textfieldLabel?: string;
}

export const LabelsEditor: FC<Props> = ({ disabled = false, invalidLabels, labelColor, labels: inputLabels, onChange, required, size, textfieldLabel, ...props }) => {
  const { t } = useTranslation(['common']);
  const smallScreen = useMaxScreen('md');
  const [currentLabel, setCurrentLabel] = useState<string>('');
  const [labels, setLabels] = useState<Record<string, string>>(inputLabels);
  const [isLoading, setIsLoading] = useState(false);
  const [isDuplicate, setIsDuplicate] = useState(false);
  const inputField = useRef<HTMLInputElement>(null);
  const labelWithAdornment = textfieldLabel && required ? textfieldLabel + ' *' : textfieldLabel;

  const updateLabels = (updatedLabels: Record<string, string>): void => {
    setIsLoading(true);
    onChange(updatedLabels)
      .then(() => {
        setIsLoading(false);
        setLabels(updatedLabels);
        setCurrentLabel('');
      })
      .catch(() => setIsLoading(false));
  };

  const onInputKeyDown = (e: KeyboardEvent<HTMLInputElement>): void => {
    const target = e.target as HTMLInputElement;
    if (e.key === 'Enter') {
      target.blur();
    }
  };

  const onInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const labelExists = Object.keys(labels).includes(e.target.value);
    setIsDuplicate(labelExists);
    setCurrentLabel(e.target.value);
  };

  const onLabelInputBlur = (e: FocusEvent<HTMLInputElement>): void => {
    const labelToSave = e.target.value.trim();
    if (labelToSave && !Object.keys(labels).includes(labelToSave)) {
      updateLabels({
        ...labels,
        [labelToSave]: '',
      });
    }
  };

  const onValueChange = (e: ChangeEvent<HTMLInputElement>, key: string): void => {
    setLabels({
      ...labels,
      [key]: e.target.value,
    });
  };

  const onValueInputBlur = (e: FocusEvent<HTMLInputElement>): void => {
    const valueExists = e.target.value.trim();
    if (valueExists) {
      updateLabels(labels);
    }
  };

  const removeLabel = (labelToRemove: string) => (): Promise<void> => {
    const updatedLabels = {
      ...labels,
    };
    delete updatedLabels[labelToRemove];

    updateLabels(updatedLabels);
    return Promise.resolve();
  };

  useEffect(() => {
    setLabels(inputLabels);
  }, [inputLabels]);

  return (
    <Stack data-testid={props['data-testid']} direction='column' spacing={2}>
      {labels && Object.entries(labels).map(([key, value], index) => (
        <Grid key={key} container spacing={0} direction="row" alignItems='center' justifyContent='space-between'>
          <Grid item sm={4}>
            <Paper aria-label="Item Label" elevation={0} sx={{ backgroundColor: labelColor, m: 1, py: .25 }}>
              {smallScreen
                ?
                <Typography variant="subtitle2" fontWeight="bold" data-testid={`label-key-${index}`} sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                  {key}
                </Typography>
                :
                <Tooltip arrow followCursor placement="top" title={key}>
                  <Typography variant="subtitle2" fontWeight="bold" data-testid={`label-key-${index}`} sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                    {key}
                  </Typography>
                </Tooltip>
              }
            </Paper>
          </Grid>
          <Grid item sm={8} sx={{ flex: '1 0 auto' }}>
            <FormControl sx={{ display: 'flex' }} variant="outlined" size={size} disabled={disabled || isLoading}>
              <InputLabel>{labelWithAdornment}</InputLabel>
              <OutlinedInput
                endAdornment={
                  <InputAdornment position="end">
                    <DeleteButton data-testid={`delete-button-${index}`} onClick={removeLabel(key)} disabled={disabled || isLoading} />
                  </InputAdornment>
                }
                error={invalidLabels && invalidLabels.some(([match]) => key === match)}
                label={textfieldLabel}
                onBlur={onValueInputBlur}
                onChange={(e: ChangeEvent<HTMLInputElement>) => onValueChange(e, key)}
                onKeyDown={onInputKeyDown}
                size={size}
                type="text"
                value={value}
                data-testid={`label-value-${index}`}
              />
            </FormControl>
          </Grid>
        </Grid>
      ))}
      <TextField
        data-testid="label-input"
        disabled={disabled || isLoading}
        error={isDuplicate}
        label={t('common:common.hint.label')}
        onBlur={onLabelInputBlur}
        onChange={onInputChange}
        onKeyDown={onInputKeyDown}
        ref={inputField}
        size={size}
        sx={{ display: 'flex' }}
        type="text"
        value={currentLabel}
        variant="outlined"
      />
    </Stack>
  );
};
