/* eslint-disable react-hooks/exhaustive-deps */
import { PaginatedResponse } from '@eagle/api-types';
import { TrackingEventTypes } from '@eagle/data-function-types';
import { useTheme } from '@mui/material';
import { DateTime } from 'luxon';
import { createContext, FC, PropsWithChildren, useContext, useEffect, useState } from 'react';
import { ulid } from 'ulid';
import { ROUTING_GET_REQUEST_LIMIT, ROUTING_POST_REQUEST_LIMIT } from '../../constants';
import { SerializationOptions, useHistoryState } from '../../hooks/use-history-state';
import { Maybe, SetState, Undefinable } from '../../types';
import { MapStorageKeys, useLocalStorage } from '../../util';
import { ThingPersonDialogOperation } from '../assign-multiple-dialog/add-thing-person-dialog';
import { useBoolFlag } from '../flags';
import { EventLocationData, ThingEventItems } from '../map/thing-event-pane/thing-event-pane.types';
import { EntityItemFields, EntityItems, EntityJourneyItemIdentifier, EntityVisibility, JourneyItem } from './entity-journey-types';
import { getColorIndex, getJourneyColor } from './util';

interface HistorySearchProviderProps extends PropsWithChildren {
  defaultEventData?: ThingEventItems[];
  defaultVisibilities?: EntityVisibility[];
  enableRoutingPostRequestDefault?: boolean;
}

interface Context {
  addEntity: (id: string, entityItemFields: EntityItemFields) => void;
  addEntityWithDateTime: (
    entityId: string,
    entityItemFields: EntityItemFields,
    startTime: Date,
    endTime: Date,
    focused: boolean,
    expanded?: boolean,
  ) => void;
  addJourneyToEntity: (startTime: Date, endTime: Date) => void;
  changeEntity: (entityId: string, newId: string, entityItemFields: EntityItemFields) => void;
  clickedEventId: Maybe<string>;
  closeAddDateTimePicker: () => void;
  currentEntityId: string;
  dateAddingJourney?: EntityJourneyItemIdentifier;
  dateEditingJourney?: EntityJourneyItemIdentifier;
  duplicateEntity: (id: string, newId: string, entityItemFields: EntityItemFields) => void;
  enableRoutingPostRequest: boolean;
  entityDialogOperationType: ThingPersonDialogOperation;
  entityItems: EntityItems[];
  entityTypeTab?: string;
  entityVisibilities: EntityVisibility[];
  eventData: ThingEventItems[];
  eventLimit: number;
  expandedEntity?: EntityJourneyItemIdentifier;
  handleExpand: (entityId: string, journeyId: string) => void;
  isEntityDialogOpen: boolean;
  mapLoading: Record<string, boolean>;
  nextZoomTarget: Maybe<string>;
  openAddDateTimePicker: (entityId: string) => void;
  progressivelyLoadRoute?: boolean;
  removeEntity: (id: string) => void;
  removeJourney: (id: string, entityId: string) => void;
  setClickedEventId: (value: Maybe<string>) => void;
  setCurrentEntityId: (id: string) => void;
  setEntityDialogOperationType: (type: ThingPersonDialogOperation) => void;
  setEntityItems: (value: EntityItems[]) => void;
  setEntityTypeTab: (id?: string) => void;
  setEventData: (value: ThingEventItems[]) => void;
  setIsEntityDialogOpen: (open: boolean) => void;
  setMapLoading: SetState<Record<string, boolean>>;
  setNextZoomTarget: (value: Maybe<string>) => void;
  setProgressivelyLoadRoute: (value: boolean) => void;
  setSnapToRoad: (value: boolean) => void;
  setThingTypeId: (value: Undefinable<string>) => void;
  snapToRoad: boolean;
  thingTypeId: Undefinable<string>;
  toggleAllVisibility: (id: string) => void;
  toggleEditingDate: (entityId: string, id: string) => void;
  toggleVisibleItem: (id: string, entityId: string) => void;
  updateDateTime: (entityId: string, journeyId: string, startTime: Date, endTime: Date) => void;
  updateJourney: (
    data: JourneyItem,
    events: PaginatedResponse<EventLocationData>,
    isSelected: boolean,
    entityDisplay: string,
  ) => void;
}

interface JourneyItemsSerialized extends Omit<JourneyItem, 'dateRange'> {
  dateRange: {
    endTime: string;
    startTime: string;
  };
}

interface EntityItemsSerialized extends Omit<EntityItems, 'journeyList'> {
  journeyList: JourneyItemsSerialized[];
}

const context = createContext<Undefinable<Context>>(undefined);

const serializationOptions: SerializationOptions<EntityItems[], EntityItemsSerialized[]> = {
  fromSerializable: (value) => {
    return value.map((item) => {
      const journeyList = item.journeyList.map((journey) => {
        return ({
          ...journey,
          dateRange: {
            endTime: DateTime.fromISO(journey.dateRange.endTime).toJSDate(),
            startTime: DateTime.fromISO(journey.dateRange.startTime).toJSDate(),
          },
        });
      });

      return ({
        ...item,
        journeyList,
      });
    });
  },
  toSerializable: (value) => {
    return value.map((item) => {
      const journeyList = item.journeyList.map((journey) => {
        return ({
          ...journey,
          dateRange: {
            endTime: journey.dateRange.endTime.toISOString(),
            startTime: journey.dateRange.startTime.toISOString(),
          },
        });
      });

      return ({
        ...item,
        journeyList,
      });
    });
  },
};

export const HistorySearchProvider: FC<HistorySearchProviderProps> = ({
  children,
  defaultEventData,
  defaultVisibilities,
  enableRoutingPostRequestDefault = false,
}) => {
  const trackRouteFlag = useBoolFlag('track-route-calculation-api-v2-temporary-20230330');
  const enableRoutingPostRequest = trackRouteFlag ?? enableRoutingPostRequestDefault;
  const eventLimit = enableRoutingPostRequest ? ROUTING_POST_REQUEST_LIMIT : ROUTING_GET_REQUEST_LIMIT;
  const [currentEntityId, setCurrentEntityId] = useState('');
  const [dateAddingJourney, setDateAddingJourney] = useState<EntityJourneyItemIdentifier>();
  const [dateEditingJourney, setDateEditingJourney] = useState<EntityJourneyItemIdentifier>();
  const [entityDialogOperationType, setEntityDialogOperationType] = useState<ThingPersonDialogOperation>(ThingPersonDialogOperation.ADD);
  const [entityItems, setEntityItems] = useHistoryState<EntityItems[], EntityItemsSerialized[]>(
    'entityItems',
    [],
    true,
    serializationOptions,
  );
  const [entityVisibilities, setEntityVisibilities] = useHistoryState<EntityVisibility[]>('entityVisibilities', defaultVisibilities ?? []);
  const [eventData, setEventData] = useState<ThingEventItems[]>(defaultEventData ?? []);
  const [nextZoomTarget, setNextZoomTarget] = useState<Maybe<string>>(null);
  const [expandedEntity, setExpandedEntity] = useHistoryState<Undefinable<EntityJourneyItemIdentifier>>('expandedEntity', undefined);
  const [clickedEventId, setClickedEventId] = useState<Maybe<string>>(null);
  const [isEntityDialogOpen, setIsEntityDialogOpen] = useState(false);
  const [mapLoading, setMapLoading] = useState<Record<string, boolean>>({});
  const [entityTypeTab, setEntityTypeTab] = useState<string>();
  const [progressivelyLoadRoute, setProgressivelyLoadRoute] = useState(true);
  const [snapToRoad, setSnapToRoad] = useLocalStorage(MapStorageKeys.HISTORY_SNAP_TO_ROAD_PREFERENCE, false);
  const [thingTypeId, setThingTypeId] = useState<string>();
  const theme = useTheme();
  const autoExpand = useBoolFlag('track-thing-history-auto-expand-journey-instance-feature');

  const addEntity = (id: string, entityItemFields: EntityItemFields): void => {
    const newEntityItem = {
      ...entityItemFields,
      id,
      journeyList: [],
    };

    const newVisibilityItem = {
      entityId: id,
      journeyVisibility: [],
      isVisible: true,
    };

    setEntityItems([...entityItems, newEntityItem]);
    setEntityVisibilities([...entityVisibilities, newVisibilityItem]);
    openAddDateTimePicker(id);
  };

  const removeEntity = (id: string): void => {
    setEntityItems(entityItems.filter((item) => item.id !== id));
    setEventData(eventData.filter((item) => item.entityId !== id));
    setEntityVisibilities(entityVisibilities.filter((entity) => entity.entityId !== id));
    if (dateAddingJourney?.entityId === id) setDateAddingJourney(undefined);
    if (dateEditingJourney?.entityId === id) setDateEditingJourney(undefined);
    if (expandedEntity?.entityId === id) setExpandedEntity(undefined);
  };

  const duplicateEntity = (entityId: string, newId: string, entityItemFields: EntityItemFields): void => {
    const entityToCopy = entityItems.find((item) => item.id === entityId);
    const visibilityToCopy = entityVisibilities.find((entity) => entity.entityId === entityId);

    if (!entityToCopy || !visibilityToCopy) return;
    const newJourneyIds = entityToCopy.journeyList.map(() => ulid());

    const colorIndex = getColorIndex(entityItems);
    const newItem = {
      ...entityItemFields,
      id: newId,
      journeyList: entityToCopy.journeyList.map((journey, i) => ({
        ...journey,
        colorIndex: colorIndex + i,
        entityId: newId,
        entityType: entityItemFields.entityType,
        eventData: undefined,
        expanded: false,
        id: newJourneyIds[i],
      })),
    };

    const newVisibilityItem: EntityVisibility = {
      ...visibilityToCopy,
      entityId: newId,
      journeyVisibility: newJourneyIds.map((journeyId) => {
        return {
          isVisible: true,
          journeyId,
        };
      }),
    };

    setEntityItems([...entityItems, newItem]);
    setEntityVisibilities([...entityVisibilities, newVisibilityItem]);
  };

  const updateDateTime = (entityId: string, journeyId: string, newStartTime: Date, newEndTime: Date): void => {
    const entity = entityItems.find((entity) => entity.id === entityId);
    const journey = entity?.journeyList.find((journey) => journey.id === journeyId);

    if (journey) {
      const originalStartTime = journey.dateRange.startTime;
      const originalEndTime = journey.dateRange.endTime;

      // Normalize timestamps by removing milliseconds
      const normalizeDate = (date: Date): string => {
        return date.toISOString().split('.')[0] + 'Z';
      };

      const hasStartTimeChanged = normalizeDate(originalStartTime) !== normalizeDate(newStartTime);
      const hasEndTimeChanged = normalizeDate(originalEndTime) !== normalizeDate(newEndTime);

      if (hasStartTimeChanged || hasEndTimeChanged) {
        const updatedEntities = entityItems.map((entity) => {
          if (entity.id !== entityId) return entity;

          const updatedJourneyList = entity.journeyList.map((journey) => {
            if (journey.id !== journeyId) return journey;

            return {
              ...journey,
              dateRange: {
                endTime: newEndTime,
                startTime: newStartTime,
              },
            };
          }).sort((journey1, journey2) => journey1.dateRange.startTime > journey2.dateRange.startTime ? 1 : -1);

          return {
            ...entity,
            journeyList: updatedJourneyList,
          };
        });

        setEntityItems(updatedEntities);
        if (!autoExpand) return;
        setExpandedEntity({ entityId, journeyId });
      }
    }

    // Always close the date editing mode, regardless of whether changes were made
    setDateEditingJourney(undefined);
  };

  const changeEntity = (entityId: string, newId: string, entityItemFields: EntityItemFields): void => {
    const updatedEntityItems = entityItems.map((entity) => {
      if (entity.id !== entityId) return entity;
      return {
        ...entityItemFields,
        id: newId,
        journeyList: entity.journeyList.map((journey) => ({
          ...journey,
          entityId: newId,
          entityType: entityItemFields.entityType,
        })),
      };
    });

    const updatedVisibilities = entityVisibilities?.map((item) => {
      if (item.entityId !== entityId) return item;
      return {
        ...item,
        entityId: newId,
      };
    });

    setEntityItems(updatedEntityItems);
    setEntityVisibilities(updatedVisibilities);
  };

  const removeJourney = (entityId: string, journeyId: string): void => {
    setEntityItems(entityItems.map((entity) => {
      if (entity.id !== entityId) return entity;
      return { ...entity, journeyList: entity.journeyList.filter((journey) => journey.id !== journeyId) };
    }));
    setEventData(eventData.filter((item) => item.journeyId !== journeyId));
    setEntityVisibilities(entityVisibilities.map((entity) => {
      if (entity.entityId !== entityId) return entity;
      return { ...entity, journeyVisibility: entity.journeyVisibility.filter((journey) => journey.journeyId !== journeyId) };
    }));
  };

  const closeAddDateTimePicker = (): void => setDateAddingJourney(undefined);

  const openAddDateTimePicker = (entityId: string): void => {
    setExpandedEntity(undefined);
    setDateEditingJourney(undefined);
    setDateAddingJourney({ entityId, journeyId: ulid() });
  };

  const addJourneyToEntity = (startTime: Date, endTime: Date): void => {
    if (!dateAddingJourney) return;
    const { entityId, journeyId } = dateAddingJourney;
    setEntityItems(entityItems.map((entity, index) => {
      if (entity.id !== entityId) return entity;
      const journey: JourneyItem = {
        id: journeyId,
        colorIndex: getColorIndex(entityItems),
        dateRange: {
          endTime,
          startTime,
        },
        entityId,
        entityType: entityItems[index].entityType,
        focused: true,
      };

      const updatedJourneyList = [
        ...entity.journeyList,
        journey,
      ].sort((journey1, journey2) => journey1.dateRange.startTime > journey2.dateRange.startTime ? 1 : -1);

      return {
        ...entity,
        journeyList: updatedJourneyList,
      };
    }));

    setEntityVisibilities(entityVisibilities.map((entity) => {
      if (entity.entityId !== entityId) return entity;

      const JourneyVisibility = {
        journeyId,
        isVisible: true,
      };

      return {
        ...entity,
        isVisible: true,
        journeyVisibility: [
          ...entity.journeyVisibility,
          JourneyVisibility,
        ],
      };
    }));

    if (!autoExpand) return;
    setExpandedEntity({ entityId, journeyId });
  };

  const addEntityWithDateTime = (
    entityId: string,
    entityItemFields: EntityItemFields,
    startTime: Date,
    endTime: Date,
    focused: boolean,
  ): void => {
    const journeyId = ulid();
    const journey: JourneyItem = ({
      id: journeyId,
      colorIndex: getColorIndex(entityItems),
      dateRange: {
        startTime,
        endTime,
      },
      entityId,
      entityType: entityItemFields.entityType,
      focused,
    });

    setEntityItems([
      ...entityItems,
      {
        ...entityItemFields,
        id: entityId,
        journeyList: [journey],
      },
    ]);

    setEntityVisibilities([
      ...entityVisibilities,
      {
        entityId,
        isVisible: true,
        journeyVisibility: [
          {
            journeyId,
            isVisible: true,
          },
        ],
      },
    ]);

    if (!autoExpand) return;
    setExpandedEntity({ entityId, journeyId });
  };

  const toggleAllVisibility = (entityId: string): void => {
    setEntityVisibilities(entityVisibilities.map((entity) => {
      if (entity.entityId !== entityId) return entity;

      return {
        ...entity,
        isVisible: !entity.isVisible,
        journeyVisibility: entity.journeyVisibility.map((journey) => {
          return {
            ...journey,
            isVisible: !entity.isVisible,
          };
        }),
      };
    }));
  };

  const toggleVisibleItem = (journeyId: string, entityId: string): void => {
    setEntityVisibilities(entityVisibilities.map((entity) => {
      if (entity.entityId !== entityId) return entity;

      const updatedVisibleJourneys = entity.journeyVisibility.map((journey) => {
        if (journey.journeyId !== journeyId) return journey;

        return {
          ...journey,
          isVisible: !journey.isVisible,
        };
      });

      return {
        ...entity,
        journeyVisibility: updatedVisibleJourneys,
        isVisible: updatedVisibleJourneys.some((journey) => journey.isVisible),
      };
    }));
  };

  const toggleEditingDate = (journeyId: string, entityId: string): void => {
    setExpandedEntity(undefined);
    setDateAddingJourney(undefined);
    if (!dateEditingJourney) return setDateEditingJourney({ entityId, journeyId });
    setDateEditingJourney(undefined);
  };

  const handleExpand = (entityId: string, journeyId: string): void => {
    if (!expandedEntity) return setExpandedEntity({ entityId, journeyId });
    if (entityId === expandedEntity.entityId && journeyId === expandedEntity.journeyId) return setExpandedEntity(undefined);
    setExpandedEntity({ entityId, journeyId });
  };

  const updateJourney = (
    data: JourneyItem,
    events: PaginatedResponse<EventLocationData>,
    isSelected: boolean,
    entityDisplay: string,
  ): void => {
    if (eventData.find((event) => event.journeyId === data.id)) {
      const updatedEventData = eventData.map((event) => {
        if (event.journeyId !== data.id) return event;
        return {
          ...event,
          data: events,
          entityDisplay,
          entityId: data.entityId,
        };
      });
      return setEventData(updatedEventData);
    }

    setEventData([
      ...eventData,
      {
        color: getJourneyColor(data.colorIndex, theme),
        data: events,
        entityDisplay,
        entityId: data.entityId,
        focused: data.focused,
        isSelected,
        journeyId: data.id,
      },
    ]);

    const entity = entityVisibilities.find((entityVisibility) => entityVisibility.entityId === data.entityId);
    const journeyToUpdate = entity?.journeyVisibility.find((journey) => journey.journeyId === data.id);
    if (!journeyToUpdate) return;
    setEntityVisibilities(entityVisibilities.map((entityVisibility) => {
      if (entityVisibility.entityId !== entity?.entityId) return entityVisibility;

      return {
        ...entityVisibility,
        journeyVisibility: entityVisibility.journeyVisibility.map((journey) => {
          if (journey.journeyId !== journeyToUpdate.journeyId) return journey;

          return {
            journeyId: data.id,
            isVisible: true,
          };
        }),
      };
    }));
  };

  useEffect(() => {
    if (!eventData.length) return;
    const isAllGreaterThanLimit = eventData.every((event) =>
      event.data.items.filter((item) => item.eventTypeId === TrackingEventTypes.LOCATION_UPDATE).length > eventLimit,
    );
    if (!isAllGreaterThanLimit) return;
    setSnapToRoad(false);
  }, [eventData, eventLimit]);

  useEffect(() => {
    if (autoExpand) return;
    setExpandedEntity(undefined);
  }, [autoExpand]);

  return (
    <context.Provider
      value={{
        addEntity,
        addEntityWithDateTime,
        addJourneyToEntity,
        changeEntity,
        clickedEventId,
        closeAddDateTimePicker,
        currentEntityId,
        dateAddingJourney,
        dateEditingJourney,
        duplicateEntity,
        enableRoutingPostRequest,
        entityDialogOperationType,
        entityItems,
        entityTypeTab,
        entityVisibilities,
        eventData,
        eventLimit,
        expandedEntity,
        handleExpand,
        isEntityDialogOpen,
        mapLoading,
        nextZoomTarget,
        openAddDateTimePicker,
        progressivelyLoadRoute,
        removeEntity,
        removeJourney,
        setClickedEventId,
        setCurrentEntityId,
        setEntityDialogOperationType,
        setEntityItems,
        setEntityTypeTab,
        setEventData,
        setIsEntityDialogOpen,
        setMapLoading,
        setNextZoomTarget,
        setProgressivelyLoadRoute,
        setSnapToRoad,
        setThingTypeId,
        snapToRoad,
        thingTypeId,
        toggleAllVisibility,
        toggleEditingDate,
        toggleVisibleItem,
        updateDateTime,
        updateJourney,
      }}
    >
      {children}
    </context.Provider>
  );
};

export const useHistorySearch = (): Context => {
  const data = useContext(context);
  if (!data) throw new Error('Missing HistorySearchProvider in tree above useSearch');
  return data;
};
