import { Box, Divider, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthStatus, useAuth } from '../../auth';
import { MenuItem } from './menu-item';
import { MenuItemProps } from './menu-item.types';

interface Props {
  hasPermissions?: boolean;
  menuItems: MenuItemProps[];
  open: boolean;
}

export const MenuNavigation: FC<Props> = ({ hasPermissions = true, menuItems, open }) => {
  const { t } = useTranslation(['common']);
  const { state } = useAuth();
  const hasChildPermissions = menuItems.some((item) => item.hasPermissions);

  if (!state || state.status !== AuthStatus.AUTHENTICATED) return null;

  const handleClick = (openInNewTab?: boolean) => (path: string): void => {
    if (path.length > 0) {
      window.open(path, openInNewTab ? '_blank' : '_self');
    }
  };

  if (!hasPermissions && !hasChildPermissions) return null;

  return (
    <Box id="footer">
      <Box sx={{ height: 50 }}>
        {
          open
          && <Typography
            sx={{
              fontSize: '0.8rem',
              mx: 0.5,
              overflow: 'hidden',
              p: 2,
              whiteSpace: 'nowrap',
            }}
          >
            {t('common:component.navigation.switch-to.labels')}
          </Typography>
        }
      </Box>
      <Divider />
      <Stack direction="column">
        {menuItems.map((item, i) => (
          <MenuItem
            active={item.active}
            data-testid={item['data-testid']}
            key={i}
            handleClick={handleClick(item.openInNewTab)}
            hasPermissions={item.hasPermissions}
            icon={item.icon}
            open={open}
            path={item.path}
            sx={{ ...item.sx }}
            text={item.text}
          />
        ))}
      </Stack>
    </Box>
  );
};
