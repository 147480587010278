import { Account } from '@eagle/core-data-types';
import { Avatar as MUIAvatar, Box, Link, Menu, MenuItem, Stack, SvgIcon, Typography } from '@mui/material';
import { ResponsiveStyleValue, SxProps } from '@mui/system';
import { FC, useState } from 'react';
import { Nullable } from '../../types';
import { Badge } from '../badge';
import { useBoolFlag } from '../flags';
import { Icon, WidgetIconProps } from '../icons/widget-icon';
import { AccountSwitcher } from './account-switcher';

interface Props {
  background?: string;
  badgeColor?: string;
  'data-testid'?: string;
  handleNavigate?: () => void;
  icon?: WidgetIconProps['icon'];
  iconColor?: string;
  iconSize?: string;
  image?: string;
  onSelected?: (value: string) => void;
  options?: Account[];
  selected?: string;
  showIconOnly?: boolean;
  spacing?: ResponsiveStyleValue<string | number>;
  subtitle?: string;
  sx?: SxProps;
  tabIndex?: number;
  title?: string;
  tooltip?: string;
}

export const Avatar: FC<Props> = ({
  badgeColor,
  background,
  handleNavigate,
  icon,
  iconColor,
  iconSize,
  image,
  onSelected,
  options,
  showIconOnly,
  spacing = 2,
  subtitle,
  tabIndex,
  title,
  tooltip,
  ...props
}) => {
  const [anchorEl, setAnchorEl] = useState<Nullable<HTMLElement>>(null);
  const useNewAccountSwitcher = useBoolFlag('portals-enhanced-switch-account-feature');

  const open = Boolean(anchorEl);

  const clickHandler = (event: React.MouseEvent<HTMLElement>): void => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const handleSelected = (event: React.MouseEvent<HTMLElement>): void => {
    if (onSelected) {
      const { value } = event.currentTarget.dataset;
      if (value) {
        onSelected(value);
      }
    }
    handleClose();
  };

  const keyupHandler = (event: React.KeyboardEvent<HTMLElement>): void => {
    if (handleNavigate && event.code === 'Enter') {
      handleNavigate?.();
    }
  };

  return <Stack alignItems="center" direction="row" spacing={spacing} {...props}>
    <Box>
      <Badge
        overlap="circular"
        variant="dot"
        sx={{
          '& .MuiBadge-badge': {
            backgroundColor: badgeColor ? badgeColor : null,
            border: 'solid 2px white',
            minWidth: '12px',
            height: '12px',
            borderRadius: '8px',
            display: !badgeColor ? 'none' : 'flex',
          },
        }}
      >
        <MUIAvatar
          tabIndex={handleNavigate && tabIndex}
          onClick={() => handleNavigate?.()}
          onKeyUp={keyupHandler}
          title={tooltip}
          src={image}
          sx={{
            backgroundColor: background ? background : null,
            color: iconColor ? iconColor : null,
            cursor: handleNavigate ? 'pointer' : 'auto',
          }}
        >{icon && <Icon size={iconSize} icon={icon} />}</MUIAvatar>
      </Badge>
    </Box>
    {title && (
      <Box className='avatar' sx={{ lineHeight: 1, display: 'flex', flexDirection: 'column', gap: '2px', transition: '250ms opacity ease', opacity: !showIconOnly ? 1 : 0 }}>
        <Typography
          data-testid='avatar-title'
          onClick={() => handleNavigate?.()}
          onKeyUp={keyupHandler}
          tabIndex={(handleNavigate && tabIndex) && (tabIndex++)}
          title={tooltip}
          sx={{
            cursor: handleNavigate ? 'pointer' : 'auto',
            lineHeight: 'inherit',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
          variant="body1"
        >
          {title}
        </Typography>
        {options && options.length
          ? <>
            <Link onClick={clickHandler} sx={{ lineHeight: 'inherit', textAlign: 'left' }} data-testid="dropdown-button" component='button' variant='caption' color='inherit' underline='none'>
              <Stack direction="row" alignItems="center">
                <Box>{subtitle}</Box>
                <SvgIcon sx={{ height: '12px', ml: 0.5, width: '12px' }}><path d="m1 8 10 10 10-10z"></path></SvgIcon>
              </Stack>
            </Link>
            {useNewAccountSwitcher ? (
              <AccountSwitcher anchorEl={anchorEl} onClose={handleClose} onSelect={onSelected} />
            ) : (
              <Menu
                data-testid="dropdown-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
                sx={{ zIndex: 1400 }}
              >
                {options.filter((item) => !item.deleted).map((prop, i) => ((<MenuItem key={i} data-testid={`dropdown-menu-item-${i}`} data-value={prop._id} onClick={handleSelected}>{prop.display}</MenuItem>)))}
              </Menu>
            )}
          </>
          : <Typography sx={{ lineHeight: 'inherit', margin: 0, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} paragraph variant="caption">
            {subtitle}
          </Typography>
        }
      </Box>
    )}
  </Stack>;
};
