import { PaginatedResponse } from '@eagle/api-types';
import { LastThingEvent } from '@eagle/core-data-types';
import { FeatureTypes, TrackingEventTypes } from '@eagle/data-function-types';
import { AxiosInstance, AxiosResponse } from 'axios';
import { DateTime } from 'luxon';
import { Maybe, Undefinable } from '../types';

export interface SignedUrl {
  signedUrl: Maybe<string>;
}

export const getLastThingEvent = async (axios: AxiosInstance, thingId: string): Promise<Undefinable<LastThingEvent>> => {
  const result = await axios.get<PaginatedResponse<LastThingEvent>>(`/api/v2/last-thing-event/thing/${thingId}`, {
    params: {
      limit: 1,
      sort: '-occurred',
    },
  });
  return result.data.items[0];
};

export const getLastThingLocation = async (axios: AxiosInstance, thingId: string): Promise<Undefinable<LastThingEvent>> => {
  const result = await axios.get<PaginatedResponse<LastThingEvent>>(`/api/v2/last-thing-event/thing/${thingId}`, {
    params: {
      filter: {
        featureTypeId: FeatureTypes.TRACKING_V0,
        feature: 'tracking',
        eventTypeId: TrackingEventTypes.LOCATION_UPDATE,
      },
      limit: 1,
      sort: '-occurred',
    },
  });
  return result.data.items[0];
};

export const getSignedUrl = async (axios: AxiosInstance, url?: URL | string): Promise<{ data: SignedUrl }> => {
  if (!url) return { data: { signedUrl: null } };
  const getNewSigned = async (): Promise<AxiosResponse<SignedUrl, any>> => axios.post<SignedUrl>('/api/v1/signed-url/read-access', { url });
  const preSignedUrl = (): Promise<{ data: SignedUrl }> => {
    const { searchParams } = new URL(url);
    const expires = DateTime.fromMillis(parseInt(searchParams.get('Expires') ?? '0', 10) * 1000);
    if (DateTime.now() > expires) return getNewSigned();
    return Promise.resolve({ data: { signedUrl: url.toString() } });
  };

  const data = new URL(url).search
    ? await preSignedUrl()
    : await getNewSigned();
  return data;
};
