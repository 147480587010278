/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Observable } from 'rxjs';
import { Undefinable } from '../types';

export const useObservable = <T>(observable: Observable<T>, onError?: (error: Error) => unknown): Undefinable<T> => {
  const [state, setState] = useState<T>();

  useEffect(() => {
    const sub = observable.subscribe(setState, onError);
    return () => sub.unsubscribe();
  }, [observable]);

  return state;
};
