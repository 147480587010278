/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import { DateTime } from 'luxon';
import { useEffect } from 'react';
import { useLocalStorage } from '../util/local-storage';
import { asNumberOrUndefined } from '../util/maps';

interface GeoLocation {
  city?: string;
  country_code?: string;
  country_name?: string;
  expiry?: number;
  IPv4?: string;
  latitude?: number;
  loading: boolean;
  longitude?: number;
  postal?: string;
  state?: string;
}

const cleanGeoData = (geoData: GeoLocation): GeoLocation => {
  const { latitude, longitude } = geoData;
  return { ...geoData, latitude: asNumberOrUndefined(latitude), longitude: asNumberOrUndefined(longitude) };
};

export const useGeoLocation = (): GeoLocation => {
  const [geoData, setGeoData] = useLocalStorage<GeoLocation>('GEOLOCATION', { loading: true });
  const api = 'https://geolocation-db.com/json/';
  const currentTime = DateTime.now();

  useEffect(() => {
    if (geoData && geoData.expiry && currentTime.toMillis() < geoData.expiry) return;
    void loadGeoData();
  }, []);

  const loadGeoData = async (): Promise<void> => {
    setGeoData({ ...geoData, loading: true });
    await axios.get<Omit<GeoLocation, 'expiry'>>(api)
      .then((res) => {
        setGeoData({
          ...res.data,
          expiry: currentTime.plus({ day: 1 }).toMillis(),
          loading: false,
        });
      })
      .catch(() => setGeoData({ ...geoData, loading: false }));
  };

  return cleanGeoData(geoData);
};
