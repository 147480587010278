import { PropertiesDefinition } from '@eagle/common';
import { FetchAllCache } from '../../hooks';
import { EntityType } from '../../pages/list/types';
import { CacheDataTypes, CommonEntityWithDeleted, CommonEntityWithProperties } from '../../types';

export enum FilterTypes {
  ALERT_TYPE = 'alertTypeId',
  DAY_OF_WEEKS = 'dayOfWeeks',
  DEVICE_TYPE = 'deviceTypeId',
  EVENT_TYPE = 'eventTypeId',
  FEATURE = 'feature',
  FEATURE_TYPE_PREFIX = 'featureTypeIdPrefix',
  GEOFENCE_GROUP = 'geofenceGroupIds',
  GEOFENCE_TYPE = 'geofenceTypeId',
  GROUP = 'groupIds',
  ID = '_id',
  INSTALLATION_TEMPLATE = 'installationTemplate._id',
  PERSON = 'personId',
  PERSON_TYPE = 'personTypeId',
  POINT_OF_INTEREST_TYPE = 'pointOfInterestTypeId',
  REFERENCE = 'referenceId',
  ROLES = 'roleIds',
  SHARED_THING_TYPE = 'sharedThingTypeId',
  THING = 'thingId',
  THING_TYPE = 'thingTypeId',
  TIMEZONE = 'timeZone',
  USER = 'userId',
  USER_GROUP = 'userGroupId',
  USERS_USER_GROUP = 'userGroups.userGroupId',
}

export enum FilterPathIdentifiers {
  PERSON = CacheDataTypes.PERSON,
  THING = CacheDataTypes.THING,
}

export interface FilterAttributes {
  getEntityTypeId?: (entity: CommonEntityWithProperties) => string;
  pathIdentifier?: FilterPathIdentifiers;
  propertyPathPrefix?: 'properties' | 'sharedProperties';
  filterEntityType?: (entityTypes: CommonEntityWithProperties) => boolean;
  transformProperties?: (input: { properties: PropertiesDefinition; sharedProperties?: PropertiesDefinition }) => { properties: PropertiesDefinition; sharedProperties?: PropertiesDefinition };
  typeCache: FetchAllCache;
  typePath: FilterTypes;
}

export enum FilterDataTypes {
  ALERT,
  API,
  CACHE,
  OTHER,
}

export interface FilterFieldProps {
  entityTypes: EntityType[];
  pathIdentifier?: FilterPathIdentifiers;
  propertyLabel: string;
  replacePath?: string;
  selectMultiple?: boolean;
  typePropertyName: FilterTypes;
}

export interface FilterFieldNewProps {
  apiUrl?: string;
  attributes?: FilterAttributes;
  dataType: FilterDataTypes;
  entityCache?: FetchAllCache;
  entityTypes?: EntityType[];
  fieldLabel: string;
  findItems?: (search: string) => Promise<CommonEntityWithDeleted<string>[]>;
  omitEntityFilter?: boolean;
  pathIdentifier?: FilterPathIdentifiers;
  propertyLabel: string;
  replacePath?: string;
  selectMultiple?: boolean;
  typePropertyName: FilterTypes | string;
}
