/* eslint-disable react-hooks/exhaustive-deps */
import { MarkerType } from '@eagle/api-types';
import { useTheme } from '@mui/material';
import L, { PointExpression } from 'leaflet';
import { debounce, isString } from 'lodash';
import { ReactNode, useCallback, useRef, useState } from 'react';
import { Marker, Tooltip } from 'react-leaflet';
import { useFetchOneCache, usePromiseConditional } from '../../hooks';
import { makeStyles } from '../../theme';
import { CommonEntity, Undefinable } from '../../types';
import { CacheDataTypes } from '../../types/cache';
import { getMarkerTemplate, useMapContext } from '../../util/maps';
import { FieldLoading } from '../field';
import { Icon } from '../icons/widget-icon';

export interface MapMarkerProps {
  cacheDataType: CacheDataTypes;
  disableMarkerClick?: boolean;
  'data-testid'?: string;
  entityId: string;
  position: L.LatLngExpression;
  background?: string;
  markerType?: MarkerType;
  indicator?: string;
  icon?: ReactNode | string;
  iconColor?: string;
  image?: string;
}

/**
  * NATIVE FUNCTION: Display map marker
  */
// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function MapMarker<T extends CommonEntity<unknown>>({ cacheDataType, disableMarkerClick, entityId, position, background, markerType, icon, iconColor, image, indicator, ...props }: MapMarkerProps): JSX.Element {
  const { handleMarkerClick } = useMapContext();
  const [markerCount, setMarkerCount] = useState(0);
  const entityCache = useFetchOneCache(cacheDataType);
  const markerReference = useRef<L.Marker>(null);
  const theme = useTheme();
  const marker = markerType || MarkerType.PIN;
  const tooltipOffset: PointExpression = marker === MarkerType.PIN ? [0, 37] : [0, 45];
  const markerBackground = background || theme.marker.background;
  const iconComponent = isString(icon) ? <Icon icon={icon} /> : icon;

  const [entityData] = usePromiseConditional<Undefinable<T>>(() => entityCache.one(entityId), () => markerCount === 0, [markerCount]);
  const template = getMarkerTemplate(marker, iconComponent, image, markerBackground, iconColor, indicator);

  const updateMarkerCount = useCallback(() => debounce((e: L.LeafletEvent) => {
    setMarkerCount((e.target as L.Marker).getElement()?.childElementCount ?? 0);
  }, 200), []);

  const { classes } = makeStyles()(() => ({
    tooltip: {
      pointerEvents: 'unset',
      maxWidth: '150px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  }))();

  return (
    <Marker
      ref={markerReference}
      eventHandlers={{
        add: updateMarkerCount,
        click: !disableMarkerClick ? () => {
          handleMarkerClick(entityId);
        } : undefined,
      }}
      icon={template}
      position={position}
    >
      <Tooltip
        direction="center"
        offset={tooltipOffset}
        permanent
        className={classes.tooltip}
      >
        {entityData ? <span data-chromatic="ignore" data-testid={props['data-testid']} title={entityData.display}>{entityData.display}</span> : <FieldLoading />}
      </Tooltip>
    </Marker>
  );
}
