/* eslint-disable react-hooks/exhaustive-deps */
import { Stack } from '@mui/material';
import { FC, useEffect } from 'react';
import { AppliedFilter, EntityField, FilterField, FilterOptions } from '../entity-search/types';
import { FilterInputItem } from './filter-input-item';

interface Props {
  fields: FilterField[];
  filterOptions: FilterOptions;
  filters: AppliedFilter[];
  includeDeleted?: boolean;
  onFiltersChanged?: (filters: AppliedFilter[]) => unknown;
  storageKey: string;
}

export const FILTERS_KEY = 'filters';

export const NewFilterBuilder: FC<Props> = ({
  fields,
  filterOptions,
  filters,
  includeDeleted,
  onFiltersChanged,
  storageKey,
}) => {
  useEffect(() => {
    const appliedEntityFilterIds = filters
      .filter((filter) => filter.definition.type === 'entity')
      .map(({ value }) => (value as EntityField).id);

    const filtersToRemove = filters.filter(
      (filter) =>
        filter.ids !== undefined
        && filter.ids.every((id) => !appliedEntityFilterIds.includes(id)),
    );

    const updatedFilters = filters.filter((filter) => !filtersToRemove.includes(filter));

    if (filtersToRemove.length) {
      onFiltersChanged?.(updatedFilters);
    }
  }, [filters]);

  return (
    <Stack spacing={2}>
      {fields.map((field, i) => (
        <FilterInputItem
          key={i}
          field={field}
          filterOptions={filterOptions}
          filters={filters}
          includeDeleted={includeDeleted}
          onFiltersChanged={onFiltersChanged}
          storageKey={storageKey}
        />
      ))}
    </Stack>
  );
};
