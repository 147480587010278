import { Box, Stack, useTheme } from '@mui/material';
import { FC } from 'react';
import { ParticleAnimation } from './particle-animation';

interface Props {
  bottomFooterText?: JSX.Element;
  footerLogo: JSX.Element;
  header: JSX.Element;
  logo: JSX.Element;
  signInButton: JSX.Element;
  subHeader: JSX.Element;
  termsAndConditionsText: JSX.Element;
  topFooterText: JSX.Element;
}

const LayoutDesktop: FC<Props> = ({
  bottomFooterText,
  footerLogo,
  header,
  logo,
  signInButton,
  subHeader,
  termsAndConditionsText,
  topFooterText,
}) => {
  const theme = useTheme();

  return (
    <Stack
      id="landing-page"
      sx={{
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        justifyContent: 'space-between',
        overflow: 'auto',
        p: 5,
        width: '100%',
        height: '100%',
      }}
    >
      <Box id="logo" sx={{ width: 'fit-content', position: 'absolute', top: '50%', left: '50%', zIndex: 1, transform: 'translate(-50%,-50%)', textAlign: 'center' }}>
        {logo}
      </Box>
      <Box id="sign-in" sx={{ position: 'absolute', top: 'calc(50% + 70px)', left: '50%', zIndex: 1, transform: 'translate(-50%,-50%)' }}>
        {signInButton}
      </Box>
      <Box id="terms-and-conditions" sx={{ position: 'absolute', top: 'calc(50% + 140px)', left: '50%', zIndex: 1, transform: 'translate(-50%,-50%)' }}>
        {termsAndConditionsText}
      </Box>
      <Stack spacing={2} sx={{ my: 1, position: 'relative', zIndex: 1 }}>
        {header}
        {subHeader}
      </Stack>

      <Stack id="footer" direction="row" sx={{ zIndex: 1 }}>
        {footerLogo}
        <Box sx={{ width: '1px', background: theme.landingPage.footer.contrastText, ml: '8px', mr: '10px' }}></Box>
        <Stack spacing={0} sx={{ justifyContent: 'center' }}>
          {topFooterText}
          {bottomFooterText}
        </Stack>
      </Stack>
      <ParticleAnimation />
    </Stack>
  );
};

export default LayoutDesktop;
