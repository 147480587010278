import { Tooltip, Typography } from '@mui/material';
import { FC } from 'react';
import { TooltipPlacement } from './date-time-tooltip.types';

interface Props {
  disableHoverListener?: boolean;
  display: JSX.Element | string;
  placement?: TooltipPlacement;
  size?: 'small';
  title: JSX.Element | string;
}

export const DateTimeTooltip: FC<Props> = ({ disableHoverListener, display, placement, size, title }) => {
  return (
    <Tooltip
      arrow
      disableHoverListener={disableHoverListener}
      enterTouchDelay={0}
      followCursor
      placement={placement}
      title={title}
    >
      <Typography
        noWrap
        data-testid="datetime-with-tooltip"
        data-chromatic="ignore"
        sx={{ fontSize: size ? '0.75rem' : '0.875rem', textDecoration: disableHoverListener ? 'none' : 'underline dotted' }}
      >
        {display}
      </Typography>
    </Tooltip>
  );
};
