import { Geofence, Thing } from '@eagle/core-data-types';
import { Close, Place } from '@mui/icons-material';
import { Box, Drawer, IconButton, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import axiosStatic from 'axios';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMap } from 'react-leaflet';
import { API_CALL_TEXT_LENGTH, HERE_MAP_API_KEY, MAP_FLY_TO_DURATION } from '../../constants';
import { useConfig, useSmallScreen } from '../../hooks';
import { FindItemsDeferredResult, FindItemsResult } from '../../pages';
import { SearchProvider } from '../../pages/list/use-search';
import { scrollbar } from '../../style';
import { MapProvider, wrapLongitude } from '../../util/maps';
import { FlexBox } from '../flex-box';
import { SearchMapResults } from '../search-map-results';
import { FindAddressProps, MapDiscoverItem, ThingSearchController } from '../search-thing-map';
import { MapLayersProvider } from './hooks';
import { SearchButton } from './map-controls/search-button';

const SEARCH_PADDING = 0.5;
const HERE_MAPS_URL = 'https://discover.search.hereapi.com/v1/discover';
const DRAWER_CONTAINER_ID = 'inlay-search-root';
const MODAL_DRAWER_CONTAINER_ID = 'modal-inlay-map-layer-selection-root';

interface Props {
  onAddressClick?: (item: MapDiscoverItem) => void;
}

export const InlayMapSearch: FC<Props> = ({
  onAddressClick,
}) => {
  const { t } = useTranslation(['common']);
  const config = useConfig();
  const smallScreen = useSmallScreen();
  const theme = useTheme();
  const map = useMap();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const drawerContainer = document.getElementById(MODAL_DRAWER_CONTAINER_ID) ?? document.getElementById(DRAWER_CONTAINER_ID);

  const findAddresses = ({ bounds, search }: FindAddressProps): FindItemsDeferredResult<MapDiscoverItem> => {
    const cancelToken = axiosStatic.CancelToken.source();

    if (!search || search.length < API_CALL_TEXT_LENGTH) {
      return {
        cancel: () => cancelToken.cancel(),
        promise: Promise.resolve({
          result: {
            itemCount: 0,
            results: [],
          },
          resultDescription: t('common:component.search.hint.less-than-count', { count: API_CALL_TEXT_LENGTH }),
        }),
      };
    }

    return {
      cancel: () => cancelToken.cancel(),
      promise: axiosStatic.get<{ items: MapDiscoverItem[] }>(HERE_MAPS_URL, {
        cancelToken: cancelToken.token,
        params: {
          apiKey: config.hereMaps?.apiKey ?? HERE_MAP_API_KEY,
          in: `bbox:${wrapLongitude(bounds.west - SEARCH_PADDING)},${bounds.south - SEARCH_PADDING},${wrapLongitude(bounds.east + SEARCH_PADDING)},${bounds.north + SEARCH_PADDING}`,
          lang: navigator.language,
          q: search,
        },
      }).then((response) => {
        return {
          result: {
            itemCount: 0,
            results: response.data.items,
          },
          resultDescription: '',
        };
      }),
    };
  };

  const renderAddressItem = ({ title }: MapDiscoverItem): JSX.Element => (
    <Stack direction="row" justifyContent="space-between">
      <Typography noWrap>{title}</Typography>
      <Tooltip title={t('common:component.search-drawer.hint.tooltip')}>
        <Place sx={{ color: theme.palette.primary.main }} />
      </Tooltip>
    </Stack>
  );

  const renderListContent = (
    items: {
      addresses?: FindItemsResult<MapDiscoverItem>;
      geofences?: FindItemsResult<Geofence>;
      things?: FindItemsResult<Thing>;
    },
    isLoading: boolean,
    handleDrawerClose: () => void,
    highlightIndex?: number,
    searchQuery?: string,
  ): JSX.Element => (
    <SearchMapResults<Thing, MapDiscoverItem, Geofence>
      displayOnlyLocations
      handleDrawerClose={handleDrawerClose}
      handleFormatAddressItem={renderAddressItem}
      highlightIndex={highlightIndex}
      initialInstructions={t('common:component.lookup.hint.initial')}
      isInlayMap
      isLoading={isLoading}
      noResultsInstructions={t('common:common.hint.list.no-results')}
      onAddressClick={(item: MapDiscoverItem) => {
        map.panTo(item.position, { animate: true, duration: MAP_FLY_TO_DURATION });
        setDrawerOpen(false);
        onAddressClick?.(item);
      }}
      searchQuery={searchQuery}
      searchResults={items}
      selectedItem={null}
    />
  );

  return (
    <SearchProvider>
      <SearchButton sx={{ position: 'absolute', right: 16, top: 72, zIndex: 801 }} onClick={() => setDrawerOpen(true)} />
      <FlexBox
        data-testid="inlay-search-root"
        id={DRAWER_CONTAINER_ID}
        sx={{
          height: 1,
          pointerEvents: drawerOpen ? 'all' : 'none',
          position: 'absolute',
          width: 1,
        }}
      >
        <Drawer
          anchor="right"
          BackdropProps={{
            style: { position: 'absolute' },
            onClick: () => setDrawerOpen(false),
          }}
          ModalProps={{
            container: drawerContainer,
            sx: { position: 'absolute' },
          }}
          open={drawerOpen}
          PaperProps={{
            sx: {
              py: 1,
              px: 2,
              position: 'absolute',
              width: smallScreen ? '100%' : '50%',
              ...scrollbar,
            },
          }}
          SlideProps={{
            onEnter: (node) => {
              node.style.transform = 'translateX(0%)';
            },
            onExiting: (node) => {
              node.style.transform = 'translateX(100%)';
            },
          }}
        >
          <Stack alignItems="center" direction="row" mb={1} justifyContent="space-between">
            <Typography variant="body1">{t('common:terms.search')}</Typography>
            <IconButton
              aria-label={t('common:common.action.close')}
              onClick={() => setDrawerOpen(false)}
              size="small"
            >
              <Close />
            </IconButton>
          </Stack>
          <Box>
            <MapLayersProvider>
              <MapProvider>
                <ThingSearchController
                  disableFilter
                  isInlayMap
                  onAddressQueryChanged={findAddresses}
                  renderListContent={renderListContent}
                  storageKey="things"
                  tFunction={t}
                />
              </MapProvider>
            </MapLayersProvider>
          </Box>
        </Drawer>
      </FlexBox>
    </SearchProvider>
  );
};
