import { Json } from '@eagle/common';
import { Account, AccountTemplate, CreateAccountFromTemplateRequest, LifecycleTemplate, SharedThing, Stage } from '@eagle/core-data-types';
import { Reducer } from 'react';
import { Nullable } from '../../../types';
import { UserBasicType } from '../../multiple-user-basic-editor';

export interface StakeholderState {
  accountId?: Nullable<string>;
  accountTemplateIds: string[];
  allowAccountRequests: boolean;
  createAccount?: {
    accountTemplateId: string;
    data: CreateAccountFromTemplateRequest;
    createdAccountId?: string;
    isCustomHomeDomain: boolean;
  };
  initialAccountId?: string;
  isEditing: boolean;
  isDirty: boolean;
  isCreatingNewAccount: boolean;
  isRequired: boolean;
  lastAccountSearch?: string;
  role: string;
}

export enum StageConfirmDialogStep {
  edit = 0,
  confirm = 1
}

export interface StageConfirmDialogState {
  confirmationChecked: boolean;
  currentStep: StageConfirmDialogStep;
  isSubmitting: boolean;
  stakeholders: StakeholderState[];
}

export type StageConfirmDialogAction =
  { type: 'SET_STEP'; step: StageConfirmDialogStep } |
  { type: 'SET_ACCOUNT'; role: string; accountId: Nullable<string> } |
  { type: 'START_SUBMIT' } |
  { type: 'SUBMIT_FINISHED' } |
  { type: 'SET_CONFIRMATION'; value: boolean } |
  { type: 'RESET_STAKEHOLDER'; role: string; sharedThing: SharedThing } |
  { type: 'START_STAKEHOLDER_EDIT_MODE'; role: string } |
  { type: 'CANCEL_STAKEHOLDER_EDIT_MODE'; role: string } |
  { type: 'FINISH_STAKEHOLDER_EDIT_MODE'; role: string } |
  { type: 'SET_LAST_ACCOUNT_SEARCH'; role: string; search: string } |
  { type: 'START_STAKEHOLDER_CREATE_MODE'; role: string; accountTemplates: AccountTemplate[]; account: Account } |
  { type: 'CANCEL_STAKEHOLDER_CREATE_MODE'; role: string } |
  { type: 'SET_ACCOUNT_TEMPLATE'; role: string; accountTemplate: AccountTemplate; account: Account } |
  { type: 'SET_ACCOUNT_TYPE'; role: string; accountTypeId: string } |
  { type: 'SET_CREATE_ACCOUNT_DATA'; role: string; field: keyof CreateAccountFromTemplateRequest; value: Json | UserBasicType[] } |
  { type: 'SET_CREATED_ACCOUNT_ID'; role: string; createdAccountId: string }

export const stageConfirmDialogReducer: Reducer<StageConfirmDialogState, StageConfirmDialogAction> = (state, action) => {
  switch (action.type) {
    case 'SET_STEP':
      return {
        ...state,
        currentStep: action.step,
      };

    case 'SET_ACCOUNT': {
      const updatedStakeholders = updateStakeholderState(
        state.stakeholders,
        action.role,
        (stakeholder) => ({ ...stakeholder, accountId: action.accountId, isEditing: false, isDirty: true }),
      );

      return {
        ...state,
        stakeholders: updatedStakeholders,
      };
    }

    case 'START_SUBMIT':
      return {
        ...state,
        isSubmitting: true,
      };

    case 'SET_CONFIRMATION':
      return {
        ...state,
        confirmationChecked: action.value,
      };

    case 'SUBMIT_FINISHED':
      return {
        ...state,
        isSubmitting: false,
      };

    case 'START_STAKEHOLDER_EDIT_MODE': {
      const updatedStakeholders = updateStakeholderState(
        state.stakeholders,
        action.role,
        (stakeholder) => ({ ...stakeholder, isEditing: true }),
      );

      return {
        ...state,
        stakeholders: updatedStakeholders,
      };
    }

    case 'CANCEL_STAKEHOLDER_EDIT_MODE': {
      const updatedStakeholders = updateStakeholderState(
        state.stakeholders,
        action.role,
        (stakeholder) => ({ ...stakeholder, isEditing: false, isDirty: false, accountId: stakeholder.initialAccountId }),
      );

      return {
        ...state,
        stakeholders: updatedStakeholders,
      };
    }

    case 'FINISH_STAKEHOLDER_EDIT_MODE': {
      const updatedStakeholders = updateStakeholderState(
        state.stakeholders,
        action.role,
        (stakeholder) => ({ ...stakeholder, isEditing: false }),
      );

      return {
        ...state,
        stakeholders: updatedStakeholders,
      };
    }

    case 'SET_LAST_ACCOUNT_SEARCH': {
      const updatedStakeholders = updateStakeholderState(
        state.stakeholders,
        action.role,
        (stakeholder) => ({ ...stakeholder, lastAccountSearch: action.search }),
      );

      return {
        ...state,
        stakeholders: updatedStakeholders,
      };
    }

    case 'START_STAKEHOLDER_CREATE_MODE': {
      const updatedStakeholders = updateStakeholderState(state.stakeholders, action.role, (stakeholder) => {
        if (action.accountTemplates.length === 1) {
          return {
            ...stakeholder,
            isCreatingNewAccount: true,
            isEditing: false,
            createAccount: {
              accountTemplateId: action.accountTemplates[0]._id,
              data: createInitialAccountData(action.accountTemplates[0], stakeholder.lastAccountSearch),
              isCustomHomeDomain: action.accountTemplates[0].accountDefaults.homeDomain.type === 'custom',
            },
          };
        }
        return { ...stakeholder, isCreatingNewAccount: true };
      });

      return {
        ...state,
        stakeholders: updatedStakeholders,
      };
    }

    case 'CANCEL_STAKEHOLDER_CREATE_MODE': {
      const updatedStakeholders = updateStakeholderState(
        state.stakeholders,
        action.role,
        (stakeholder) => {
          const updatedStakeholder: StakeholderState = { ...stakeholder, isCreatingNewAccount: false, createAccount: undefined };
          if (stakeholder.isEditing) {
            updatedStakeholder.accountId = updatedStakeholder.initialAccountId;
            updatedStakeholder.isDirty = false;
          }

          return updatedStakeholder;
        },
      );

      return {
        ...state,
        stakeholders: updatedStakeholders,
      };
    }

    case 'SET_ACCOUNT_TEMPLATE': {
      const updatedStakeholders = updateStakeholderState(
        state.stakeholders,
        action.role,
        (stakeholder) => {
          return {
            ...stakeholder,
            createAccount: {
              accountTemplateId: action.accountTemplate._id,
              data: createInitialAccountData(action.accountTemplate, stakeholder.lastAccountSearch),
              isCustomHomeDomain: action.accountTemplate.accountDefaults.homeDomain.type === 'custom',
            },
          };
        },
      );

      return {
        ...state,
        stakeholders: updatedStakeholders,
      };
    }

    case 'SET_ACCOUNT_TYPE': {
      const updatedStakeholders = updateStakeholderState(
        state.stakeholders,
        action.role,
        (stakeholder) => {
          if (!stakeholder.createAccount) {
            return stakeholder;
          }
          return { ...stakeholder, createAccount: { ...stakeholder.createAccount, data: { ...stakeholder.createAccount.data, accountTypeId: action.accountTypeId, properties: {} } } };
        },
      );

      return {
        ...state,
        stakeholders: updatedStakeholders,
      };
    }

    case 'SET_CREATE_ACCOUNT_DATA': {
      const updatedStakeholders = updateStakeholderState(
        state.stakeholders,
        action.role,
        (stakeholder) => {
          if (!stakeholder.createAccount) {
            return stakeholder;
          }
          return { ...stakeholder, createAccount: { ...stakeholder.createAccount, data: { ...stakeholder.createAccount.data, [action.field]: action.value } } };
        },
      );

      return {
        ...state,
        stakeholders: updatedStakeholders,
      };
    }

    case 'SET_CREATED_ACCOUNT_ID': {
      const updatedStakeholders = updateStakeholderState(
        state.stakeholders,
        action.role,
        (stakeholder) => {
          if (stakeholder.createAccount) {
            return {
              ...stakeholder,
              createAccount: {
                ...stakeholder.createAccount,
                createdAccountId: action.createdAccountId,
              },
            };
          }
          return stakeholder;
        },
      );

      return {
        ...state,
        stakeholders: updatedStakeholders,
      };
    }

    default:
      return state;
  }
};

const updateStakeholderState = (stakeholders: StakeholderState[], role: string, updates: (state: StakeholderState) => StakeholderState): StakeholderState[] => {
  const stakeholderIndex = stakeholders.findIndex((stakeholderState) => stakeholderState.role === role);
  if (stakeholderIndex === -1) {
    return stakeholders;
  }

  const updatedStakeholders = [...stakeholders];

  updatedStakeholders[stakeholderIndex] = updates(updatedStakeholders[stakeholderIndex]);

  return updatedStakeholders;
};

export const initStageConfirmDialogReducer = ({ stage, sharedThing, lifecycleTemplate }: { stage: Stage; sharedThing: SharedThing; lifecycleTemplate: LifecycleTemplate }): StageConfirmDialogState => {
  return {
    currentStep: StageConfirmDialogStep.edit,
    confirmationChecked: false,
    isSubmitting: false,
    stakeholders: Object.entries(stage.stakeholders ?? []).map(([role, stakeholder]) => {
      const initialAccountId = sharedThing.lifecycleState?.stakeholders?.[role] ?? lifecycleTemplate.stakeholderAccounts[role].fixedAccountId ?? undefined;
      const accountTemplateIds = lifecycleTemplate.stakeholderAccounts[role].accountTemplateIds;

      return {
        role,
        initialAccountId,
        allowAccountRequests: Boolean(stakeholder.allowAccountRequest),
        accountId: initialAccountId,
        isEditing: false,
        isDirty: false,
        accountTemplateIds: accountTemplateIds ?? [],
        isCreatingNewAccount: false,
        isRequired: Boolean(stage.enterConditions?.requiredStakeholders?.includes(role)),
      };
    }),
  };
};

const createInitialAccountData = (accountTemplate: AccountTemplate, lastAccountSearch?: string): CreateAccountFromTemplateRequest => {
  const accountTypeId = accountTemplate.accountDefaults.accountTypeIds.length === 1 ? accountTemplate.accountDefaults.accountTypeIds[0] : null;

  return {
    accountTypeId,
    display: lastAccountSearch ?? '',
    users: [{ email: '', display: '' }],
    homeDomain: accountTemplate.accountDefaults.homeDomain.type === 'custom' ? '' : null,
    labels: accountTemplate.accountDefaults.labels,
    properties: {},
    tags: accountTemplate.accountDefaults.tags,
  };
};
