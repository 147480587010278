import { OpenInFull } from '@mui/icons-material';
import { Box, Paper, Typography, useTheme } from '@mui/material';
import chroma from 'chroma-js';
import L from 'leaflet';
import { CSSProperties, FC, PropsWithChildren, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { MapContainer, Marker, Tooltip } from 'react-leaflet';
import { MAP_MAX_ZOOM, MAP_MIN_ZOOM } from '../../constants';
import { useSmallScreen } from '../../hooks';
import { SearchProvider } from '../../pages/list/use-search';
import { ValidateResize } from '../../util';
import { getMarkerTemplate, MapProvider } from '../../util/maps';
import { FlexBox } from '../flex-box';
import { MapDiscoverItem } from '../search-thing-map';
import { MapLayersProvider } from './hooks';
import { InlayMapSearch } from './inlay-map-search';
import { ToggleLayers } from './layer-selection/layer-selection.types';
import { MapButton } from './map-button';
import { ZoomButtons } from './map-controls/zoom-buttons';
import { MapLayerController } from './map-layer-controller';
import { ViewMapButton } from './view-map-button';

interface Props extends PropsWithChildren {
  'data-testid'?: string;
  allowFullScreen?: boolean;
  allowSearch?: boolean;
  blurred?: boolean;
  center?: L.LatLng;
  customMarker?: ReactNode;
  disableRemountOnChange?: boolean;
  disabledLayers?: ToggleLayers[];
  drawerLayerSelection?: boolean;
  entityId?: string;
  marker?: ReactNode;
  onAddressClick?: (item: MapDiscoverItem) => void;
  onOpenInFullClick?: () => void;
  overlay?: JSX.Element;
  overlayLabel?: string;
  pinLabel?: ReactNode;
  renderMapLayersProvider?: boolean;
  scrollWheelZoom?: boolean;
  showMarker?: boolean;
  static?: boolean;
  style?: CSSProperties;
  zoom: number;
  shouldUseLocalStorage?: boolean;
}

interface MarkerProps extends PropsWithChildren {
  center: L.LatLng;
  pinLabelPosition?: L.Direction;
}

const MarkerFormatted: FC<MarkerProps> = ({ center, children }) => {
  return <Marker icon={getMarkerTemplate()} position={center}>{children}</Marker>;
};

export const InlayMap: FC<Props> = ({
  allowFullScreen = false,
  allowSearch,
  blurred,
  center,
  children,
  customMarker,
  disableRemountOnChange = false,
  disabledLayers = [],
  drawerLayerSelection = false,
  entityId,
  marker,
  onAddressClick,
  onOpenInFullClick,
  overlay,
  overlayLabel,
  pinLabel,
  renderMapLayersProvider = true,
  scrollWheelZoom,
  showMarker = true,
  static: staticMap,
  style,
  zoom,
  shouldUseLocalStorage,
  ...props
}) => {
  const { t } = useTranslation(['common']);
  const theme = useTheme();
  const smallScreen = useSmallScreen();

  const body = (
    <MapProvider>
      <Box
        className="InlayMap-root"
        sx={{
          '& .leaflet-map-pane': { transform: blurred ? 'inherit !important' : 'inherit', zIndex: blurred ? 'inherit' : '400' },
          '& .draw-control-disabled': { backgroundColor: theme.map.editControl.disabled, pointerEvents: 'none' },
          '& .leaflet-container': { height: '100%', width: '100%' },
          '& .leaflet-container a': { color: theme.palette.common.white },
          '& .leaflet-control': { marginTop: 0 },
          '& .leaflet-div-icon': { display: 'flex', background: 'none', border: 'none' },
          '& .leaflet-editing-icon': { backgroundColor: theme.palette.common.white, borderStyle: 'solid', borderWidth: '1px' },
          '& .leaflet-draw-tooltip:not(.leaflet-error-draw-tooltip)': { display: 'none' },
          '& .leaflet-marker-icon': { outline: 'none' },
          '& .leaflet-interactive:focus': { outline: 'none' },
          '& .leaflet-popup-content': { margin: 0 },
          '& .leaflet-popup-content-wrapper': { borderRadius: theme.shape.borderRadius, padding: 0 },
          '& .leaflet-tooltip': { backgroundColor: chroma(theme.palette.background.default).alpha(0.8).css() },
          '& .leaflet-top': { top: '35%' },
          position: 'relative',
          boxShadow: theme.shadows[1],
          height: 1,
          width: '100%',
          overflow: 'hidden',
        }}
      >
        <FlexBox className="InlayMap-overlayBlocker" sx={{ display: staticMap ? 'block' : 'none', height: '100%', position: 'absolute', width: '100%', zIndex: 501 }} />
        {overlay && <FlexBox sx={{ position: 'absolute', width: '100%', left: 0, top: 0, zIndex: 999, justifyContent: 'center' }}>{overlay}</FlexBox>}
        <FlexBox data-testid={props['data-testid']} sx={{ filter: !center && !allowSearch ? 'blur(4px)' : undefined, height: 1 }}>
          <MapContainer
            key={!disableRemountOnChange ? new Date().getTime() : ''}
            center={center ?? L.latLng(0, 0)}
            maxBoundsViscosity={0.8}
            maxZoom={MAP_MAX_ZOOM}
            dragging={!blurred}
            minZoom={MAP_MIN_ZOOM}
            preferCanvas={true}
            renderer={new L.Canvas()}
            scrollWheelZoom={scrollWheelZoom}
            zoom={center ? zoom : MAP_MIN_ZOOM}
            zoomAnimation={true}
            zoomControl={false}
            zoomDelta={1}
            zoomSnap={0.5}
          >
            <SearchProvider>
              <ValidateResize />
              <MapLayerController
                disabledLayers={disabledLayers}
                drawerLayerSelection={drawerLayerSelection}
                shouldShowLayerSelection={center && !blurred}
              />
              {center && marker}
              {center && showMarker
                && <>
                  {customMarker
                    ? customMarker
                    : <MarkerFormatted center={center} >
                      {pinLabel
                        && <Tooltip
                          direction='right'
                          offset={[15, -10]}
                          opacity={1}
                          permanent
                        >
                          {pinLabel}
                        </Tooltip>
                      }
                    </MarkerFormatted>
                  }
                </>
              }
              {blurred && <FlexBox className="InlayMap-blur" sx={{ backdropFilter: 'blur(4px)', height: '100%', position: 'absolute', width: '100%', zIndex: 501 }} />}
              {center && !blurred && <ZoomButtons sx={{ bottom: 32, right: 16, position: 'absolute', zIndex: 500 }} />}
              {allowSearch && <InlayMapSearch onAddressClick={onAddressClick} />}
              {entityId && center && <ViewMapButton entityId={entityId} />}
              {allowFullScreen && !smallScreen &&
                <Paper elevation={3} sx={{ top: 154, right: 16, position: 'absolute', zIndex: 502 }}>
                  <MapButton data-testid='map-open-full-screen-button' onClick={onOpenInFullClick}>
                    <OpenInFull />
                  </MapButton>
                </Paper>
              }
              {center && children}
            </SearchProvider>
          </MapContainer>
        </FlexBox>
        <FlexBox
          sx={{
            alignItems: 'center',
            bottom: 0,
            justifyContent: 'center',
            left: 0,
            position: 'absolute',
            right: 0,
            top: 0,
          }}
        >
          <Typography
            data-testid='inlay-map-no-location-label'
            color="text.secondary"
            fontStyle="italic"
            sx={{
              textShadow: `0 0 ${theme.spacing(1)} ${theme.palette.background.paper}, 0 0 ${theme.spacing(2)} ${theme.palette.background.paper}, 0 0 ${theme.spacing(3)} ${theme.palette.background.paper}`,
            }}
            variant="body2"
          >
            {overlayLabel}
            {(center && !blurred && !overlayLabel) && t('common:component.map.hint.no-location')}
          </Typography>
        </FlexBox>
      </Box>
    </MapProvider>
  );

  return renderMapLayersProvider ? (
    <MapLayersProvider shouldUseLocalStorage={shouldUseLocalStorage}>{body}</MapLayersProvider>
  ) : body;
};
