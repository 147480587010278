import { DateTime } from 'luxon';
import { FC } from 'react';
import { RelativeTimestamp } from '../relative-timestamp';

type FormatTypes = 'short' | 'shortWithSeconds' | 'long' | 'relative' | 'timeWithSeconds' | 'dateFull' | (string & { format?: unknown })

export interface FormatTimestampProps {
  format?: FormatTypes;
  value: string | Date;
  zone?: string;
}

export const FormatTimestamp: FC<FormatTimestampProps> = ({ format, value, zone = 'local' }) => {
  if (!value) return null;

  const locale = navigator.language;
  const timestamp = typeof value === 'string'
    ? DateTime.fromISO(value, { zone, locale })
    : DateTime.fromJSDate(value, { zone }).setLocale(navigator.language);

  let formattedTime;
  switch (format) {
    case 'short':
      formattedTime = timestamp.toLocaleString(DateTime.DATETIME_SHORT);
      break;
    case 'dateFull':
      formattedTime = timestamp.toLocaleString(DateTime.DATE_FULL);
      break;
    case 'shortWithSeconds':
      formattedTime = timestamp.toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS);
      break;
    case 'long':
      formattedTime = timestamp.toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY);
      break;
    case 'relative':
      formattedTime = <RelativeTimestamp date={timestamp} />;
      break;
    case 'timeWithSeconds':
      formattedTime = timestamp.toLocaleString(DateTime.TIME_WITH_SECONDS);
      break;
    default:
      formattedTime = timestamp.toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS);
      break;
  }
  return <>{formattedTime}</>;
};

export default FormatTimestamp;
