import { Subscription } from '@eagle/core-data-types';
import { TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { T_ONE } from '../../constants';
import { testid } from '../../util';
import { SubscriptionsTableRow } from './subscriptions-table-row';
import { SubscriptionUserRecipientDataType } from './subscriptions.types';

const NUMBER_OF_COLUMNS = 4;
export const COLUMN_WIDTH = `${100 / NUMBER_OF_COLUMNS}%`;

interface SubscriptionListDesktopViewProps {
  disableLink?: boolean;
  isLoading: boolean;
  subscriptions: Subscription[];
  subscriptionDetailPath: string;
  userRecipientDataType: SubscriptionUserRecipientDataType;
}

export const SubscriptionListDesktopView: FC<SubscriptionListDesktopViewProps> = ({ subscriptions, isLoading, disableLink, subscriptionDetailPath, userRecipientDataType }) => {
  const { t } = useTranslation();

  return (
    <>
      <TableHead component="div">
        <TableRow component="div">
          <TableCell component="div" sx={{ width: COLUMN_WIDTH }}>{t('common:terms.recipient', { count: T_ONE })}</TableCell>
          <TableCell component="div" sx={{ width: COLUMN_WIDTH }}>{t('common:terms.from')}</TableCell>
          <TableCell component="div" sx={{ width: COLUMN_WIDTH }}>{t('common:terms.feature')}</TableCell>
          <TableCell component="div" sx={{ width: COLUMN_WIDTH }}>{t('common:terms.alert', { count: T_ONE })}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody component="div" sx={{ filter: isLoading ? 'blur(1px)' : '', opacity: isLoading ? 0.66 : 1 }}>
        {subscriptions.map((item, i) => <SubscriptionsTableRow key={i} userRecipientDataType={userRecipientDataType} subscription={item} subscriptionDetailPath={subscriptionDetailPath} disableLink={disableLink} data-testid={testid`subscription-list-item-${i}`} />)}
      </TableBody>
    </>
  );
};
