/* eslint-disable react-hooks/exhaustive-deps */
import { Stack, Typography, useTheme } from '@mui/material';
import chroma from 'chroma-js';
import { useSnackbar } from 'notistack';
import { FC, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { AuthStatus, useAuth } from '../../auth';
import { LoadingButton } from '../../components/loading-button';
import { EthingsLogo } from '../../components/logo';
import { useConfig, useSmallScreen } from '../../hooks';
import { LandingView } from './view';

interface LandingControllerProps {
  portalName: string;
}

export const LandingPageController: FC<LandingControllerProps> = ({ portalName }) => {
  const { signIn, state } = useAuth();
  const { termsOfService, productName } = useConfig();
  const { enqueueSnackbar } = useSnackbar();
  const smallScreen = useSmallScreen();
  const { i18n, t } = useTranslation(['common']);
  const theme = useTheme();

  useEffect(() => {
    if (state?.status === AuthStatus.CONFIG_ERROR) {
      enqueueSnackbar(state.error.message, { variant: 'error' });
      return;
    }
    if (state?.status === AuthStatus.AUTHENTICATION_FAILED) {
      enqueueSnackbar(i18n.exists(`auth-errors:${state.failure.error}`)
        ? t(`auth-errors:${state.failure.error}`)
        : state.failure.errorDescription, { variant: 'error' });
    }
  }, [state]);

  const renderLogo = (logoUrl: string): JSX.Element => (
    logoUrl.length ? <img src={logoUrl} width="100%" /> : <EthingsLogo logo={theme.landingPage.logo} width='250px' />
  );
  const renderHeader = (headerText: string): JSX.Element => (
    <Typography id="header" variant="h3" sx={{ whiteSpace: 'pre-line' }}>{headerText}</Typography>
  );
  const renderSubHeader = (subHeaderText: string): JSX.Element => (
    <Typography id='sub-header' variant="h5">{subHeaderText}</Typography>
  );
  const renderSignInButton = (): JSX.Element => (
    <LoadingButton data-testid='sign-in-button' fullWidth={smallScreen} size='large' task={() => signIn()} variant='contained' sx={{ color: theme.landingPage.signInButton.contrastText, backgroundColor: theme.landingPage.signInButton.background, '&:hover': { backgroundColor: chroma(theme.landingPage.signInButton.background).darken(0.5).hex() } }}>
      {t('common:component.auth.labels.sign-in')}
    </LoadingButton>
  );

  const renderFooterLogo = (logoUrl: string): JSX.Element => (
    <Stack id="footer-logo" direction="row">
      {logoUrl.length ?
        <img src={logoUrl} width="100px" />
        : <>
          <EthingsLogo logo={theme.landingPage.footer.logo} width='120px' />
          <Typography color={theme.landingPage.footer.logo.includes('light') ? '#ffffff' : '#231F20'} variant="caption" sx={{ fontSize: '0.3rem', mt: 1 }}>TM</Typography>
        </>}
    </Stack>
  );
  const renderTopFooterText = (topFooterText: string): JSX.Element => (
    <Typography id="footer-top-text" color={theme.landingPage.footer.contrastText} variant="caption">{topFooterText}</Typography>
  );

  const renderBottomFooterText = (bottomFooterText: string): JSX.Element => {
    return <Typography id="footer-bottom-text" color={theme.landingPage.footer.contrastText} variant="caption">{bottomFooterText}</Typography>;
  };

  const renderTermsAndConditionsText = (): JSX.Element => {
    if (!termsOfService?.documentId || !productName) return <></>;
    return (
      <Stack alignItems="center">
        <Trans
          i18nKey="common:component.landing.labels.domain-terms-and-conditions"
          components={[
            <br key="break" />,
            <Link
              key="link"
              rel="noopener"
              target="blank"
              to={`/web-asset/${termsOfService.documentId}`}
            />,
          ]}
          values={{ account: productName }}
        />
      </Stack>
    );
  };

  return (
    <LandingView
      portalName={portalName}
      renderBottomFooterText={renderBottomFooterText}
      renderFooterLogo={renderFooterLogo}
      renderHeader={renderHeader}
      renderLogo={renderLogo}
      renderSignInButton={renderSignInButton}
      renderSubHeader={renderSubHeader}
      renderTermsAndConditionsText={renderTermsAndConditionsText}
      renderTopFooterText={renderTopFooterText}
      smallScreen={smallScreen}
    />
  );
};
