import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, Stack, styled, SwipeableDrawer, Theme, useTheme } from '@mui/material';
import { FC, PropsWithChildren } from 'react';
import { useParams } from 'react-router-dom';
import { testid } from '../../../util';
import { FlexBox } from '../../flex-box';

interface LayoutMobileProps extends PropsWithChildren {
  handleCloseButtonClick: () => void;
  isPopupOpen: boolean;
}

const Puller = styled(Box)(({ theme }: { theme: Theme }) => ({
  backgroundColor: theme.drawer.puller,
  borderRadius: 3,
  display: 'block',
  height: 6,
  left: 'calc(50% - 15px)',
  position: 'absolute',
  top: 8,
  width: 30,
}));

export const LayoutMobile: FC<LayoutMobileProps> = ({ children, handleCloseButtonClick, isPopupOpen }) => {
  const { thingId } = useParams();
  const theme = useTheme();

  if (!isPopupOpen) return <></>;

  return (
    <SwipeableDrawer
      anchor="bottom"
      disableEnforceFocus={true}
      onClose={handleCloseButtonClick}
      onOpen={() => { }}
      open={isPopupOpen}
      sx={{
        position: 'relative',
        zIndex: 'unset',
        '& .MuiDrawer-paper': {
          background: theme.mapPopup.background,
          color: theme.mapPopup.contrastText,
          borderRadius: '8px 8px 0 0',
          boxShadow: 4,
          height: 'min-content !important',
        },
      }}
    >
      <FlexBox
        flexDirection="column"
        sx={{
          overflowX: 'hidden',
          overflowY: 'auto',
        }}
      >
        <Puller theme={theme} />
        <IconButton onClick={handleCloseButtonClick} sx={{ alignSelf: 'flex-end', position: 'absolute', right: 3, top: 2, color: theme.mapPopup.contrastText }}>
          <CloseIcon />
        </IconButton>
        <Stack data-testid={testid`thing-map-popup-${thingId}`} sx={{ m: 2, p: 0, width: 'auto' }}>
          {children}
        </Stack>
      </FlexBox>
    </SwipeableDrawer>
  );
};
