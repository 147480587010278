/* eslint-disable react-hooks/exhaustive-deps */
import { Account, SharedThing } from '@eagle/core-data-types';
import { Skeleton } from '@mui/material';
import { Box } from '@mui/system';
import { FC } from 'react';
import { useAuthenticated } from '../../auth';
import { usePromise } from '../../hooks';
import { ListPaperColumn } from '../list-item';

interface Props {
  sharedThing: SharedThing;
  stakeholder: string;
  label?: string;
}

export const StakeholderColumn: FC<Props> = ({ sharedThing, stakeholder, label }) => {
  const { axios } = useAuthenticated();
  const stakeholderId = sharedThing.lifecycleState?.stakeholders?.[stakeholder];
  const columnLabel = label || stakeholder;

  const [account, accountError, accountState] = usePromise(
    async () => {
      if (!stakeholderId) return;
      const result = await axios.get<Account>(`/api/v1/shared-thing/${sharedThing._id}/life-cycle/stakeholder/${stakeholder}/account/${stakeholderId}`);
      return result.data;
    },
    [axios, stakeholderId],
  );

  if (!stakeholderId || accountError) {
    return <Box />;
  }

  return <ListPaperColumn data-testid={`transfers-list-column-${stakeholder}`} label={columnLabel} sx={{ overflow: 'hidden', '& > label': { textTransform: 'capitalize' } }}>
    {accountState === 'pending'
      ? <Skeleton variant="text" sx={{ width: '25%', height: 24 }} />
      : account?.display
    }
  </ListPaperColumn>;
};
