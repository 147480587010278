import { JsonObject } from '@eagle/common';
import { PersonType, ThingType } from '@eagle/core-data-types';
import { Box } from '@mui/material';
import { isNumber } from 'lodash';
import { FC } from 'react';
import { CacheDataTypes } from '../../types';
import { FetchOneOfAll } from '../fetch';
import { FormatProperty } from '../format';
import { ListPaperColumn } from '../list-item';

interface Props {
  data: {
    _id: string;
    display: string;
    thingTypeId?: string;
    sharedThingTypeId?: string;
    personTypeId?: string;
    properties: JsonObject;
  };
  'data-testid'?: string;
  property: string;
  index?: number;
}

export const PropertyColumn: FC<Props> = ({ data, property, index, ...props }) => {
  if (!property && !isNumber(index)) {
    return <Box />;
  }

  const dataType = data.sharedThingTypeId ? CacheDataTypes.SHARED_THING_TYPE :
    (data.thingTypeId ? CacheDataTypes.THING_TYPE :
      (data.personTypeId ? CacheDataTypes.PERSON_TYPE : null));

  const id = data.sharedThingTypeId || data.thingTypeId || data.personTypeId || null;

  const renderListPaperColumns = (entityType: ThingType | PersonType): JSX.Element => {
    let propertyDefinition;
    let value;

    if (isNumber(index) && entityType.properties.order.length > index) {
      const propName = entityType.properties.order[index];
      propertyDefinition = entityType.properties.definitions[propName];
      value = data.properties[propName];
    }

    if (!propertyDefinition) {
      return <Box />;
    }

    return (
      <ListPaperColumn label={propertyDefinition.label} sx={{ overflow: 'hidden' }} >
        <FormatProperty
          value={value || null}
          definition={propertyDefinition}
        />
      </ListPaperColumn>
    );
  };

  if (!dataType) {
    return <ListPaperColumn data-testid={props['data-testid']} label>{data.display}</ListPaperColumn>;
  }

  return <FetchOneOfAll
    id={id}
    dataType={dataType}
    notFoundFactory={() => <span />}
    renderFactory={renderListPaperColumns}
  />;
};
