import { useTheme } from '@mui/material';
import chroma from 'chroma-js';
import L from 'leaflet';
import { FC } from 'react';
import { MapContainer, useMapEvents } from 'react-leaflet';
import { MAP_MAX_ZOOM, MAP_MIN_ZOOM } from '../../constants';
import { useSavedPosition } from '../../hooks';
import { MapStorageKeys, ValidateResize, wrapLongitude } from '../../util';
import { FlexBox } from '../flex-box';
import { MapScale } from './map-controls/map-scale';
import { ZoomButtons } from './map-controls/zoom-buttons';
import { MapLayerController } from './map-layer-controller';
import { PageMapProps, ZOOM } from './page-map.types';

const BOUNDS_OF_EARTH = L.latLngBounds(L.latLng(-85, -Infinity), L.latLng(85, Infinity));

export const SavePosition: FC<{ initialCenter: L.LatLng; storageKey: MapStorageKeys }> = ({ initialCenter, storageKey }) => {
  const { setSavedPosition } = useSavedPosition(storageKey);

  const map = useMapEvents({
    dragend: () => updatePositionAndZoom(),
    zoomend: () => updatePositionAndZoom(),
  });

  const updatePositionAndZoom = (): void => {
    const { lat, lng } = map.getCenter() ?? initialCenter;
    const savedPosition = new L.LatLng(lat, wrapLongitude(lng), map.getZoom());
    setSavedPosition({ ...savedPosition, timeStamp: Date.now() });
    if (lng > 180 || lng < -180) {
      map.setView(new L.LatLng(lat, wrapLongitude(lng)), map.getZoom(), { animate: false });
    }
  };
  return null;
};

export const PageMap: FC<PageMapProps> = ({
  center,
  children,
  storageKey,
  zoom = ZOOM.earth,
  bounds,
  dim,
  ...props
}) => {
  const theme = useTheme();

  return (
    <FlexBox
      id="map-page"
      sx={{
        '& .leaflet-overlay-pane': { outline: 'none !important' },
        '& .leaflet-container': { fontFamily: 'inherit', width: '100%', height: '100%' },
        '& .leaflet-container a': { color: theme.palette.info.main, textDecoration: 'none' },
        '& .leaflet-div-icon': { background: 'none', border: 'none' },
        '& .leaflet-map-pane canvas': { filter: dim ? 'brightness(0.5)' : 'none', transition: 'filter .3s' },
        '& .leaflet-layer canvas': { backgroundColor: dim ? 'rgba(0, 0, 0, 0.5) !important' : 'none', transition: 'filter .3s' },
        '& .leaflet-marker-icon': { outline: 'none' },
        '& .leaflet-popup-content-wrapper': { borderRadius: theme.shape.borderRadius, padding: 0 },
        '& .leaflet-popup-content': { margin: 0 },
        '& .leaflet-tooltip-bottom': { marginTop: '20px' },
        '& .leaflet-tooltip': { backgroundColor: chroma(theme.palette.background.default).alpha(0.8).css() },
        position: 'relative',
      }}
      {...props}
    >
      <MapContainer
        center={center}
        maxBounds={BOUNDS_OF_EARTH}
        maxBoundsViscosity={1}
        maxZoom={MAP_MAX_ZOOM}
        minZoom={MAP_MIN_ZOOM}
        preferCanvas={true}
        renderer={new L.Canvas()}
        touchZoom={true}
        worldCopyJump={false}
        zoom={zoom}
        zoomAnimation={true}
        zoomAnimationThreshold={2}
        zoomControl={false}
        zoomDelta={1}
        zoomSnap={0.5}
      >
        <MapScale />
        <ValidateResize />
        <MapLayerController />
        {storageKey && <SavePosition initialCenter={center} storageKey={storageKey} />}
        <ZoomButtons sx={{ bottom: 0, m: 3, position: 'absolute', right: 0, zIndex: 500 }} />
        {children}
      </MapContainer>
    </FlexBox>
  );
};
