import { Button } from '@mui/material';
import { SxProps } from '@mui/system';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SetState } from '../../types';

interface Props {
  disabled?: boolean;
  setActiveStep: SetState<number>;
  sx?: SxProps;
}

export const BackButton: FC<Props> = ({ setActiveStep, ...props }) => {
  const { t } = useTranslation(['common']);
  return (
    <Button
      data-testid="back-button"
      onClick={() => setActiveStep((prev) => prev - 1)}
      variant="outlined"
      {...props}
    >
      {t('common:common.action.back')}
    </Button>
  );
};

export const NextButton: FC<Props> = ({ setActiveStep, ...props }) => {
  const { t } = useTranslation(['common']);
  return (
    <Button
      data-testid="next-button"
      onClick={() => setActiveStep((prev) => prev + 1)}
      variant="contained"
      {...props}
    >
      {t('common:common.action.next')}
    </Button>
  );
};
