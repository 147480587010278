export const THING_INLAY_MAP = {
  op: 'if-feature-data',
  feature: 'tracking',
  then: {
    op: 'ui',
    name: 'InlayMap',
    'data-testid': 'inlay-map-container',
    center: {
      op: 'latitude-longitude',
      latitude: {
        op: 'feature-event',
        feature: 'tracking',
        eventTypeId: 'location-update',
        path: 'location.latitude',
      },
      longitude: {
        op: 'feature-event',
        feature: 'tracking',
        eventTypeId: 'location-update',
        path: 'location.longitude',
      },
    },
    zoom: 15,
    allowSearch: false,
    drawerLayerSelection: true,
    customMarker: {
      op: 'ui',
      name: 'CustomMarker',
      pinPosition: {
        op: 'latitude-longitude',
        latitude: {
          op: 'feature-event',
          feature: 'tracking',
          eventTypeId: 'location-update',
          path: 'location.latitude',
        },
        longitude: {
          op: 'feature-event',
          feature: 'tracking',
          eventTypeId: 'location-update',
          path: 'location.longitude',
        },
      },
      color: {
        op: 'if',
        condition: {
          op: 'or',
          values: [{
            op: 'feature-is-started',
            feature: 'tracking',
            startEventTypeId: 'no-location-update',
            finishEventTypeId: 'location-update',
          }, {
            op: 'feature-is-started',
            feature: 'tracking',
            startEventTypeId: 'inaccurate-location-update',
            finishEventTypeId: 'location-update',
          }, {
            op: 'before-past',
            timestamp: {
              op: 'feature-latest-occurred',
              feature: 'tracking',
              havingPath: 'data.location',
            },
            duration: 'P7D',
          }],
        },
        then: {
          op: 'theme-property',
          path: 'states.uncertain',
        },
      },
      indicator: {
        op: 'if',
        condition: {
          op: 'or',
          values: [{
            op: 'feature-is-started',
            feature: 'tracking',
            startEventTypeId: 'no-location-update',
            finishEventTypeId: 'location-update',
          }, {
            op: 'feature-is-started',
            feature: 'tracking',
            startEventTypeId: 'inaccurate-location-update',
            finishEventTypeId: 'location-update',
          }, {
            op: 'before-past',
            timestamp: {
              op: 'feature-latest-occurred',
              feature: 'tracking',
              havingPath: 'data.location',
            },
            duration: 'P7D',
          }],
        },
        then: null,
        else: {
          op: 'if',
          condition: { op: 'macro:is-power-on' },
          then: {
            op: 'if',
            condition: { op: 'macro:is-moving' },
            then: {
              op: 'theme-property',
              path: 'states.on',
            },
            else: {
              op: 'theme-property',
              path: 'states.paused',
            },
          },
          else: {
            op: 'if',
            condition: { op: 'macro:is-moving' },
            then: {
              op: 'theme-property',
              path: 'states.on',
            },
            else: {
              op: 'theme-property',
              path: 'states.off',
            },
          },
        },
      },
      children: {
        op: 'ui',
        name: 'Tooltip',
        direction: 'right',
        offset: [15, -10],
        opacity: 1,
        permanent: true,
        children: {
          op: 'ui',
          name: 'Stack',
          children: [
            {
              op: 'ui',
              name: 'EventAddress',
              loadingComponent: {
                op: 'ui',
                name: 'Skeleton',
                variant: 'text',
                width: 120,
              },
              location: {
                op: 'feature-event',
                feature: 'tracking',
                eventTypeId: 'location-update',
                path: 'location',
              },
            },
            {
              op: 'ui',
              name: 'Typography',
              variant: 'caption',
              children: [
                {
                  op: 'translate',
                  translation: 'page.thing-detail.last-location-update.label',
                  options: {
                    ns: 'track',
                  },
                },
                ': ',
                {
                  op: 'ui',
                  name: 'FormatTimestamp',
                  format: 'relative',
                  value: {
                    op: 'feature-latest',
                    feature: 'tracking',
                    havingPath: 'data.location',
                    path: 'occurred',
                    excludeEventTypes: ['inaccurate-location-update'],
                  },
                },
              ],
            },

          ],
        },
      },
    },
    overlay: {
      op: 'ui',
      name: 'MapOverlay',
      tooltip: [
        {
          op: 'translate',
          translation: 'common.labels.last-updated',
          options: {
            ns: 'common',
          },
        },
        ': ',
        {
          op: 'ui',
          name: 'FormatTimestamp',
          format: 'relative',
          value: {
            op: 'last-event-time',
          },
        }],
      children: [{
        op: 'if',
        condition: {
          op: 'macro:is-power-on',
        },
        then: {
          op: 'ui',
          name: 'BasicColumn',
          'data-testid': 'map-event-power-on',
          label: {
            op: 'translate',
            translation: 'page.diagnostic.tracking.labels.power-state',
            options: {
              ns: 'manage',
            },
          },
          avatar: {
            op: 'ui',
            name: 'Avatar',
            icon: 'PowerIcon',
            background: 'transparent',
            iconColor: {
              op: 'theme-property',
              path: 'states.on',
            },
            iconSize: '1em',
          },
          children: {
            op: 'translate',
            translation: 'page.diagnostic.tracking.labels.power-state-on',
            options: {
              ns: 'manage',
            },
          },
        },
        else: {
          op: 'ui',
          name: 'BasicColumn',
          'data-testid': 'map-event-power-off',
          label: 'Power',
          avatar: {
            op: 'ui',
            name: 'Avatar',
            icon: 'PowerIcon',
            background: 'transparent',
            iconColor: {
              op: 'theme-property',
              path: 'states.off',
            },
            iconSize: '1em',
          },
          children: {
            op: 'translate',
            translation: 'page.diagnostic.tracking.labels.power-state-off',
            options: {
              ns: 'manage',
            },
          },
        },
      },
      {
        op: 'ui',
        name: 'BasicColumn',
        label: {
          op: 'translate',
          translation: 'page.thing-map.state.label',
          options: {
            ns: 'common',
          },
        },
        avatar: {
          op: 'ui',
          name: 'Avatar',
          icon: {
            op: 'if',
            condition: { op: 'macro:is-power-on' },
            then: {
              op: 'if',
              condition: { op: 'macro:is-moving' },
              then: 'PlayIcon',
              else: 'PauseIcon',
            },
            else: {
              op: 'if',
              condition: { op: 'macro:is-moving' },
              then: 'PlayIcon',
              else: 'StopIcon',
            },
          },
          background: 'transparent',
          iconColor: {
            op: 'if',
            condition: { op: 'macro:is-power-on' },
            then: {
              op: 'if',
              condition: { op: 'macro:is-moving' },
              then: {
                op: 'theme-property',
                path: 'states.on',
              },
              else: {
                op: 'theme-property',
                path: 'states.paused',
              },
            },
            else: {
              op: 'if',
              condition: { op: 'macro:is-moving' },
              then: {
                op: 'theme-property',
                path: 'states.on',
              },
              else: {
                op: 'theme-property',
                path: 'states.off',
              },
            },
          },
          iconSize: '1em',
        },
        children: {
          op: 'if',
          condition: { op: 'macro:is-power-on' },
          then: {
            op: 'if',
            condition: { op: 'macro:is-moving' },
            then: {
              op: 'translate',
              translation: 'component.events.labels.moving',
              options: {
                ns: 'common',
              },
            },
            else: {
              op: 'translate',
              translation: 'page.thing-map.state.stationary',
              options: {
                ns: 'track',
              },
            },
          },
          else: {
            op: 'if',
            condition: { op: 'macro:is-moving' },
            then: {
              op: 'translate',
              translation: 'page.thing-map.state.unknown',
              options: {
                ns: 'track',
              },
            },
            else: {
              op: 'translate',
              translation: 'page.thing-map.state.stopped',
              options: {
                ns: 'track',
              },
            },
          },
        },
      },
      {
        op: 'if',
        condition: {
          op: 'ne',
          a: {
            op: 'feature-latest',
            feature: 'tracking',
            havingPath: 'data.velocity',
            path: 'data.velocity.speed',
          },
          b: null,
        },
        then: {
          op: 'ui',
          name: 'BasicColumn',
          'data-testid-inner': 'speed',
          label: {
            op: 'translate',
            translation: 'component.events.labels.speed',
            options: {
              ns: 'common',
            },
          },
          avatar: {
            op: 'ui',
            name: 'Avatar',
            icon: 'SpeedIcon',
            background: 'transparent',
            iconColor: {
              op: 'if',
              condition: { op: 'macro:is-moving' },
              then: {
                op: 'theme-property',
                path: 'states.on',
              },
              else: {
                op: 'theme-property',
                path: 'states.unknown',
              },
            },
            iconSize: '1em',
          },
          children: {
            op: 'format-speed',
            value: {
              op: 'feature-latest',
              feature: 'tracking',
              havingPath: 'data.velocity',
              path: 'data.velocity.speed',
            },
          },
        },
        else: '',
      },
      ],
    },
    entityId: {
      op: 'context',
      path: 'entityId',
    },
  },
  else: {
    op: 'ui',
    name: 'InlayMap',
    zoom: 15,
    drawerLayerSelection: true,
    allowSearch: false,
    overlayLabel: {
      op: 'translate',
      translation: 'component.map.hint.no-location',
      options: {
        ns: 'common',
      },
    },
  },
};
