import { RoleFunction } from '@eagle/common';
import { Account, AccountRequest, ChangeAction, CreateAccountFromTemplateRequest, CreateAccountRequestRequest, InitialUser, LifecycleTemplate, SetSharedThingLifeCycleStageRequest, SharedThing, Stage, StakeholderAccountResult, Thing } from '@eagle/core-data-types';
import { Button } from '@mui/material';
import { useTheme } from '@mui/system';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import { FC, ReactNode, SetStateAction, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { Issue } from 'validata';
import validator from 'validator';
import { useAuthenticated } from '../../../auth';
import { useFetchOneCache, useSwitchAwareConfig } from '../../../hooks';
import { CacheDataTypes, Nullable, Undefinable } from '../../../types';
import { useHasAuthorization } from '../../../util';
import { DialogActions, DialogCloseButton, DialogContent, DialogInner, DialogRoot, DialogTitle } from '../../dialog';
import { useBoolFlag } from '../../flags';
import { convertPropertiesToObjectFormat } from '../../format';
import { LoadingButton } from '../../loading-button';
import { BackButton, NextButton } from '../../stepper';
import { StageConfirmDialogConfirmStep } from './stage-confirm-dialog-confirm-step';
import { stageConfirmDialogContext } from './stage-confirm-dialog-context';
import { StageConfirmDialogEditStep } from './stage-confirm-dialog-edit-step';
import { initStageConfirmDialogReducer, stageConfirmDialogReducer, StageConfirmDialogStep, StakeholderState } from './stage-confirm-dialog-reducer';

const ACCOUNT_CREATOR = [RoleFunction.ACCOUNT_CREATOR] as const;

interface StageConfirmDialogProps {
  'data-testid'?: string;
  lifecycleTemplate: LifecycleTemplate;
  onClose: VoidFunction;
  onSuccess?: (hasDeleteAction: boolean) => void;
  open: boolean;
  sharedThing: SharedThing;
  stageId: string;
  thing?: Thing;
}

export const StageConfirmDialog: FC<StageConfirmDialogProps> = ({ stageId, lifecycleTemplate, open, 'data-testid': dataTestId, onClose, ...rest }) => {
  const stage = lifecycleTemplate.stages.find((stage) => stage.stageId === stageId);
  if (!stage) {
    return null;
  }

  return (
    <DialogRoot
      data-testid={dataTestId}
      fullWidth
      maxWidth="xs"
      onClose={onClose}
      open={open}
      scroll="paper"
    >
      <DialogCloseButton onClick={onClose} />
      <StageConfirmDialogInner lifecycleTemplate={lifecycleTemplate} stage={stage} onClose={onClose} {...rest} />
    </DialogRoot>
  );
};

interface StageConfirmDialogInnerProps extends Omit<StageConfirmDialogProps, 'open' | 'stageId'> {
  stage: Stage;
}

const StageConfirmDialogInner: FC<StageConfirmDialogInnerProps> = ({ sharedThing, stage, lifecycleTemplate, thing, onClose, onSuccess }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(['common', 'admin']);
  const theme = useTheme();
  const { axios } = useAuthenticated();
  const { hasAuthorization } = useHasAuthorization();
  const accountCreator = hasAuthorization(ACCOUNT_CREATOR);
  const hasUserFeature = useBoolFlag('manage-account-create-invite-user-feature');
  const config = useSwitchAwareConfig();
  const hasEThingsAuthProvider = Boolean(config.ethingsAuthenticationProvider);
  const thingCache = useFetchOneCache(CacheDataTypes.THING);
  const sharedThingCache = useFetchOneCache(CacheDataTypes.SHARED_THING);

  const [state, dispatch] = useReducer(stageConfirmDialogReducer, { sharedThing, stage, lifecycleTemplate }, initStageConfirmDialogReducer);

  const setActiveStep = (step: SetStateAction<number>): void => {
    dispatch({ type: 'SET_STEP', step: typeof step === 'function' ? step(state.currentStep) : step });
  };

  const getStakeholdersForStageTransition = async (): Promise<Nullable<Record<string, Nullable<string>>>> => {
    const stakeholders: Record<string, Nullable<string>> = {};
    for (const stakeholder of state.stakeholders) {
      if (stakeholder.isCreatingNewAccount && stakeholder.createAccount) {
        if (stakeholder.createAccount.createdAccountId) {
          stakeholders[stakeholder.role] = stakeholder.createAccount.createdAccountId;
          continue;
        }

        try {
          const users = getInitialAccountUsers(stakeholder.createAccount.data.users, Boolean(hasUserFeature), hasEThingsAuthProvider);
          let newAccount;
          if (accountCreator) {
            const data: CreateAccountFromTemplateRequest = {
              ...stakeholder.createAccount.data,
              properties: convertPropertiesToObjectFormat(stakeholder.createAccount.data.properties),
              users,
            };
            // Creating the accounts in parallel would add unnecessary complexity, most of the time we are not creating more than one account.
            // eslint-disable-next-line no-await-in-loop
            newAccount = await axios.post<Account>(`/api/v1/account-template/${stakeholder.createAccount.accountTemplateId}/create-account`, data);
          }
          else {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { users: _, ...targetAccountInfo } = stakeholder.createAccount.data;
            const data: CreateAccountRequestRequest = {
              accountTemplateId: stakeholder.createAccount.accountTemplateId,
              labels: {},
              tags: [],
              targetAccountInfo: {
                ...targetAccountInfo,
                initialUsers: users,
                properties: convertPropertiesToObjectFormat(targetAccountInfo.properties),
              },
            };
            // eslint-disable-next-line no-await-in-loop
            newAccount = await axios.post<AccountRequest>('/api/v1/account-request', data);
          }
          stakeholders[stakeholder.role] = newAccount.data._id;
          dispatch({ type: 'SET_CREATED_ACCOUNT_ID', role: stakeholder.role, createdAccountId: newAccount.data._id });
        } catch (e) {
          enqueueSnackbar(t('common:common.hint.create-failure', { display: stakeholder.createAccount.data.display }), { variant: 'error' });
          return null;
        }
      }

      else {
        if (stakeholder.accountId && accountCreator) {
          // eslint-disable-next-line no-await-in-loop
          const stakeholderAccount = await axios.get<StakeholderAccountResult>(`/api/v1/shared-thing/${sharedThing._id}/life-cycle/stakeholder/${stakeholder.role}/account/${stakeholder.accountId}`);
          if (stakeholderAccount.data.isAccountRequest) {
            try {
              // eslint-disable-next-line no-await-in-loop
              const accountRequest = await axios.get<AccountRequest>(`/api/v1/account-request/${stakeholder.accountId}`);
              if (config.productName === accountRequest.data.productName) {
                // eslint-disable-next-line no-await-in-loop
                await axios.post(`/api/v1/account-request/${stakeholder.accountId}/create-account`);
              }
            } catch (e) {
              enqueueSnackbar(t('common:common.hint.create-failure', { display: stakeholderAccount.data.display }), { variant: 'error' });
              return null;
            }
          }
        }
        if (stakeholder.isDirty) {
          if (stakeholder.initialAccountId) {
            stakeholders[stakeholder.role] = stakeholder.accountId ?? null;
          }
          else if (stakeholder.accountId) {
            stakeholders[stakeholder.role] = stakeholder.accountId;
          }
        }
      }
    }
    return stakeholders;
  };

  const handleConfirm = async (): Promise<void> => {
    try {
      dispatch({ type: 'START_SUBMIT' });

      const stakeholders = await getStakeholdersForStageTransition();
      if (stakeholders === null) {
        return;
      }

      const body: SetSharedThingLifeCycleStageRequest = {
        devices: null,
        deviceChecks: null,
        stakeholders,
        stageId: stage.stageId,
      };

      await axios.post(`/api/v1/shared-thing/${sharedThing._id}/life-cycle`, body);

      enqueueSnackbar(t('admin:page.thing-detail.update-stage-dialog.hint.success', { thingDisplay: thing?.display ?? '', stageDisplay: stage.display }), { variant: 'success' });
      onClose();

      if (thing) {
        await Promise.all([
          thingCache.invalidate(thing._id),
          sharedThingCache.invalidate(thing.sharedThingId),
        ]);
      }

      const { data: roles } = await axios.get<string[]>(`/api/v1/my/life-cycle-template/${lifecycleTemplate._id}/stakeholders`);
      const hasDeleteAction = roles.every((role) => lifecycleTemplate.stakeholderAccounts[role].thingUninstallAction?.action === ChangeAction.DELETE);
      onSuccess?.(hasDeleteAction);
    } catch (err) {
      console.error(err);
      const issues = (err as AxiosError<{ issues?: Issue[] }>).response?.data.issues;
      const path = issues?.[0]?.path;
      const reason = issues?.[0]?.reason;

      enqueueSnackbar(t('admin:page.thing-detail.update-stage-dialog.hint.error', { path, reason, thingDisplay: thing?.display ?? '', stageDisplay: stage.display }), { variant: 'error' });
    } finally {
      dispatch({ type: 'SUBMIT_FINISHED' });
    }
    return Promise.resolve();
  };

  const actionsByStep: Record<StageConfirmDialogStep, ReactNode> = {
    [StageConfirmDialogStep.edit]: (
      <NextButton
        data-testid="stage-dialog-next-button"
        setActiveStep={setActiveStep}
        sx={{ alignSelf: 'flex-end' }}
        disabled={state.stakeholders.some((stakeholder) => !isStakeholderStateValid(stakeholder))}
      />
    ),
    [StageConfirmDialogStep.confirm]: (
      <>
        <BackButton disabled={state.isSubmitting} setActiveStep={setActiveStep} />
        <LoadingButton
          data-testid="stage-dialog-update-stage-button"
          disabled={!state.confirmationChecked}
          loadingCaption={t('common:common.labels.loading')}
          size="medium"
          task={handleConfirm}
          variant="contained"
        >
          {t('admin:page.thing-detail.update-stage-dialog.action.update-stage')}
        </LoadingButton>
      </>
    ),
  };

  const contentByStep: Record<StageConfirmDialogStep, ReactNode> = {
    [StageConfirmDialogStep.edit]: <StageConfirmDialogEditStep />,
    [StageConfirmDialogStep.confirm]: <StageConfirmDialogConfirmStep />,
  };

  const subtitleByStep: Record<StageConfirmDialogStep, Undefinable<string>> = {
    [StageConfirmDialogStep.edit]: undefined,
    [StageConfirmDialogStep.confirm]: t('admin:page.thing-detail.update-stage-dialog.confirm.sub-title'),
  };

  const title = t('admin:page.thing-detail.update-stage-dialog.heading', { stageDisplay: stage.display });

  return (
    <DialogInner sx={{ maxHeight: `calc(100vh - ${theme.spacing(8)})` }}>
      <DialogTitle title={title} subtitle={subtitleByStep[state.currentStep]} />
      <DialogContent>
        <stageConfirmDialogContext.Provider value={{ state, dispatch, lifecycleTemplate, sharedThing, stage, thing }}>
          {contentByStep[state.currentStep]}
        </stageConfirmDialogContext.Provider>
      </DialogContent>
      <DialogActions>
        <Button
          data-testid="stage-dialog-cancel-button"
          disabled={state.isSubmitting}
          onClick={onClose}
          size="medium"
          variant="text"
        >
          {t('common:common.action.cancel')}
        </Button>
        {actionsByStep[state.currentStep]}
      </DialogActions>
    </DialogInner>
  );
};

const getInitialAccountUsers = (users: Nullable<InitialUser[]>, hasUserFeature: boolean, hasEthingsAuthProvider: boolean): Nullable<InitialUser[]> => {
  if (!users || !hasUserFeature) return null;
  const validUsers = users.filter((value) => value.email.trim() !== '');

  if (!hasEthingsAuthProvider) {
    return validUsers.map((item) => ({ email: item.email }));
  }

  return validUsers;
};

const isStakeholderStateValid = (stakeholderState: StakeholderState): boolean => {
  if (stakeholderState.isEditing) {
    return false;
  }

  if (stakeholderState.isCreatingNewAccount) {
    if (!stakeholderState.createAccount?.data.display.trim() || (stakeholderState.createAccount.isCustomHomeDomain && !stakeholderState.createAccount?.data.homeDomain?.trim())) {
      return false;
    }
    if (stakeholderState.createAccount?.data.users) {
      for (const user of stakeholderState.createAccount.data.users) {
        if (user.email && !validator.isEmail(user.email)) {
          return false;
        }
      }
    }
  }

  else {
    if (stakeholderState.isRequired) {
      return !!stakeholderState.accountId;
    }
  }

  return true;
};
