/* eslint-disable react-hooks/exhaustive-deps */
import { Location } from '@eagle/data-function-types';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Stack, useTheme } from '@mui/material';
import L from 'leaflet';
import { FC, PropsWithChildren, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { Popup, useMap } from 'react-leaflet';
import { useParams } from 'react-router-dom';
import { makeStyles } from '../../../theme';
import { getPanByPixels, testid } from '../../../util';

interface Props extends PropsWithChildren {
  handleCloseButtonClick: () => void;
  locationData: Location;
  pane?: string;
}

export interface PopupSize {
  height: number;
  width: number;
}

export const LayoutDesktop: FC<Props> = ({
  children,
  handleCloseButtonClick,
  locationData,
  pane,
}) => {
  const theme = useTheme();

  const useStyles = makeStyles()(() => ({
    popup: {
      '&': {
        zIndex: 2500,
      },
      '& .leaflet-popup-content': {
        fontSize: 'initial',
        minHeight: 0,
        '& p': {
          margin: 0,
        },
      },
      '& .leaflet-popup-content-wrapper': {
        borderRadius: theme.mapPopup.borderRadius,
        background: theme.mapPopup.background,
        color: theme.mapPopup.contrastText,
      },
      '& .leaflet-popup-tip-container': {
        display: 'none',
      },
    },
  }));

  const { classes } = useStyles();
  const { thingId } = useParams();
  const [popupSize, setPopupSize] = useState<PopupSize>({ height: 0, width: 0 });
  const map = useMap();
  const ref = useRef<L.Popup>(null);
  const popup = ref.current?.getElement();
  const position = useMemo(() => (new L.LatLng(locationData.latitude, locationData.longitude)), [locationData]);

  const panAroundEdge = useCallback((): void => {
    if (!map.getBounds().contains(position) || !popupSize.width || !popupSize.height) return;
    const markerPoint = map.latLngToContainerPoint(position);
    const { panXby, panYby } = getPanByPixels(markerPoint, popupSize);
    map.panBy([panXby, panYby]);
  }, [map, popupSize, position]);

  useLayoutEffect(() => {
    setPopupSize({
      height: popup?.clientHeight ?? 0,
      width: popup?.clientWidth ?? 0,
    });
  }, [popup]);

  useEffect(() => {
    panAroundEdge();
  }, [popupSize]);

  return (
    <Popup
      ref={ref}
      autoClose={false}
      autoPan={false}
      className={classes.popup}
      closeButton={false}
      minWidth={320}
      offset={[0, -25]}
      pane={pane}
      position={position}
    >
      <IconButton
        onClick={handleCloseButtonClick}
        size="small"
        sx={{ position: 'absolute', right: '3px', top: '3px', color: theme.mapPopup.contrastText }}
      >
        <CloseIcon />
      </IconButton>
      <Stack data-testid={testid`thing-map-popup-${thingId}`} sx={{ p: theme.mapPopup.padding }}>
        {children}
      </Stack>
    </Popup>
  );
};
