/* eslint-disable react-hooks/exhaustive-deps */
import { TypeDefinitionTypes } from '@eagle/common';
import { Person, Thing, User } from '@eagle/core-data-types';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthenticated } from '../../auth';
import { FILTER_LOOKUP_LIMIT, T_MANY } from '../../constants';
import { FILTER_OUT, NEW_ALERT_FILTER_FLAG } from '../../util';
import {
  EntityField,
  FilterFieldTypes,
  SelectedFilterType
} from '../entity-search/types';
import { useBoolFlag } from '../flags';
import { FilterAlertTypeField } from './filter-alert-type-field';
import { FilterEventTypeField } from './filter-event-type-field';
import { FilterInputAutocompleteMultiple } from './filter-input-autocomplete-multiple';
import { FilterInputEntity } from './filter-input-entity';
import { FilterInputLookup } from './filter-input-lookup';
import { FilterInputNumber } from './filter-input-number';
import { FilterInputString } from './filter-input-string';
import { FilterTypes } from './filter.types';

interface Props {
  appliedFilters: (value: FilterTypes) => EntityField[];
  inputError: boolean;
  isApplied: (value: EntityField) => boolean;
  onChanged: (value: SelectedFilterType) => void;
  path: string;
  placeholderText?: string;
  replacePath?: string;
  selectedValue?: SelectedFilterType;
  selectMultiple?: boolean;
  type: FilterFieldTypes;
  values?: EntityField[];
}

export const FilterInput: FC<Props> = ({
  appliedFilters,
  inputError,
  isApplied,
  onChanged,
  path,
  placeholderText,
  replacePath,
  selectedValue,
  selectMultiple = false,
  type,
  values,
}) => {
  const { t } = useTranslation(['common']);
  const { axios } = useAuthenticated();
  const enableNewAlertFilter = useBoolFlag(NEW_ALERT_FILTER_FLAG);

  const findThings = useMemo<(_: string) => Promise<Thing[]>>(() => {
    return async (text: string) => {
      const { data } = await axios.get<Thing[]>('/api/v1/thing', {
        params: {
          filter: FILTER_OUT.deleted,
          limit: FILTER_LOOKUP_LIMIT,
          search: text,
          sort: 'display',
        },
      });
      return data.filter(({ _id }) => !appliedFilters(FilterTypes.THING).some(({ id }) => id === _id));
    };
  }, [axios]);

  const findPersons = useMemo<(_: string) => Promise<Person[]>>(() => {
    return async (text: string) => {
      const { data } = await axios.get<Person[]>('/api/v1/person', {
        params: {
          filter: FILTER_OUT.deleted,
          limit: FILTER_LOOKUP_LIMIT,
          search: text,
          sort: 'display',
        },
      });
      return data.filter(({ _id }) => !appliedFilters(FilterTypes.PERSON).some(({ id }) => id === _id));
    };
  }, [axios]);

  const findUsers = useMemo<(_: string) => Promise<User[]>>(() => {
    return async (text: string) => {
      const { data } = await axios.get<User[]>('/api/v1/admin/user', {
        params: {
          filter: FILTER_OUT.deleted,
          limit: FILTER_LOOKUP_LIMIT,
          search: text,
          sort: 'display',
        },
      });
      return data;
    };
  }, [axios]);

  switch (type) {
    case TypeDefinitionTypes.TEXT: {
      return (
        <FilterInputString
          inputError={inputError}
          onChanged={onChanged}
          placeholderText={placeholderText}
        />
      );
    }
    case TypeDefinitionTypes.NUMBER: {
      return (
        <FilterInputNumber
          inputError={inputError}
          onChanged={onChanged}
          placeholderText={placeholderText}
        />
      );
    }
    case 'entity': {
      switch (path) {
        case FilterTypes.ALERT_TYPE: {
          if (enableNewAlertFilter) {
            const updatedFilters = [
              ...appliedFilters(FilterTypes.ALERT_TYPE),
              ...(selectedValue as EntityField[] ?? []),
            ].reduce((accumulator, current) => {
              if (!accumulator.some(({ id }) => id === current.id)) {
                accumulator.push(current);
              }
              return accumulator;
            }, [] as EntityField[]);

            return <FilterAlertTypeField
              key={path}
              appliedFilters={updatedFilters}
              label={t('common:component.filter.labels.select-an-alert-type')}
              onChanged={onChanged}
              values={values}
            />;
          }

          if (!selectMultiple) {
            return <FilterInputAutocompleteMultiple
              key={path}
              isApplied={isApplied}
              label={t('common:component.filter.labels.select-an-alert-type')}
              onChanged={onChanged}
              values={values}
            />;
          }

          return <FilterInputEntity
            key={path}
            isApplied={isApplied}
            onChanged={onChanged}
            values={values ?? []}
          />;
        }
        case FilterTypes.GROUP: {
          return <FilterInputAutocompleteMultiple
            key={path}
            isApplied={isApplied}
            label={t('common:component.filter.labels.select-a-group')}
            onChanged={onChanged}
            values={values}
          />;
        }
        case FilterTypes.GEOFENCE_GROUP: {
          return <FilterInputAutocompleteMultiple
            key={path}
            isApplied={isApplied}
            label={t('common:component.filter.labels.select-a-geofence-group')}
            onChanged={onChanged}
            values={values}
          />;
        }
        case FilterTypes.GEOFENCE_TYPE: {
          return <FilterInputAutocompleteMultiple
            key={path}
            isApplied={isApplied}
            label={t('common:component.filter.labels.select-a-geofence-type')}
            onChanged={onChanged}
            values={values}
          />;
        }
        case FilterTypes.PERSON: {
          return <FilterInputLookup
            key={path}
            appliedFilters={appliedFilters((replacePath ?? path) as FilterTypes)}
            findEntity={findPersons}
            isApplied={isApplied}
            isSearch
            label={t('common:component.filter.labels.search-a-person')}
            noResults={t('common:common.hint.list.no-results')}
            onChanged={onChanged}
            selectedValue={selectedValue}
            selectMultiple={selectMultiple}
          />;
        }
        case FilterTypes.THING: {
          return <FilterInputLookup
            key={path}
            appliedFilters={appliedFilters((replacePath ?? path) as FilterTypes)}
            findEntity={findThings}
            isApplied={isApplied}
            isSearch
            label={t('common:component.filter.labels.search-a-thing')}
            noResults={t('common:common.hint.list.no-results')}
            onChanged={onChanged}
            selectedValue={selectedValue}
            selectMultiple={selectMultiple}
          />;
        }
        case FilterTypes.TIMEZONE: {
          return <FilterInputAutocompleteMultiple
            key={path}
            isApplied={isApplied}
            label={t('common:component.filter.labels.select-a-timezone')}
            onChanged={onChanged}
            values={values}
          />;
        }
        case FilterTypes.USER: {
          return <FilterInputLookup
            key={path}
            appliedFilters={appliedFilters((replacePath ?? path) as FilterTypes)}
            findEntity={findUsers}
            isApplied={isApplied}
            isSearch
            label={t('common:component.filter.labels.search-a-user')}
            noResults={t('common:common.hint.list.no-results')}
            onChanged={onChanged}
            selectedValue={selectedValue}
            selectMultiple={selectMultiple}
          />;
        }
        case FilterTypes.USER_GROUP:
        case FilterTypes.USERS_USER_GROUP: {
          return <FilterInputAutocompleteMultiple
            key={path}
            isApplied={isApplied}
            label={t('common:component.filter.labels.select-a-user-group')}
            onChanged={onChanged}
            values={values}
          />;
        }
        case FilterTypes.EVENT_TYPE: {
          const updatedFilters = [
            ...appliedFilters(FilterTypes.EVENT_TYPE),
            ...(selectedValue as EntityField[] ?? []),
          ].reduce((accumulator, current) => {
            if (!accumulator.some(({ id }) => id === current.id)) {
              accumulator.push(current);
            }
            return accumulator;
          }, [] as EntityField[]);

          return <FilterEventTypeField
            key={path}
            appliedFilters={updatedFilters}
            label={t('common:component.events-table.labels.event-type', { count: T_MANY })}
            onChanged={onChanged}
            values={values}
          />;
        }
        default: {
          return <FilterInputEntity
            key={path}
            isApplied={isApplied}
            onChanged={onChanged}
            values={values ?? []}
          />;
        }
      }
    }
    default: {
      // TODO: Add support for all types
      return null;
    }
  }
};
