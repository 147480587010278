import { PaginatedResponse } from '@eagle/api-types';
import { RoleFunction } from '@eagle/common';
import { ServiceHistory, ServiceMetric, ServiceType, Thing } from '@eagle/core-data-types';
import AddIcon from '@mui/icons-material/Add';
import { Alert, Button, Paper, Stack, Typography } from '@mui/material';
import Axios from 'axios';
import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthenticated } from '../../auth';
import { usePromise, useSmallScreen } from '../../hooks';
import { FindItemsDeferredResult, Query } from '../../pages/list/types';
import { Undefinable } from '../../types';
import { FILTER_OUT, useHasAuthorization } from '../../util';
import { BasicList } from '../basic-list';
import { MiddleSpinner } from '../middle-spinner';
import { ServiceHistoryDetailsDialog } from './service-history-details-dialog';
import { ServiceHistoryListView } from './service-history-list-view';
import { ServiceHistoryExtended } from './service-history.types';
import { UpdateCreateServiceHistoryDialog } from './update-create-service-history-dialog';

interface Props {
  thingId: string;
  thing: Thing;
  addNewService?: boolean;
  onAddService?: () => void;
}

const VIEW_SERVICE_ROLE = [RoleFunction.SERVICE_VIEWER] as const;
const SERVICE_ADMINISTRATOR = [RoleFunction.SERVICE_ADMINISTRATOR] as const;

export const ServiceHistoryCard: FC<Props> = ({ thingId, thing, addNewService = false }) => {
  const { axios } = useAuthenticated();
  const { t } = useTranslation(['common']);
  const { hasAuthorization } = useHasAuthorization();
  const smallScreen = useSmallScreen();
  const serviceViewPermissions = hasAuthorization(VIEW_SERVICE_ROLE);
  const serviceEditPermissions = hasAuthorization(SERVICE_ADMINISTRATOR);
  const addAndEditPermission = addNewService && serviceEditPermissions;
  const [newServiceDialogOpen, setNewServiceDialogOpen] = useState(false);
  const [viewServiceDialogOpen, setViewServiceDialogOpen] = useState(false);
  const [editServiceDialogOpen, setEditServiceDialogOpen] = useState(false);
  const [saveInProgress, setSaveInProgress] = useState(false);
  const [refreshList, setRefreshList] = useState(new Date());
  const [selectedService, setSelectedService] = useState<ServiceHistoryExtended>();

  const handleFindServices = useCallback(({ pagination }: Query): FindItemsDeferredResult<ServiceHistory> => {
    const cancelToken = Axios.CancelToken.source();
    return {
      cancel: () => cancelToken.cancel(),
      promise: axios.get<PaginatedResponse<ServiceHistory>>(`/api/v1/service-history/thing/${thingId}`, {
        cancelToken: cancelToken.token,
        params: {
          ...pagination,
          sort: '-occurred',
          filter: FILTER_OUT.deleted,
        },
      }).then((response) => {
        const headers = response.headers as Record<string, any>;
        const matchCount = response.data.count ?? Number.parseInt(headers['x-match-count'] as string, 10);
        return {
          result: {
            results: response.data.items,
            itemCount: matchCount,
          },
          resultDescription: '',
        };
      }),
    };
  }, [axios, thingId, refreshList]);

  const [serviceTypes, , serviceTypesState] = usePromise<ServiceType[]>(
    async () => {
      const { data } = await axios.get<PaginatedResponse<ServiceType>>(`/api/v1/service-type/thing/${thingId}`, {
        params: {
          filter: FILTER_OUT.deleted,
        },
      });
      return data.items;
    }, [axios, thingId]);

  const [serviceMetrics, , serviceMetricsState] = usePromise<ServiceMetric[]>(
    async () => {
      const { data } = await axios.get<PaginatedResponse<ServiceMetric>>(`/api/v1/service-metric/thing/${thingId}`, {
        params: {
          filter: FILTER_OUT.deleted,
        },
      });
      return data.items;
    }, [axios, thingId]);

  const renderServiceHistoryList = (services: Undefinable<ServiceHistory[]>, isLoading: boolean): JSX.Element => {
    if (isLoading || serviceTypesState === 'pending' || serviceMetricsState === 'pending') return <MiddleSpinner />;

    if (!services || !services?.length || !serviceTypes?.length || !serviceMetrics?.length) {
      return <Alert data-testid="no-search-results" severity="info" sx={{ my: 0 }}>
        {t('common:page.thing-detail.service-history.list.none.hint')}
      </Alert>;
    }
    return <ServiceHistoryListView services={services} serviceTypes={serviceTypes} serviceMetrics={serviceMetrics} onClick={handleViewEditService} />;
  };

  const handleViewEditService = (service?: Undefinable<ServiceHistoryExtended>): void => {
    if (!service) return setNewServiceDialogOpen(true);
    setSelectedService(service);
    if (service && addAndEditPermission) {
      setEditServiceDialogOpen(true);
    } else if (serviceViewPermissions) {
      setViewServiceDialogOpen(true);
    }
  };

  const renderCreateAction = (): JSX.Element => {
    if (!addAndEditPermission) return <></>;
    return (
      <Button
        data-testid="add-new-service-button"
        disabled={saveInProgress || !serviceMetrics?.length}
        onClick={() => handleViewEditService()}
        startIcon={<AddIcon />}
        sx={{ alignSelf: 'flex-end' }}
      >
        {smallScreen ? t('common:page.thing-detail.service-history.add.service.action') : t('common:page.thing-detail.service-history.add-new.service.action')}
      </Button>
    );
  };

  const actions = [
    <Stack key="title-add" direction="row" sx={{ justifyContent: 'space-between', px: 3 }}>
      <Typography variant="h5" color="text.primary">{t('common:page.thing-detail.service-history.service-history-title.labels')}</Typography>
      {renderCreateAction()}
    </Stack>,
  ];

  return <>
    <Paper sx={{ py: 3 }}>
      <BasicList
        actions={actions}
        limit={5}
        onQueryChanged={handleFindServices}
        renderContent={renderServiceHistoryList}
      />
    </Paper>
    {addAndEditPermission && <UpdateCreateServiceHistoryDialog
      data-testid="add-new-service-dialog"
      thing={thing}
      open={newServiceDialogOpen}
      handleClose={() => setNewServiceDialogOpen(false)}
      saveInProgress={saveInProgress}
      setSaveInProgress={setSaveInProgress}
      serviceMetrics={serviceMetrics || []}
      serviceTypes={serviceTypes || []}
      setRefreshList={setRefreshList}
    />}
    {serviceViewPermissions && selectedService && <ServiceHistoryDetailsDialog
      item={selectedService}
      open={viewServiceDialogOpen}
      handleClose={() => setViewServiceDialogOpen(false)}
    />}
    {addAndEditPermission && <UpdateCreateServiceHistoryDialog
      data-testid="update-service-dialog"
      thing={thing}
      item={selectedService}
      open={editServiceDialogOpen}
      handleClose={() => setEditServiceDialogOpen(false)}
      saveInProgress={saveInProgress}
      setSaveInProgress={setSaveInProgress}
      serviceMetrics={serviceMetrics || []}
      serviceTypes={serviceTypes || []}
      setRefreshList={setRefreshList}
    />}
  </>;
};
