/* eslint-disable react-hooks/exhaustive-deps */
// cspell: ignore Timelapse
import { TimeZone } from '@eagle/data-function-types';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, timelineItemClasses, TimelineSeparator } from '@mui/lab';
import { Button, Stack, Typography, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { isNumber } from 'lodash';
import { DateTime, Duration, DurationLikeObject } from 'luxon';
import React, { FC, Fragment, KeyboardEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Maybe, Nullable, Undefinable } from '../../types';
import { FormatTimestamp } from '../format';
import { DateTimeTooltip } from '../tooltip';

export interface TimelineData {
  color: string;
  date?: Date;
  eventId?: string;
  primary: string;
  secondary?: string;
  timeDifference?: Duration;
  timezone?: TimeZone;
}

interface Props {
  cursor?: number;
  data: TimelineData[];
  'data-testid'?: string;
  disableFocus?: boolean;
  hoveredEventId?: Maybe<string>;
  minTimeDifference?: DurationLikeObject;
  onClick?: (index: number) => void;
  onMouseEnter?: (eventId: Undefinable<string>) => void;
  onMouseLeave?: (eventId: Undefinable<string>) => void;
  onSelected?: (index: number) => void;
  preCalculatedDateDiff?: boolean;
  showCursorLocation?: boolean;
  startTime?: Date;
}

const dropZero = (input: Duration): Duration => {
  const normalized = input.normalize();
  const { hours, minutes, seconds } = normalized.shiftTo('hours', 'minutes', 'seconds');
  const handleZero = (number: number): Undefinable<number> => number < 1 ? undefined : Math.round(number);
  return Duration.fromObject({ hours: handleZero(hours), minutes: handleZero(minutes), seconds: handleZero(seconds) });
};

const elapsesMinTimeDifference = (minTimeDifference?: DurationLikeObject, timeDifference?: Duration): boolean => {
  if (!timeDifference || !minTimeDifference) return false;
  const { hours, minutes, seconds } = minTimeDifference;
  const minTimeSeconds = Duration.fromObject({ hours, minutes, seconds }).as('milliseconds');
  const timeOfEvent = timeDifference.as('milliseconds');
  return timeOfEvent >= minTimeSeconds;
};

export const TimeLine: FC<Props> = ({
  cursor: controllableCursor,
  data,
  disableFocus,
  hoveredEventId,
  minTimeDifference = { minutes: 5 },
  onClick,
  onMouseEnter,
  onMouseLeave,
  onSelected,
  preCalculatedDateDiff,
  showCursorLocation,
  startTime,
  ...props
}) => {
  const [cursor, setCursor] = useState(-1);
  const theme = useTheme();
  const timeZone = !preCalculatedDateDiff ? data.find(({ timezone }) => !!timezone)?.timezone : undefined;

  const isColored = (i: number): boolean => cursor === i && !!showCursorLocation;

  const dataWithDifference: TimelineData[] = useMemo(() => {
    return !preCalculatedDateDiff
      ? data.map(({ date, ...current }, i) => {
        const prevDate = data[i - 1]?.date;
        if (!prevDate || !date || i === 0) return { date, ...current, timezone: timeZone };
        return {
          ...current,
          timezone: current.timezone ?? timeZone,
          date,
          timeDifference: DateTime.fromJSDate(date).diff(DateTime.fromJSDate(prevDate)),
        };
      })
      : data;
  }, [timeZone, data, preCalculatedDateDiff]);

  useEffect(() => {
    if (!isNumber(controllableCursor)) return;
    setCursor(controllableCursor);
  }, [controllableCursor]);

  const keyboardHandler = ({ key }: KeyboardEvent<HTMLUListElement>): void => {
    if (!isNumber(controllableCursor)) return;
    switch (key) {
      case 'ArrowUp': {
        if (cursor - 1 < 0) return setCursor(data.length - 1);
        setCursor((prev) => prev - 1);
        break;
      }
      case 'ArrowDown': {
        if (cursor + 1 > data.length - 1) return setCursor(0);
        setCursor((prev) => prev + 1);
        break;
      }
    }
  };

  const renderSeparator = (timeDifference: Duration): JSX.Element => {
    const minTimeCheck = elapsesMinTimeDifference(minTimeDifference, timeDifference);
    if (!minTimeCheck) return <></>;

    return (
      <TimelineItem sx={{ minHeight: '60px' }}>
        <TimelineSeparator>
          <TimelineDot sx={{ backgroundColor: 'transparent', boxShadow: 'none', p: 0, zIndex: 1 }}>
            <TimelapseIcon sx={{ color: theme.palette.grey[400], height: '.8em', width: '.8em' }} />
          </TimelineDot>
          <TimelineConnector sx={{
            backgroundColor: 'transparent',
            borderLeft: `2px dotted ${theme.palette.grey[400]}`,
            transform: 'scaleY(2.5)',
            zIndex: 0,
          }}
          />
        </TimelineSeparator>
        <TimelineContent color="text.secondary" sx={{ m: 0, p: 0 }}>
          <Typography variant="subtitle2" sx={{ ml: '.7rem', mt: '.9rem' }}>
            <i>{dropZero(timeDifference).toHuman()}</i>
          </Typography>
        </TimelineContent>
      </TimelineItem>
    );
  };

  const onFocusCapture = useCallback((index: number) => {
    if (controllableCursor) setCursor(index);
    onSelected?.(index);
  }, []);

  const refs = useRef<(HTMLButtonElement | null)[]>([]);

  useEffect(() => {
    for (let i = 0; i < dataWithDifference.length; i++) {
      const ref = refs.current[i];
      if (cursor === i && !disableFocus || hoveredEventId === dataWithDifference[i].eventId) {
        ref?.focus();
      }
      else {
        ref?.blur();
      }
    }
  }, [cursor, hoveredEventId, dataWithDifference, disableFocus]);

  const buttonRef = useCallback((element: Nullable<HTMLButtonElement>, index: number) => {
    refs.current[index] = element;
  }, []);

  return (
    <Timeline
      sx={{
        [`& .${timelineItemClasses.root}:before`]: {
          flex: 0,
          padding: 0,
        },
        '&': {
          m: 0,
          p: 0,
        },
      }}
      onKeyDown={keyboardHandler}
    >
      {(startTime && dataWithDifference[0].date)
        && renderSeparator(DateTime.fromJSDate(dataWithDifference[0].date).diff(DateTime.fromJSDate(startTime)))
      }
      {dataWithDifference.map((timelineData, i) => {
        const nextItemDifference = elapsesMinTimeDifference(minTimeDifference, dataWithDifference[i + 1]?.timeDifference);

        return (
          <MemoizedItem
            key={i}
            buttonRef={buttonRef}
            data-testid={props['data-testid']}
            disableFocusRipple={disableFocus}
            hasSeparator={elapsesMinTimeDifference(minTimeDifference, timelineData.timeDifference)}
            index={i}
            isColored={isColored(i)}
            isHovered={hoveredEventId === timelineData.eventId}
            isLast={i === dataWithDifference.length - 1}
            nextItemDifference={nextItemDifference}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onFocusCapture={onFocusCapture}
            onClick={onClick}
            {...timelineData}
          />
        );
      })}
    </Timeline>
  );
};

interface ItemProps extends TimelineData {
  buttonRef?: ((instance: HTMLButtonElement | null, index: number) => void);
  'data-testid'?: string;
  disableFocusRipple?: boolean;
  hasSeparator: boolean;
  index: number;
  isLast: boolean;
  isHovered: boolean;
  isColored: boolean;
  nextItemDifference: boolean;
  onClick?: (index: number) => void;
  onMouseEnter?: (eventId: Undefinable<string>) => void;
  onFocusCapture?: (index: number) => void;
  onMouseLeave?: (eventId: Undefinable<string>) => void;
}

const Item: FC<ItemProps> = ({
  buttonRef,
  color,
  date,
  disableFocusRipple,
  eventId,
  hasSeparator,
  index,
  isLast,
  isColored,
  isHovered,
  nextItemDifference,
  onMouseEnter,
  onMouseLeave,
  onFocusCapture,
  onClick,
  primary,
  secondary,
  timezone,
  timeDifference,
  ...props
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const displaySx: SxProps = { lineHeight: '1.0625rem', textAlign: 'left', width: '75%' };

  const renderDateTime = (dateItem: Date, timezone?: TimeZone): JSX.Element => {
    const zoneName = timezone?.id;
    const when = DateTime.fromJSDate(dateItem, { zone: zoneName }).setLocale(navigator.language);
    const whenLocal = when.toLocal();
    const localTimeFormat = when.day !== whenLocal.day ? DateTime.DATETIME_MED_WITH_SECONDS : DateTime.TIME_WITH_SECONDS;
    const localTimeString = when.toLocaleString({ ...localTimeFormat });

    return (
      <DateTimeTooltip
        disableHoverListener={whenLocal.offsetNameShort === when.offsetNameShort}
        display={
          <Typography sx={{ width: '25%' }} variant="caption">
            <FormatTimestamp format="timeWithSeconds" value={dateItem} />
          </Typography>
        }
        placement="top"
        title={t('common:component.alert-table.hint.tooltip', { dateTime: localTimeString, offsetName: when.offsetNameShort })}
      />
    );
  };

  const renderSeparator = (timeDifference: Duration): JSX.Element => {
    return (
      <TimelineItem sx={{ minHeight: '60px' }}>
        <TimelineSeparator>
          <TimelineDot sx={{ backgroundColor: 'transparent', boxShadow: 'none', p: 0, zIndex: 1 }}>
            <TimelapseIcon sx={{ color: theme.palette.grey[400], height: '.8em', width: '.8em' }} />
          </TimelineDot>
          <TimelineConnector sx={{
            backgroundColor: 'transparent',
            borderLeft: `2px dotted ${theme.palette.grey[400]}`,
            transform: 'scaleY(2.5)',
            zIndex: 0,
          }}
          />
        </TimelineSeparator>
        <TimelineContent color="text.secondary" sx={{ m: 0, p: 0 }}>
          <Typography variant="subtitle2" sx={{ ml: '.7rem', mt: '.9rem' }}>
            <i>{dropZero(timeDifference).toHuman()}</i>
          </Typography>
        </TimelineContent>
      </TimelineItem>
    );
  };

  return (
    <Fragment>
      {hasSeparator && timeDifference && renderSeparator(timeDifference)}
      <TimelineItem data-testid={props['data-testid']} sx={{ minHeight: '60px' }}>
        <TimelineSeparator>
          <TimelineDot
            sx={{
              backgroundColor: color,
              boxShadow: 'none',
              p: 0,
              zIndex: 1,
            }}
          >
            <FiberManualRecordIcon sx={{ color, height: '.8em', width: '.8em' }} />
          </TimelineDot>
          {!isLast
            && <TimelineConnector
              sx={{
                backgroundColor: nextItemDifference ? 'transparent' : theme.palette.grey[400],
                borderLeft: nextItemDifference ? `2px dotted ${theme.palette.grey[400]}` : theme.palette.grey[400],
                transform: 'scaleY(2.5)',
                zIndex: 0,
              }}
            />
          }
        </TimelineSeparator>
        <TimelineContent sx={{ p: 0, mt: '.5rem', ml: '.25rem' }}>
          <Button
            data-testid={`item-${index}-button`}
            disableFocusRipple={disableFocusRipple}
            onClick={() => onClick?.(index)}
            onFocusCapture={() => { onFocusCapture?.(index); }}
            onMouseEnter={() => { onMouseEnter?.(eventId); }}
            onMouseLeave={() => { onMouseLeave?.(eventId); }}
            ref={(element) => { buttonRef?.(element, index); }}
            sx={{
              alignItems: 'flex-start',
              backgroundColor: isColored ? color : isHovered ? '#0299d609' : 'transparent',
              color: isColored ? theme.palette.getContrastText(color) : 'inherit',
              display: 'flex',
              justifyContent: 'space-between',
              mt: '0.125rem',
              textTransform: 'inherit',
              width: '100%',
            }}
          >
            <Stack sx={{ width: '75%' }}>
              <Typography data-testid={`item-${index}-primary`} sx={displaySx}>{primary}</Typography>
              {secondary && <Typography variant="body2" data-testid={`item-${index}-secondary`} sx={displaySx}>{secondary}</Typography>}
            </Stack>
            {date && renderDateTime(date, timezone)}
          </Button>
        </TimelineContent>
      </TimelineItem>
    </Fragment>
  );
};

const MemoizedItem = React.memo(Item);
