/* eslint-disable react-hooks/exhaustive-deps */
import { PaginatedResponse } from '@eagle/api-types';
import { Subscription } from '@eagle/core-data-types';
import { Button, Card, CardContent, Stack, Table, TableContainer, Typography } from '@mui/material';
import Axios from 'axios';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHref } from 'react-router';
import { useAuthenticated } from '../../auth';
import { Alert, AlertIcon, FlexBox, SubscriptionListDesktopView, SubscriptionListMobileView, useBoolFlag } from '../../components';
import { GeneralPaginatedList, GeneralPaginatedListView } from '../../components/general-paginated-list';
import { T_MANY, T_ONE } from '../../constants';
import { useCustomRoutes } from '../../hooks';
import { CacheDataTypes } from '../../types';
import { FILTER_OUT } from '../../util';
import { FindItemsDeferredResult, Query } from '../list';

export const PROFILE_PAGE_CREATE_SUBSCRIPTIONS_FLAG = 'portals-profile-enable-create-subscription';

export const ProfilePageSubscriptions: FC = () => {
  const { axios, userInfo } = useAuthenticated();
  const { t } = useTranslation(['common']);
  const { subscription: customRouteSubscription } = useCustomRoutes();
  const itemType = t('common:terms.subscription', { count: T_MANY });
  const createSubscriptionHref = useHref(`/profile/${customRouteSubscription}/create`);
  const createSubscriptionPermissions = useBoolFlag(PROFILE_PAGE_CREATE_SUBSCRIPTIONS_FLAG);
  const subscriptionDetailPath = `/profile/${customRouteSubscription}`;

  const findSubscriptions = useCallback(({ pagination }: Query): FindItemsDeferredResult<Subscription> => {
    const cancelToken = Axios.CancelToken.source();

    return {
      cancel: cancelToken.cancel,
      promise: axios.get<PaginatedResponse<Subscription>>('/api/v2/my/subscription', {
        cancelToken: cancelToken.token,
        params: {
          ...pagination,
          filter: FILTER_OUT.finish,
          sort: { start: { when: 'desc' } },
        },
      }).then((response) => {
        return {
          result: {
            results: response.data.items,
            itemCount: response.data.count ?? 0,
          },
          resultDescription: '',
        };
      }),
    };
  }, [axios, userInfo.accountId]);

  const renderCreateSubscriptionsButton = (): JSX.Element => {
    if (!createSubscriptionPermissions) return <></>;
    return (
      <Button
        href={createSubscriptionHref}
        startIcon={<AlertIcon />}
        sx={{ alignSelf: 'flex-start' }}
      >
        {t('common:common.action.create-entity', { entity: t('common:terms.subscription', { count: T_ONE }) })}
      </Button>
    );
  };

  const titleAndAction = (
    <FlexBox justifyContent="space-between" alignItems="center" gap={1} flexWrap="wrap">
      <Typography variant="h5">{t('common:terms.subscription', { count: T_MANY })}</Typography>
      {renderCreateSubscriptionsButton()}
    </FlexBox>
  );

  const emptyState = <Alert severity='info'>{t('common:common.hint.list.none', { entity: itemType })}</Alert>;

  return (
    <GeneralPaginatedList<Subscription>
      limit={5}
      onQueryChanged={findSubscriptions}
      renderContent={({ isLoading, items, matchCount, pagination, setPagination, hasError }) =>
        <GeneralPaginatedListView<Subscription>
          hasError={hasError}
          isLoading={isLoading}
          items={items}
          matchCount={matchCount}
          pagination={pagination}
          renderDesktopContent={({ paginationElement, items: subscriptions }) => (
            <Stack spacing={2}>
              <Card>
                <CardContent>
                  {titleAndAction}
                </CardContent>
                {subscriptions.length > 0
                  ? <TableContainer>
                    <Table component="div">
                      <SubscriptionListDesktopView
                        subscriptions={subscriptions}
                        subscriptionDetailPath={subscriptionDetailPath}
                        userRecipientDataType={CacheDataTypes.MY_USER}
                        isLoading={isLoading}
                      />
                    </Table>
                  </TableContainer>
                  : <CardContent>
                    {emptyState}
                  </CardContent>
                }
                {paginationElement}
              </Card >
            </Stack >
          )}
          renderMobileContent={({ paginationElement, items: subscriptions }) => (
            <Stack spacing={2}>
              {titleAndAction}
              {subscriptions.length > 0
                ? <SubscriptionListMobileView
                  subscriptions={subscriptions}
                  subscriptionDetailPath={subscriptionDetailPath}
                  userRecipientDataType={CacheDataTypes.MY_USER}
                  isLoading={isLoading}
                  displayOnCard
                />
                : emptyState
              }
              {paginationElement}
            </Stack >
          )}
          setPagination={setPagination}
        />
      }
    />
  );
};
