import { Thing } from '@eagle/core-data-types';
import {
  CameraEventTypes,
  DeviceInstalled,
  DeviceUninstalled,
  DriverBehaviorEventTypes,
  DuressButtonActivated,
  DuressEventTypes,
  EventRecordEventTypes,
  FeatureTypes,
  InertiaEventTypes,
  InstallationEventTypes,
  LocationUpdate,
  PersonChanged,
  PersonEventTypes,
  RapidAcceleration,
  Rollover,
  SimpleActivityEventTypes,
  SpeedingFinish,
  SpeedingStart,
  ThingEvent,
  TrackingEventTypes,
  VehicleSeatEventTypes
} from '@eagle/data-function-types';
import CircleIcon from '@mui/icons-material/Circle';
import ErrorIcon from '@mui/icons-material/Error';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import SpeedIcon from '@mui/icons-material/Speed';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import { TFunction } from 'i18next';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { T_ONE } from '../../constants';
import { useCustomRoutes, useSmallScreen } from '../../hooks';
import { EventFields } from '../../pages/event-history';
import { CacheDataTypes } from '../../types';
import { getFormattedFileName, HeaderWithSpaces, kphToMph } from '../../util';
import { EventAddress } from '../events';
import { FetchOne } from '../fetch';
import { FormatTimestamp, FormatTimezone } from '../format';
import { DataRow, LinkButton, MediaViewButton } from '../static-data';
import { DataImageModal, DataVideoModal } from '../static-data/data-row-modal';
import { CameraEvents, CategoryProps, TableEventIcon } from './types';

const { ROLLOVER, ...inertiaEvents } = InertiaEventTypes;
const { DURESS_BUTTON_ACTIVATED } = DuressEventTypes;

type OtherFeatures = 'abs-activated' | 'vsc-activated' | 'airbag-deployed';

// Temporary definitions until types are added
enum AdasEventTypes {
  PRE_COLLISION_WARNING_OCCURRED = 'pre-collision-warning-occurred',
  LANE_DEPARTURE_OCCURRED = 'lane-departure-occurred',
}
enum AdditionalDriverBehaviorEventTypes {
  ATTENTION_WARNING_OCCURRED = 'attention-warning-occurred',
  DISTRACTION = 'distraction',
  NOT_WEARING_MASK = 'not-wearing-mask',
  SUNGLASSES_DETECTED = 'sunglasses-detected',
  CAMERA_BLOCKED = 'camera-blocked',
}

export const EventCategories = {
  [EventFields.DRIVER_BEHAVIOR]: Object.values({ ...inertiaEvents, ...DriverBehaviorEventTypes, ...VehicleSeatEventTypes, ...AdditionalDriverBehaviorEventTypes }),
  [EventFields.MEDIA]: Object.values(CameraEventTypes),
  [EventFields.SAFETY_INCIDENT]: Object.values({ ROLLOVER, DURESS_BUTTON_ACTIVATED, ...AdasEventTypes }),
  [EventFields.TRACKING]: Object.values(TrackingEventTypes),
  [EventFields.OTHER]: Object.values({ ...EventRecordEventTypes, ...SimpleActivityEventTypes }),
};

export const EventTableCategory: FC<CategoryProps> = ({ eventTypeId, feature }) => {
  const { t } = useTranslation(['common']);
  const header = Object.entries(EventCategories)
    .filter(([_key, value]: [string, string[]]) => value.includes(eventTypeId))
    .map(([key]) => key)[0];

  if (!header) return <HeaderWithSpaces header={feature} />;
  return <span style={{ textTransform: 'capitalize' }}>{t(`common:component.filter-dropdown.labels.${header}`)}</span>;
};

const getFeatureText = (feature: string, t: TFunction): string => {
  if (feature.includes('camera/')) return t(`common:features.camera-type.${feature}`);
  return t(`common:features.${feature}`);
};

export const EventTableEventIcon: FC<TableEventIcon> = ({ event, showDateBelowEvent }) => {
  const { eventTypeId, occurred, feature, featureTypeId } = event;
  const { t } = useTranslation(['common']);
  const theme = useTheme();
  const smallScreen = useSmallScreen();
  const iconStyling = { pr: '.5rem', m: 'auto 0' };

  const Icon = (): JSX.Element => {
    switch (eventTypeId) {
      case InertiaEventTypes.ROLLOVER: return <ErrorIcon sx={{ color: theme.palette.error.main, ...iconStyling }} />;
      case DuressEventTypes.DURESS_BUTTON_ACTIVATED: return <ErrorIcon sx={{ color: theme.palette.warning.main, ...iconStyling }} />;
      case TrackingEventTypes.POWER_ON: return <PowerSettingsNewIcon sx={{ color: theme.palette.success.main, ...iconStyling }} />;
      case TrackingEventTypes.POWER_OFF: return <PowerSettingsNewIcon sx={{ color: theme.palette.grey[500], ...iconStyling }} />;
      case TrackingEventTypes.SPEEDING_START:
      case TrackingEventTypes.SPEEDING_FINISH: return <SpeedIcon sx={{ color: theme.palette.error.main, ...iconStyling }} />;
      default: return <CircleIcon sx={{ color: theme.palette.grey[500], ...iconStyling }} />;
    }
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <Icon />
      <Stack direction="column">
        <Typography>{getFeatureText(feature, t)}</Typography>
        <Typography variant="caption">
          {t(`common:event-descriptions-v2.${featureTypeId}.${eventTypeId}.label`)}
        </Typography>
        {occurred && showDateBelowEvent
          && <Typography sx={{ mt: smallScreen ? 'inherit' : '-0.5rem' }} variant="caption">
            <FormatTimestamp value={occurred} format="shortWithSeconds" />
          </Typography>
        }
      </Stack>
    </Box>
  );
};

const DataRowTrueOrFalse: FC<{ label: JSX.Element; flag?: boolean }> = ({ label, flag }) => {
  const { t } = useTranslation(['common']);
  if (flag === undefined) return <></>;
  return <DataRow label={label} value={flag ? t('common:common.action.true') : t('common:common.action.false')} />;
};

const FormatStack: FC<{ left: JSX.Element; right: JSX.Element }> = ({ left, right }) => {
  const smallScreen = useSmallScreen();
  if (smallScreen) return <Stack sx={{ '& > div': { my: .5 }, m: 1, width: '100%' }}>{left}{right}</Stack>;
  return <>
    <Stack sx={{ '& > div': { my: .5 }, m: 1, width: '100%' }}>{left}</Stack>
    <Stack sx={{ '& > div': { my: .5 }, m: 1, width: '100%' }}>{right}</Stack>
  </>;
};

/**
  * NATIVE FUNCTION: Component for entry data body
  */
// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function EntryDataBody<T extends Omit<ThingEvent, 'schema'>>({ event }: { event: T }): JSX.Element {
  const { t } = useTranslation(['common', 'terms']);
  const { person } = useCustomRoutes();
  const [showModal, setShowModal] = useState(false);
  const handleModalOpen = (): void => setShowModal(true);
  const handleModalClose = (): void => setShowModal(false);

  if (!event.data) return t('common:component.events.thing-event.labels.table.filter-no-results');

  const { featureTypeId, eventTypeId, feature } = event;

  const FeatureCell = (): JSX.Element => (
    <DataRow
      label={getFeatureText(feature, t)}
      value={t(`common:event-descriptions-v2.${featureTypeId}.${eventTypeId}.label`)}
    />
  );

  const NoEventFormat = (): JSX.Element => (
    <Stack sx={{ '& > div': { my: .5 }, m: 1, width: '100%' }}>
      <Typography variant="body2" fontStyle="italic" color="text.secondary" sx={{ px: 2.5, py: 4 }}>{t('common:component.events.thing-event.labels.table.no-formatting-available')}</Typography>
      <DataRow label={t('common:component.events.thing-event.labels.table.raw-data')} value={<pre>{JSON.stringify(event.data, null, 4)}</pre>} />
    </Stack>
  );

  const VelocityOrAcceleration = (): JSX.Element => {
    const data = event.data as RapidAcceleration;
    if (data.velocity) return <DataRow label={t('common:component.events.labels.acceleration')} value={<Typography>{t('common:component.events.labels.speed')}: {data.velocity.speed}</Typography>} />;
    if (!data.acceleration) return t('common:component.events.thing-event.labels.table.filter-no-results');
    return (
      <DataRow label={t('common:component.events.labels.acceleration')} value={<>
        <Typography>{t('common:component.events.labels.axis', { axis: t('common:component.events.labels.x-axis'), data: data.acceleration?.axisX })}</Typography>
        <Typography>{t('common:component.events.labels.axis', { axis: t('common:component.events.labels.y-axis'), data: data.acceleration?.axisY })}</Typography>
        <Typography>{t('common:component.events.labels.axis', { axis: t('common:component.events.labels.z-axis'), data: data.acceleration?.axisZ })}</Typography>
      </>}
      />
    );
  };

  const DataContainsLocation = (data: LocationUpdate, leftChildren?: JSX.Element, rightChildren?: JSX.Element): { leftColumnFormatting: JSX.Element; rightColumnFormatting: JSX.Element } => {
    const timeZone = data.location?.timeZone?.id
      ? <DataRow label={t('common:component.events.labels.timezone')} value={<><FormatTimezone zone={data.location?.timeZone?.id} /></>} />
      : <></>;
    const leftColumnFormatting = data.location
      ? <>
        <FeatureCell />
        <DataRow label={t('common:component.events.labels.address')} value={<EventAddress location={data.location} />} />
        <DataRow label={t('common:component.events.labels.latitude')} value={<>{data.location.latitude}</>} />
        <DataRow label={t('common:component.events.labels.longitude')} value={<>{data.location.longitude}</>} />
        <DataRow label={t('common:component.events.labels.source')} value={<>{data.location.source}</>} />
        {leftChildren}
      </>
      : <>
        <FeatureCell />
        <DataRowTrueOrFalse label={t('common:component.events.labels.moving')} flag={data.moving} />
        <DataRowTrueOrFalse label={t('common:event-descriptions.tracking-v0.power-on.label')} flag={data.powerOn} />
        {leftChildren}
      </>;
    const rightColumnFormatting = data.location
      ? <>
        <DataRowTrueOrFalse label={t('common:component.events.labels.moving')} flag={data.moving} />
        <DataRowTrueOrFalse label={t('common:event-descriptions.tracking-v0.power-on.label')} flag={data.powerOn} />
        <DataRowTrueOrFalse label={t('common:component.events.labels.sub')} flag={data.sub} />
        <VelocityOrAcceleration />
        {timeZone}
        {rightChildren}
      </>
      : <>
        <DataRowTrueOrFalse label={t('common:component.events.labels.sub')} flag={data.sub} />
        <VelocityOrAcceleration />
        {timeZone}
        {rightChildren}
      </>;
    return { leftColumnFormatting, rightColumnFormatting };
  };

  switch (event.featureTypeId as FeatureTypes & OtherFeatures) {
    case FeatureTypes.CAMERA_V0: {
      const data = event.data as CameraEvents;
      switch (event.eventTypeId) {
        case CameraEventTypes.CAMERA_STATUS: // intentional fallthrough
        case CameraEventTypes.VIDEO_STATUS: {
          if (data.statuses) {
            const { start, end, status } = data.statuses.reduce((previous) => previous);
            return <FormatStack
              left={<>
                <FeatureCell />
                {start && <DataRow label={t('common:terms.start')} value={<FormatTimestamp format="long" value={start} />} />}
                {end && <DataRow label={t('common:terms.start')} value={<FormatTimestamp format="long" value={end} />} />}
              </>}
              right={<DataRow label={t('common:component.events.labels.status')} value={<>{status}</>} />}
            />;
          }
          return <FormatStack
            left={<FeatureCell />}
            right={<DataRow label={t('common:component.events.labels.status')} value={<>{data.status}</>} />}
          />;
        }
        case CameraEventTypes.IMAGE_CAPTURED: {
          return <FetchOne
            id={event.thingId}
            dataType={CacheDataTypes.THING}
            renderFactory={({ display }: Thing) => {
              const url = data.images.original;
              const displayText = getFormattedFileName(display, url, event.occurred, event.feature);
              return <>
                <FormatStack
                  left={<FeatureCell />}
                  right={<DataRow
                    action={<MediaViewButton display={t('common:common.action.view')} onClick={handleModalOpen} />}
                    label={t('common:common.labels.images')}
                    value={displayText}
                  />}
                />
                <DataImageModal
                  fileName={displayText}
                  handleClose={handleModalClose}
                  open={showModal}
                  url={url}
                />
              </>;
            }}
          />;
        }
        case CameraEventTypes.VIDEO_CAPTURED: {
          return <FetchOne
            id={event.thingId}
            dataType={CacheDataTypes.THING}
            renderFactory={({ display }: Thing) => {
              const url = data.videos.original[0];
              const displayText = getFormattedFileName(display, url, event.occurred, event.feature);
              return <>
                <FormatStack
                  left={<FeatureCell />}
                  right={<DataRow
                    action={<MediaViewButton display={t('common:common.action.view')} onClick={handleModalOpen} />}
                    label={t('common:common.labels.videos')}
                    value={displayText}
                  />}
                />
                <DataVideoModal
                  fileName={displayText}
                  handleClose={handleModalClose}
                  open={showModal}
                  url={url}
                />
              </>;
            }}
          />;
        }
      }
      break;
    }
    case FeatureTypes.DURESS_V0: {
      switch (event.eventTypeId) {
        case DuressEventTypes.DURESS_BUTTON_ACTIVATED: {
          const data = event.data as DuressButtonActivated;
          return <FormatStack
            left={<>
              <FeatureCell />
              <DataRow label={t('common:component.events.labels.address')} value={<EventAddress location={data.location} />} />
              <DataRow label={t('common:component.events.labels.latitude')} value={<>{data.location?.latitude}</>} />
              <DataRow label={t('common:component.events.labels.longitude')} value={<>{data.location?.longitude}</>} />
            </>}
            right={<>
              <DataRow label={t('common:component.events.labels.source')} value={<>{data.location?.source}</>} />
              <DataRow label={t('common:component.events.labels.timezone')} value={<><FormatTimezone zone={data.location?.timeZone?.id} /></>} />
            </>}
          />;
        }
      }
      break;
    }
    case FeatureTypes.DRIVER_BEHAVIOR_V0: {
      switch (event.eventTypeId) {
        case DriverBehaviorEventTypes.EYES_CLOSED: // intentional fallthrough
        case DriverBehaviorEventTypes.FATIGUE_RISK_DRIVING: // intentional fallthrough
        case DriverBehaviorEventTypes.LOOK_DOWN: // intentional fallthrough
        case DriverBehaviorEventTypes.MISSING_FACE: // intentional fallthrough
        case DriverBehaviorEventTypes.PHONE_USAGE: // intentional fallthrough
        case DriverBehaviorEventTypes.SMOKING: // intentional fallthrough
        case DriverBehaviorEventTypes.YAWN: {
          const { leftColumnFormatting, rightColumnFormatting } = DataContainsLocation(event.data as LocationUpdate);
          return <FormatStack
            left={leftColumnFormatting}
            right={rightColumnFormatting}
          />;
        }
        case DriverBehaviorEventTypes.OTHER: {
          const data = event.data as LocationUpdate & { type: string };
          const { leftColumnFormatting, rightColumnFormatting } = DataContainsLocation(
            data,
            undefined,
            <DataRow label={t('common:component.events.labels.data-type')} value={<>{data.type}</>} />,
          );
          return <FormatStack
            left={leftColumnFormatting}
            right={<>{rightColumnFormatting}</>}
          />;
        }
      }
      break;
    }
    case FeatureTypes.EVENT_RECORD_V0: {
      switch (event.eventTypeId) {
        case EventRecordEventTypes.OCCURRENCE: {
          const { leftColumnFormatting, rightColumnFormatting } = DataContainsLocation(event.data as LocationUpdate);
          return <FormatStack
            left={leftColumnFormatting}
            right={rightColumnFormatting}
          />;
        }
      }
      break;
    }
    case FeatureTypes.INERTIA_V0: {
      switch (event.eventTypeId) {
        case InertiaEventTypes.DANGEROUS_CORNERING: // intentional fallthrough
        case InertiaEventTypes.RAPID_ACCELERATION: // intentional fallthrough
        case InertiaEventTypes.RAPID_DECELERATION: {
          return <FormatStack
            left={<FeatureCell />}
            right={<VelocityOrAcceleration />}
          />;
        }
        case InertiaEventTypes.ROLLOVER: {
          const data = event.data as Rollover;
          if (!data.location) return t('common:component.events.thing-event.labels.table.no-location-available');

          const leftColumnFormatting = <>
            <FeatureCell />
            <DataRow label={t('common:component.events.labels.latitude')} value={<>{data.location.latitude}</>} />
            <DataRow label={t('common:component.events.labels.longitude')} value={<>{data.location.longitude}</>} />
          </>;
          const rightColumnFormatting = <>
            <DataRow label={t('common:component.events.labels.address')} value={<EventAddress location={data.location} />} />
            <DataRow label={t('common:component.events.labels.source')} value={<>{data.location.source}</>} />
          </>;
          return <FormatStack
            left={leftColumnFormatting}
            right={rightColumnFormatting}
          />;
        }
      }
      break;
    }
    case FeatureTypes.TRACKING_V0: {
      switch (event.eventTypeId) {
        case TrackingEventTypes.LOCATION_UPDATE: // intentional fallthrough
        case TrackingEventTypes.MOTION_START: // intentional fallthrough
        case TrackingEventTypes.MOTION_FINISH: {
          const { leftColumnFormatting, rightColumnFormatting } = DataContainsLocation(event.data as LocationUpdate);
          return <FormatStack
            left={leftColumnFormatting}
            right={rightColumnFormatting}
          />;
        }
        case TrackingEventTypes.POWER_ON:
          return <FormatStack
            left={<FeatureCell />}
            right={<DataRow label={t('common:event-descriptions.tracking-v0.power-on.label')} value={t('common:common.action.true')} />}
          />;
        case TrackingEventTypes.POWER_OFF:
          return <FormatStack
            left={<FeatureCell />}
            right={<DataRow label={t('common:event-descriptions.tracking-v0.power-off.label')} value={t('common:common.action.false')} />}
          />;
        case TrackingEventTypes.SPEEDING_START: // intentional fallthrough
        case TrackingEventTypes.SPEEDING_FINISH: {
          const data = event.data as SpeedingStart | SpeedingFinish;
          if (!data.speedingThreshold) return <NoEventFormat />;
          const speed = data.speedingThreshold.speed;
          return <FormatStack
            left={<FeatureCell />}
            right={<DataRow label={t('common:component.events.labels.speed')} value={t('common:component.events.labels.speed-value', { kph: speed, mph: kphToMph(speed) })} />}
          />;
        }
      }
      break;
    }
    case FeatureTypes.INSTALLATION_V0: {
      const DeviceFormat = (): JSX.Element => {
        const data = event.data as DeviceInstalled | DeviceUninstalled;
        return <FormatStack
          left={<><FeatureCell /><DataRow label={t('common:component.events.thing-event.labels.table.device-id')} value={<>{data.deviceId.deviceId}</>} /></>}
          right={<></>}
        />;
      };
      switch (event.eventTypeId) {
        case InstallationEventTypes.DEVICE_INSTALLED: return <DeviceFormat />;
        case InstallationEventTypes.DEVICE_UNINSTALLED: return <DeviceFormat />;
      }
      break;
    }
    case FeatureTypes.PERSON_V0: {
      switch (event.eventTypeId) {
        case PersonEventTypes.PERSON_CHANGED: {
          const data = event.data as PersonChanged;
          const link = data.personId
            ? { display: t('common:common.action.view'), url: `/${person}/${data.personId}` }
            : undefined;

          return <FormatStack
            left={<FeatureCell />}
            right={
              <DataRow
                action={<LinkButton link={link} />}
                label={t('terms:person', { count: T_ONE })}
                value={<>{data.personId ? data.personId : t('common:common.hint.no-results.person')}</>}
              />}
          />;
        }
      }
      break;
    }
    case FeatureTypes.VEHICLE_SEAT_V0: {
      switch (event.eventTypeId) {
        case VehicleSeatEventTypes.MOVEMENT_WITHOUT_RESTRAINT_START: {
          const { leftColumnFormatting, rightColumnFormatting } = DataContainsLocation(event.data as LocationUpdate);
          return <FormatStack
            left={leftColumnFormatting}
            right={rightColumnFormatting}
          />;
        }
      }
      break;
    }
    default: return <NoEventFormat />;
  }
  return <NoEventFormat />;
}
