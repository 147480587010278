/* eslint-disable react-hooks/exhaustive-deps */
import CloseIcon from '@mui/icons-material/Close';
import { Button, Pagination, PaginationItem, Typography } from '@mui/material';
import { FC, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { scrollIntoView } from 'seamless-scroll-polyfill';
import { FilterDrawerContent, FilterElement } from '../../components/filter';
import { useSmallScreen } from '../../hooks';
import { testid } from '../../util';
import { ListPageViewDesktop } from './view-desktop';
import { ListPageViewMobile } from './view-mobile';
import { Props } from './view.types';

const ZERO_WIDTH_SPACE = '​';

export const ListPageView: FC<Props> = (props): JSX.Element => {
  const smallScreen = useSmallScreen();
  const scrollRef = useRef<HTMLDivElement>(null);

  const {
    appliedFilters,
    fields,
    filterDrawerComponent,
    isLoading,
    matchCount,
    onFiltersChanged,
    pagination,
    removeAllFilters,
    removeFilter,
    setFilterOpen,
    setPagination,
    storageKey,
    subtitle,
    ...internal
  } = props;
  const { t } = useTranslation(['common']);
  const filtersLength = appliedFilters.length;

  const subtitleComponent = subtitle
    ? <Typography variant="subtitle2" color="GrayText" fontStyle="italic">{subtitle}</Typography>
    : <Typography variant="subtitle2" component="div">{ZERO_WIDTH_SPACE}</Typography>;

  const paginationComponent = useMemo(
    () => matchCount > pagination.limit
      ? <Pagination
        count={Math.ceil(matchCount / pagination.limit)}
        disabled={isLoading}
        onChange={(_, page) => {
          setPagination({ limit: pagination.limit, skip: pagination.limit * (page - 1) });
          scrollIntoView(scrollRef.current as Element, { behavior: 'smooth', block: 'start' });
        }}
        renderItem={(item) => (
          <PaginationItem data-testid={testid`pagination-item-${item.type}${item.type === 'page' ? `-${item.page ?? ''}` : ''}`}
            {...item}
          />
        )}
        page={Math.floor(pagination.skip / pagination.limit) + 1}
        showFirstButton={!smallScreen}
        showLastButton={!smallScreen}
        size={smallScreen ? 'small' : 'large'}
      />
      : undefined,
    [isLoading, matchCount, pagination, smallScreen],
  );

  const defaultFilterDrawerComponent = (
    <FilterDrawerContent
      fields={fields}
      filters={appliedFilters}
      onCloseClicked={() => setFilterOpen(false)}
      onFiltersChanged={onFiltersChanged}
      storageKey={storageKey}
    />
  );

  const clearAllFiltersComponent = (
    <Button
      data-testid="clear-filters-button"
      onClick={removeAllFilters}
      startIcon={<CloseIcon />}
      sx={{ mr: 1 }}
      variant="text"
    >
      {t('common:component.filter.actions.clear')}
    </Button>
  );

  const appliedFiltersComponents = useMemo(
    () => appliedFilters.length
      ? <>
        {appliedFilters.map((filter) =>
          <FilterElement
            key={filter.id}
            filter={filter}
            onRemoveClicked={() => removeFilter(filter)}
            sx={{ my: .5 }}
          />,
        )}
      </>
      : undefined,
    [appliedFilters],
  );

  if (smallScreen) {
    return (
      <ListPageViewMobile
        {...internal}
        appliedFilters={appliedFilters}
        appliedFiltersComponents={appliedFiltersComponents}
        clearAllFiltersComponent={clearAllFiltersComponent}
        filterDrawerComponent={filterDrawerComponent ?? defaultFilterDrawerComponent}
        filtersLength={filtersLength}
        paginationComponent={paginationComponent}
        removeAllFilters={removeAllFilters}
        scrollRef={scrollRef}
        setFilterOpen={setFilterOpen}
        subtitleComponent={subtitleComponent}
      />
    );
  }

  return (
    <ListPageViewDesktop
      {...internal}
      appliedFilters={appliedFilters}
      appliedFiltersComponents={appliedFiltersComponents}
      clearAllFiltersComponent={clearAllFiltersComponent}
      filterDrawerComponent={filterDrawerComponent ?? defaultFilterDrawerComponent}
      filtersLength={filtersLength}
      paginationComponent={paginationComponent}
      removeAllFilters={removeAllFilters}
      scrollRef={scrollRef}
      setFilterOpen={setFilterOpen}
      subtitleComponent={subtitleComponent}
    />
  );
};
