import { Id, Pagination } from '@eagle/api-types';
import { Geofence } from '@eagle/core-data-types';
import { Divider, ListItemText, Paper, Stack, TablePagination, Typography } from '@mui/material';
import { Box, SxProps, Theme } from '@mui/system';
import { Position } from '@turf/turf';
import L from 'leaflet';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMap } from 'react-leaflet';
import { API_CALL_TEXT_LENGTH, SEARCH_RESULT_THING_LIMIT, T_MANY } from '../../constants';
import { useFetchAllCache, usePromise } from '../../hooks';
import { FindItemsResult } from '../../pages';
import { useSearch } from '../../pages/list/use-search';
import { scrollbar } from '../../style';
import { CacheDataTypes, CommonEntityWithDeleted } from '../../types';
import { filterDeleted, useMapContext } from '../../util';
import { testid } from '../../util/test-id';
import { FilterTypes } from '../filter';
import { useBoolFlag } from '../flags';
import { LocationPin, useMapLayers } from '../map';
import { ToggleLayers } from '../map/layer-selection/layer-selection.types';
import { getFilterProps } from '../map/layer-selection/sub-menu/sub-menu.util';
import { coordinatesToLatLng } from '../map/layers/route-matching/util';
import { MapDiscoverItem } from '../search-thing-map';
import { SearchResultsProps } from './types';

/**
  * FUNCTION: Display an search result Item
  */
// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function SearchMapResults<T extends Id<string>, U, O>({
  displayOnlyLocations = false,
  handleDrawerClose,
  handleFormatAddressItem = () => <></>,
  handleFormatGeofenceItem = () => <></>,
  handleFormatListItem = () => <></>,
  highlightIndex,
  isInlayMap,
  isLoading,
  noResultsInstructions,
  onAddressClick,
  searchQuery,
  searchResults,
}: SearchResultsProps<T, U, O>): JSX.Element {
  const map = useMap();
  const { onAddressSelected, onGeofenceSelected } = useMapContext();
  const { t } = useTranslation(['common', 'terms']);
  const { geofenceFilters, setGeofenceFilters, selectedLayers, setSelectedLayers } = useMapLayers();
  const { pagination, setPagination, geofencePagination, setGeofencePagination } = useSearch();
  const [searchPosition, setSearchPosition] = useState<L.LatLng>();
  const thingsLength = searchResults.things?.result.results.length ? Math.min(searchResults.things?.result.results.length, SEARCH_RESULT_THING_LIMIT) : 0;
  const geofenceLength = searchResults.geofences?.result.results.length ? Math.min(searchResults.geofences?.result.results.length, SEARCH_RESULT_THING_LIMIT) : 0;
  const showGeofenceSearch = useBoolFlag('portals-geofence-feature');
  const filterCache = useFetchAllCache(CacheDataTypes.GEOFENCE_TYPE);
  const overflowStyle: SxProps<Theme> = { whiteSpace: isInlayMap ? 'unset' : 'nowrap', textOverflow: isInlayMap ? 'unset' : 'ellipsis' };
  const [filterItems] = usePromise(
    async () => {
      const data = await filterCache.all<CommonEntityWithDeleted>();
      return filterDeleted(data);
    },
    [filterCache],
  );

  const bottomText = (searchResult: FindItemsResult<T | O>, paginationValue: Pagination): string => {
    if (searchResult && searchResult.result.itemCount > 0) {
      return t('common:component.search.hint.pagination-of', { startCount: paginationValue.skip + 1, endCount: Math.min(paginationValue.skip + 4, searchResult.result.itemCount), totalCount: searchResult.result.itemCount });
    }
    return (t('common:common.hint.list.no-results'));
  };

  const geofenceClick = (item: O): void => {
    const geofence = item as unknown as Geofence;
    const geofenceLayerToggled = selectedLayers.some((layer) => layer === ToggleLayers.geofences);
    if (!geofenceLayerToggled) setSelectedLayers([...selectedLayers, ToggleLayers.geofences]);
    const geofenceCoords = geofence.geometry.coordinates[0].map((itemCoords) => {
      return coordinatesToLatLng(itemCoords as Position);
    });
    if (!geofenceFilters.find((filter) => filter.id === geofence.geofenceTypeId)) {
      const geofenceItem = filterItems?.find((filterItem) => filterItem._id === geofence.geofenceTypeId);
      if (geofenceItem) {
        setGeofenceFilters([...geofenceFilters, getFilterProps(geofenceItem, ToggleLayers.geofences, FilterTypes.GEOFENCE_TYPE)]);
      }
    }
    onGeofenceSelected(geofenceCoords, map);
  };

  return <>
    {!displayOnlyLocations
      && <>
        <Box data-testid='thing-map-search-things' display="flex" flexDirection="column" alignItems="flex-start" px={2} py={1} width="100%">
          <Typography variant="h6" sx={{ mt: 1 }}>{t('terms:thing', { count: T_MANY })}</Typography>
          <Stack sx={{ width: 1, filter: isLoading ? 'blur(1px)' : '', opacity: isLoading ? 0.66 : 1 }} data-testid="things-map-search-results">
            {!searchResults || !searchResults.things || searchResults.things.result.results.length === 0
              ? searchQuery && searchQuery.length < API_CALL_TEXT_LENGTH
                ? <Typography color="text.secondary" sx={{ p: 1 }}>{t('common:component.search.hint.less-than-count-map', { count: API_CALL_TEXT_LENGTH })}</Typography>
                : <Typography color="text.secondary" sx={{ p: 1 }}>{noResultsInstructions}</Typography>
              : searchResults.things.result.results.slice(0, SEARCH_RESULT_THING_LIMIT).map((thing, index) =>
                <Paper
                  key={index}
                  elevation={index === highlightIndex ? 3 : 0}
                >
                  {handleFormatListItem(thing, handleDrawerClose, searchQuery)}
                </Paper>,
              )}
          </Stack>
        </Box>

        {searchQuery && searchResults.things && searchResults.things.result.itemCount > SEARCH_RESULT_THING_LIMIT
          && <Stack direction='row' sx={{ mt: 'auto', alignItems: 'center', px: 2.5 }} data-testid="thing-map-search-pagination">
            <ListItemText secondary={bottomText(searchResults.things, pagination)} />
            <TablePagination
              component='div'
              count={searchResults.things.result.itemCount}
              labelDisplayedRows={() => ('')}
              labelRowsPerPage={''}
              onPageChange={(_: unknown, page: number) => setPagination({ limit: SEARCH_RESULT_THING_LIMIT, skip: SEARCH_RESULT_THING_LIMIT * (page) })}
              page={Math.floor(pagination.skip / SEARCH_RESULT_THING_LIMIT)}
              rowsPerPage={SEARCH_RESULT_THING_LIMIT}
              SelectProps={{ style: { display: 'none' } }}
              sx={{
                border: 'none',
                overflowX: 'hidden',
                '& .MuiTablePagination-actions': { ml: isInlayMap ? 0 : '20px' },
                '& .MuiToolbar-root': { px: isInlayMap ? 0 : '2px' },
              }}
            />
          </Stack>
        }
        {showGeofenceSearch && <>
          <Divider sx={{ my: 0.5, mx: 2 }} />
          <Box
            data-testid="thing-map-search-geofences"
            sx={{
              alignItems: 'flex-start',
              display: 'flex',
              flexDirection: 'column',
              p: 2,
              pt: 1,
              width: '100%',
            }}
          >
            <Typography variant="h6">{t('common:terms.geofence_other')}</Typography>
            <Stack spacing={0.5} sx={{ width: 1, filter: isLoading ? 'blur(1px)' : '', opacity: isLoading ? 0.66 : 1 }}>
              {!searchResults.geofences || searchResults.geofences?.result.results.length === 0
                ? searchQuery && searchQuery.length < API_CALL_TEXT_LENGTH
                  ? <Typography noWrap color="text.secondary" sx={{ p: 1 }}>{t('common:component.search.hint.less-than-count-map', { count: API_CALL_TEXT_LENGTH })}</Typography>
                  : <Typography noWrap color="text.secondary" sx={{ p: 1 }}>{noResultsInstructions}</Typography>
                : searchResults.geofences.result.results.slice(0, SEARCH_RESULT_THING_LIMIT).map((item, index) => (
                  <Paper
                    key={index}
                    data-testid={testid`thing-map-search-geofence-${index}`}
                    elevation={(index + thingsLength) === highlightIndex ? 3 : 0}
                    onClick={() => geofenceClick(item)}
                    sx={{ cursor: 'pointer', flex: [1, 1] }}
                  >
                    {handleFormatGeofenceItem(item, searchQuery)}
                  </Paper>
                ))
              }
            </Stack>
          </Box>

          {searchQuery && searchResults.geofences && searchResults.geofences.result.itemCount > SEARCH_RESULT_THING_LIMIT
            && <Stack direction="row" sx={{ mt: 'auto', alignItems: 'center', px: 2.5 }} data-testid="thing-map-search-pagination">
              <ListItemText secondary={bottomText(searchResults.geofences, geofencePagination)} />
              <TablePagination
                component="div"
                count={searchResults.geofences.result.itemCount}
                labelDisplayedRows={() => ('')}
                labelRowsPerPage={''}
                onPageChange={(_: unknown, page: number) => setGeofencePagination({ limit: SEARCH_RESULT_THING_LIMIT, skip: SEARCH_RESULT_THING_LIMIT * (page) })}
                page={Math.floor(geofencePagination.skip / SEARCH_RESULT_THING_LIMIT)}
                rowsPerPage={SEARCH_RESULT_THING_LIMIT}
                SelectProps={{ style: { display: 'none' } }}
                sx={{
                  border: 'none',
                  overflowX: 'hidden',
                  '& .MuiTablePagination-actions': { ml: isInlayMap ? 0 : '20px' },
                  '& .MuiToolbar-root': { px: isInlayMap ? 0 : '2px' },
                }}
              />
            </Stack>
          }
        </>}
        <Divider sx={{ my: 0.5, mx: 2 }} />
      </>
    }
    <Stack spacing={1} flexDirection="column" alignItems="flex-start" pt={1} px={displayOnlyLocations ? 0 : 2} pb={displayOnlyLocations ? 0 : 1} width="100%" data-testid="thing-map-search-locations">
      <Typography variant="h6">{t('common:terms.location')}</Typography>
      <Stack spacing={isInlayMap ? 0.5 : 1.5} sx={{ ...scrollbar, width: 1, filter: isLoading ? 'blur(1px)' : '', opacity: isLoading ? 0.66 : 1 }}>
        {!searchResults.addresses || searchResults.addresses?.result.results.length === 0
          ? searchQuery && searchQuery.length < API_CALL_TEXT_LENGTH
            ? <Typography noWrap color="text.secondary" sx={{ p: 1, ...overflowStyle }}>{t('common:component.search.hint.less-than-count-map', { count: API_CALL_TEXT_LENGTH })}</Typography>
            : <Typography noWrap color="text.secondary" sx={{ p: 1, ...overflowStyle }}>{noResultsInstructions}</Typography>
          : searchResults.addresses?.result.results.map((item, index) => (
            <Paper
              key={index}
              data-testid={testid`thing-map-search-location-${index}`}
              elevation={(index + thingsLength + geofenceLength) === highlightIndex ? 3 : 0}
              onClick={() => {
                const address = item as unknown as MapDiscoverItem;
                onAddressClick?.(item);
                if (!isInlayMap) {
                  onAddressSelected(address, map);
                  setSearchPosition(address.position);
                }
              }}
              sx={{ cursor: 'pointer', flex: [1, 1] }}
            >
              {handleFormatAddressItem(item)}
            </Paper>
          ))
        }
      </Stack>
      {searchPosition && <LocationPin position={searchPosition} />}
    </Stack>
  </>;
}
