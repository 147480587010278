import { evaluate as coreEvaluator, registry } from '@jexop/core';
import { dateTimeOperators } from '@jexop/date-time';
import { featureActivityIsStartedOperator, formatDistance, formatSpeed, formatTimestamp, getTimezoneFromLocation, hasFeatureDataOperator, ifFeatureDataOperator, lastEventOperator, lastEventTime, latestEventOccurredOperator, latestEventOperator, latitudeLongitude, layoutGrid, objectKeyByIndex, objectValueByIndex, renderSimpleElement, stringReplace, themeProperty, translate, ui } from './operators';
import { cardinalDirection } from './operators/cardinal-direction';
import { boolMacros, stringMacros } from './operators/macros';

registry.addDefaults();
registry.add(dateTimeOperators);

registry.add({
  ...boolMacros,
  ...stringMacros,
  'cardinal-direction': cardinalDirection,
  'feature-event': lastEventOperator,
  'feature-latest': latestEventOperator,
  'feature-latest-occurred': latestEventOccurredOperator,
  'feature-is-started': featureActivityIsStartedOperator,
  'format-distance': formatDistance,
  'format-speed': formatSpeed,
  'format-timestamp': formatTimestamp,
  'timezone-from-location': getTimezoneFromLocation,
  'has-feature-data': hasFeatureDataOperator,
  'if-feature-data': ifFeatureDataOperator,
  'last-event-time': lastEventTime,
  'latitude-longitude': latitudeLongitude,
  'layout-grid': layoutGrid,
  'object-value-by-index': objectValueByIndex,
  'object-key-by-index': objectKeyByIndex,
  'render-simple-element': renderSimpleElement,
  'string-replace': stringReplace,
  'theme-property': themeProperty,
  translate,
  ui,
});

export const evaluate = (expression: unknown, context: object): unknown => coreEvaluator(expression, context);
