import RefreshIcon from '@mui/icons-material/Refresh';
import { DateRange, DateRangePickerDay, StaticDateRangePicker } from '@mui/lab';
import { Box, Button, useTheme } from '@mui/material';
import { DateTime, DurationUnit } from 'luxon';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSmallScreen } from '../../hooks/use-small-screen';
import { Undefinable } from '../../types';
import { DateTimeRangeError } from '../../util';
import { useDateTimeRangePickerContext } from '../../util/data-time-range-picker';
import { DateTimeRangeDatePicker } from './date-time-range-date-picker';
import { DateFieldType } from './date-time-range-picker.types';
import { DateTimeRangeLayoutDesktop } from './layout-desktop';
import { TimePicker } from './time-picker';

interface Props {
  'data-testid'?: string;
  defaultEndTime: DateTime;
  defaultStartTime: DateTime;
  isDateTimePickerOpen: boolean;
  maxDateRange: number;
  maxRangeUnit: DurationUnit;
  onCalendarSelect: (date: Date, defaultTime: DateTime, dateFieldType: DateFieldType) => void;
  onCancel: () => void;
  onSetRange: () => void;
  shouldDisableDate: (date: DateTime, maxRange: number) => boolean;
  shouldDisableSetRange: () => boolean;
}

export const CustomDateRangeView: FC<Props> = ({
  defaultEndTime,
  defaultStartTime,
  isDateTimePickerOpen,
  maxDateRange,
  maxRangeUnit,
  onCalendarSelect,
  onCancel,
  onSetRange,
  shouldDisableDate,
  shouldDisableSetRange,
  ...props
}): JSX.Element => {
  const { t } = useTranslation(['common']);
  const theme = useTheme();
  const smallScreen = useSmallScreen();
  const {
    clearAll,
    dateTimeRangeError,
    endDate,
    endDateError,
    endTime,
    endTimeError,
    escapeKeyDisabled,
    handleDisableEscapeKey,
    setEndDate,
    setEndDateError,
    setEndTime,
    setEndTimeError,
    setStartDate,
    setStartDateError,
    setStartTime,
    setStartTimeError,
    startDate,
    startDateError,
    startTime,
    startTimeError,
  } = useDateTimeRangePickerContext();
  const isResetButtonDisabled = !startDate && !endDate && !startTime && !endTime;

  const renderDatePicker = (
    date: Undefinable<DateTime>,
    defaultTime: DateTime,
    error: Undefinable<DateTimeRangeError>,
    label: string,
    setDate: (date?: DateTime) => void,
    setDateError: (error?: DateTimeRangeError) => void,
    setTime: (date?: DateTime) => void,
    setTimeError: (error?: DateTimeRangeError) => void,
    testId?: string,
  ): JSX.Element => {
    return (
      <DateTimeRangeDatePicker
        date={date}
        defaultTime={defaultTime}
        error={error}
        label={label}
        setDate={setDate}
        setDateError={setDateError}
        setTime={setTime}
        setTimeError={setTimeError}
        data-testid={testId}
      />
    );
  };

  const renderTimePicker = (
    disabled: boolean,
    error: Undefinable<DateTimeRangeError>,
    label: string,
    maxRange: number,
    setTime: (date: Undefinable<DateTime>) => void,
    setTimeError: (error?: DateTimeRangeError) => void,
    time: Undefinable<DateTime>,
    testId?: string,
  ): JSX.Element => {
    return (
      <TimePicker
        disabled={disabled}
        error={error}
        handleDisableEscapeKey={handleDisableEscapeKey}
        label={label}
        maxRange={maxRange}
        maxRangeUnit={maxRangeUnit}
        setTime={setTime}
        setTimeError={setTimeError}
        time={time}
        data-testid={testId}
        views={['hours', 'minutes']}
        key={testId}
      />
    );
  };

  const renderCalendar = (
    defaultEnd: DateTime,
    defaultStart: DateTime,
    isDateDisabled: (date: DateTime, maxRange: number) => boolean,
    maxRange: number,
    testId?: string,
  ): JSX.Element => {
    const handleRangeChange = (newRange: DateRange<Date>): void => {
      if (startDate && endDate) return;
      if (!startDate) {
        const newStartDate = newRange[0] ?? newRange[1];
        newStartDate && onCalendarSelect(newStartDate, defaultStart, DateFieldType.START_DATE);
        return;
      }
      const newEndDate = newRange[1] ?? newRange[0];
      newEndDate && onCalendarSelect(newEndDate, defaultEnd, DateFieldType.END_DATE);
    };

    return (
      <Box data-testid={testId} sx={{ '& .PrivatePickersSlideTransition-root': { minHeight: '260px', minWidth: '320px' } }}>
        <StaticDateRangePicker
          componentsProps={{
            leftArrowButton: testId ? { 'data-testid': `${testId}-left-button` } : undefined,
            rightArrowButton: testId ? { 'data-testid': `${testId}-right-button` } : undefined,
          }}
          calendars={1}
          disableFuture
          displayStaticWrapperAs="desktop"
          onChange={handleRangeChange}
          renderInput={() => <></>}
          renderDay={(date, dateRangePickerDayProps) => <DateRangePickerDay data-testid={testId && `${testId}-day-${DateTime.fromJSDate(date).day}`} {...dateRangePickerDayProps} />}
          shouldDisableDate={(date: Date) => isDateDisabled(DateTime.fromJSDate(date), maxRange)}
          value={[startDate?.toJSDate() ?? null, endDate?.toJSDate() ?? null]}
        />
      </Box>
    );
  };

  const renderResetButton = (disabled: boolean, handleReset: () => void): JSX.Element => {
    return (
      <Button
        data-testid="reset-button"
        disabled={disabled}
        onClick={handleReset}
        startIcon={<RefreshIcon />}
        sx={{
          alignSelf: 'center',
          color: theme.palette.primary.main,
          width: 'fit-content',
        }}
      >
        {t('common:component.date-picker.action.reset-range')}
      </Button>
    );
  };

  const renderCancelButton = (handleCancel: () => void): JSX.Element => {
    return (
      <Button data-testid="cancel-button" onClick={handleCancel}>
        {t('common:common.action.cancel')}
      </Button>
    );
  };

  const renderSetRangeButton = (handleSetRange: () => void, disabled: boolean): JSX.Element => {
    return (
      <Button
        data-testid="set-button"
        disabled={disabled}
        onClick={handleSetRange}
        variant="contained"
      >
        {t('common:component.date-picker.action.set-range')}
      </Button>
    );
  };

  if (smallScreen) return <></>;

  return (
    <DateTimeRangeLayoutDesktop
      calendar={renderCalendar(
        defaultEndTime,
        defaultStartTime,
        shouldDisableDate,
        maxDateRange,
        'calendar',
      )}
      cancelButton={renderCancelButton(onCancel)}
      data-testid={props['data-testid']}
      disableEscapeKeyDown={escapeKeyDisabled}
      endDatePicker={renderDatePicker(
        endDate,
        defaultEndTime,
        endDateError,
        t('common:component.date-picker.labels.end-date'),
        setEndDate,
        setEndDateError,
        setEndTime,
        setEndTimeError,
        'calendar-end-date',
      )}
      endTimePicker={renderTimePicker(
        !endDate,
        endTimeError ?? dateTimeRangeError,
        t('common:component.date-picker.labels.end-time'),
        maxDateRange,
        setEndTime,
        setEndTimeError,
        endTime,// ?? defaultEndTime,
        'calendar-end-time',
      )}
      isDateTimePickerOpen={isDateTimePickerOpen}
      onCancel={onCancel}
      resetButton={renderResetButton(isResetButtonDisabled, clearAll)}
      setRangeButton={renderSetRangeButton(onSetRange, shouldDisableSetRange())}
      startDatePicker={renderDatePicker(
        startDate,
        defaultStartTime,
        startDateError,
        t('common:component.date-picker.labels.start-date'),
        setStartDate,
        setStartDateError,
        setStartTime,
        setStartTimeError,
        'calendar-start-date',
      )}
      startTimePicker={renderTimePicker(
        !startDate,
        startTimeError,
        t('common:component.date-picker.labels.start-time'),
        maxDateRange,
        setStartTime,
        setStartTimeError,
        startTime,// ?? defaultStartTime,
        'calendar-start-time',
      )}
    />
  );
};
