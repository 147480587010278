import { AlertResponse } from '@eagle/core-data-types';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import { useTheme } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { getAlertTypeStyle } from '../../util';
import { LabelledBadge } from '../labelled-badge';

interface AlertLabelProps {
  align?: 'left' | 'right' | 'center';
  alert: AlertResponse;
}

export const AlertLabel: FC<AlertLabelProps> = ({ alert, align = 'left' }) => {
  const theme = useTheme();
  const alertStyles = getAlertTypeStyle(alert, theme);
  const { t } = useTranslation(['common']);
  const suppressionIcon = alert.suppressed ? <NotificationsOffIcon /> : undefined;
  const suppressionLabel = alert.suppressed ? t('common:component.alerts-table.hint.suppression') : undefined;

  return <LabelledBadge align={align} background={alertStyles.background} color={alertStyles.color} fullWidth icon={suppressionIcon} label={t(`common:features.${alert.feature}`)} subtitle={t(`common:alert-descriptions.${alert.featureTypeId}.${alert.alertTypeId}.label`)} tooltip={suppressionLabel} />;
};
