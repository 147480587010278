import { Close } from '@mui/icons-material';
import { Box, IconButton, Modal, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthenticated } from '../../auth';
import { ImageModal } from '../../components/image-modal';
import { VideoModal } from '../../components/video-modal';
import { usePromise } from '../../hooks/use-promise';
import { getSignedUrl } from '../../util';
import { dataRowModalStyles } from './data-row-modal.styles';

interface MediaModal {
  fileName?: string;
  handleClose: () => void;
  open: boolean;
  url: string;
}

export const DataImageModal: FC<MediaModal> = ({ fileName, handleClose, open, url }) => {
  const { axios } = useAuthenticated();
  const { t } = useTranslation(['common']);
  const { classes } = dataRowModalStyles();

  const [signedImageUrl, signedImageUrlError, signedImageUrlState] = usePromise(async () => {
    const result = await getSignedUrl(axios, url);
    return result.data.signedUrl;
  }, [axios, url]);

  if (signedImageUrlState === 'pending') return <></>;

  if (signedImageUrlError || !signedImageUrl) {
    return (
      <Modal disablePortal open={open} onClose={handleClose} sx={{ zIndex: 1400 }} >
        <Box className={classes.imageBox}>
          <Typography
            className={classes.errorText}
            variant="caption"
          >
            {t('common:component.static-data.hint.image-modal-error')}
          </Typography>
          <IconButton
            aria-label={t('common:common.action.close')}
            className={classes.closeButton}
            onClick={handleClose}
          >
            <Close />
          </IconButton>
        </Box>
      </Modal>
    );
  }

  return (
    <ImageModal
      fileName={fileName}
      handleClose={handleClose}
      image={signedImageUrl}
      open={open}
    />
  );
};

export const DataVideoModal: FC<MediaModal> = ({ fileName, handleClose, open, url }) => {
  return (
    <VideoModal
      fileName={fileName}
      handleClose={handleClose}
      open={open}
      url={url}
    />
  );
};
