/* eslint-disable react-hooks/exhaustive-deps */
import lottie, { AnimationConfig } from 'lottie-web';
import { createRef, FC, useEffect } from 'react';

export interface PlayerProps {
  className?: string;
  initialSegment?: [number, number];
  loop?: boolean;
  src?: string;
  style?: { [key: string]: string | number };
}

// Build default config for lottie-web player
const defaultOptions: Partial<AnimationConfig> = {
};

export const Player: FC<PlayerProps> = ({ className, initialSegment, loop = true, src, style }) => {
  const container = createRef<HTMLDivElement>();

  useEffect(() => {
    if (!container.current) return;

    const instance = lottie.loadAnimation({
      ...defaultOptions,
      autoplay: true,
      container: container.current,
      initialSegment,
      loop,
      path: src,
      renderer: 'svg',
    });

    return () => instance.destroy();
  }, [container, src]);

  return (
    <div
      id="lottie"
      className={className}
      ref={container}
      style={{
        margin: '0 auto',
        outline: 'none',
        overflow: 'hidden',
        ...style,
      }}
    />
  );
};
