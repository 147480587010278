/* eslint-disable react-hooks/exhaustive-deps */
// cspell: ignore geocode
import { PaginatedResponse } from '@eagle/api-types';
import { Time } from '@eagle/common';
import { PersonThingEvent, ThingEventSnapshot } from '@eagle/core-data-types';
import { TrackingEventTypes } from '@eagle/data-function-types';
import { Add, LocationSearching, Visibility, VisibilityOff } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import RoomIcon from '@mui/icons-material/Room';
import { Box, Collapse, IconButton, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { AxiosResponse } from 'axios';
import { debounce } from 'lodash';
import { DateTime } from 'luxon';
import { useSnackbar } from 'notistack';
import { Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthenticated } from '../../auth';
import { HERE_MAP_API_KEY, THING_EVENT_API_LIMIT } from '../../constants';
import { useConfig, usePromise, useSmallScreen } from '../../hooks';
import { useRecursive } from '../../hooks/use-recursive';
import { EventFields } from '../../pages/event-history/history-drawer/history-drawer.types';
import { useSearch } from '../../pages/list/use-search';
import { scrollbar } from '../../style';
import { CacheDataTypes, Maybe, Nullable, Undefinable } from '../../types';
import { formatDateRange, formatTimeRange, isMatchingDay, validateLocationType } from '../../util';
import { DateTimeRangePickerProvider } from '../../util/data-time-range-picker';
import { Accordion, AccordionDetails, AccordionSummary } from '../accordion';
import CircularProgressIndicator from '../circular-progress-indicator/circular-progress-indicator';
import { AppliedFilter, EntityField } from '../entity-search';
import { ErrorMessage } from '../error-message';
import { useEventDialog } from '../event-dialog';
import { EventCategories } from '../events-table';
import { useBoolFlag, useFlags, useNumberFlag } from '../flags';
import { FormatDate } from '../format';
import { DeleteIcon } from '../icons';
import { InlineDateTimeRangePicker } from '../inline-date-time-range-picker/inline-date-time-range-picker';
import { EventLocationData } from '../map/thing-event-pane/thing-event-pane.types';
import { TimeLine, TimelineData } from '../timeline';
import { EntityCardActions } from './entity-card-actions';
import { CardAction, JourneyItem } from './entity-journey-types';
import { useHistorySearch } from './use-history-search';
import { getEventDictionary, getJourneyColor, isValidEvent } from './util';

interface Props {
  data: JourneyItem;
  'data-testid'?: string;
  entityDisplay: string;
  hoveredEventId: Maybe<string>;
  selectedEvent: Maybe<EventLocationData>;
  setHoveredEventId: Dispatch<SetStateAction<Maybe<string>>>;
  setSelectedEvent: Dispatch<SetStateAction<Maybe<EventLocationData>>>;
}

type EventFiltersType = typeof EventCategories[keyof typeof EventCategories] | unknown[];

export const HISTORY_DEFAULT_MAX_DATE_RANGE = 24;
export const HISTORY_DEFAULT_DATE_RANGE_UNIT = 'hours';
export const HISTORY_DATE_RANGE_FLAG = 'track-thing-history-custom-date-range-feature';
const HISTORY_REQUEST_RETRY_COUNT = 2;

export const JourneyInstance: FC<Props> = ({
  data,
  entityDisplay,
  hoveredEventId,
  selectedEvent,
  setHoveredEventId,
  setSelectedEvent,
  ...props
}) => {
  const {
    dateEditingJourney,
    entityVisibilities,
    expandedEntity,
    handleExpand,
    mapLoading,
    removeJourney,
    setMapLoading,
    setNextZoomTarget,
    toggleEditingDate,
    toggleVisibleItem,
    updateDateTime,
    updateJourney,
  } = useHistorySearch();
  const { axios } = useAuthenticated();
  const config = useConfig();
  const { endTime, startTime } = data.dateRange;
  const { enqueueSnackbar } = useSnackbar();
  const { filters } = useSearch();
  const { t } = useTranslation(['common', 'track']);
  const [anchorEl, setAnchorEl] = useState<Nullable<HTMLElement>>(null);
  const smallScreen = useSmallScreen();
  const actionsOpen = Boolean(anchorEl);
  const theme = useTheme();
  const color = getJourneyColor(data.colorIndex, theme);
  const endDateTime = DateTime.fromJSDate(endTime);
  const entityVisibility = entityVisibilities.find((visibility) => visibility.entityId === data.entityId);
  const instanceExpanded = (data.entityId === expandedEntity?.entityId) && (data.id === expandedEntity.journeyId);
  const isEditingDate = (dateEditingJourney?.entityId === data.entityId) && (dateEditingJourney.journeyId === data.id);
  const isJourneyVisible = entityVisibility?.journeyVisibility.find((journey) => journey.journeyId === data.id)?.isVisible;
  const loadingHint = t('common:common.labels.loading');
  const startDateTime = DateTime.fromJSDate(startTime);
  const darklyTime = useNumberFlag('track-history-feature-notable-event-time-period');
  const { setEvents, setEventIndex, setOpen } = useEventDialog();
  const flags = useFlags();
  const maxDateRange = flags[HISTORY_DATE_RANGE_FLAG] as number || HISTORY_DEFAULT_MAX_DATE_RANGE;
  const isBatchingEnabled = useBoolFlag('track-thing-history-parallel-processing-api-feature');
  const batchSize = useNumberFlag('track-thing-history-parallel-processing-api-batch-settings-feature');
  const timeoutToDisplayToast = useNumberFlag('track-thing-history-parallel-processing-api-first-api-response-time-toast-feature');
  const isRetryingEnabled = useBoolFlag('track-thing-history-retry-api-calls-feature');

  const eventFilters = filters.flatMap((filter): EventFiltersType => {
    switch ((filter as AppliedFilter<EntityField>).value.id) {
      case EventFields.DRIVER_BEHAVIOR:
        return EventCategories[EventFields.DRIVER_BEHAVIOR];
      case EventFields.MEDIA:
        return EventCategories.media;
      case EventFields.SAFETY_INCIDENT:
        return EventCategories[EventFields.SAFETY_INCIDENT];
      case EventFields.OTHER:
        return EventCategories.other;
    }
    return [];
  });

  const url = data.entityType === CacheDataTypes.THING_TYPE
    ? `/api/v2/thing-event/thing/${data.entityId}`
    : `/api/v2/person-thing-event/person/${data.entityId}`;

  const locationFilters = { '$nin': [TrackingEventTypes.NO_LOCATION_UPDATE, TrackingEventTypes.INACCURATE_LOCATION_UPDATE] };

  const params = {
    dateRangeStart: startTime,
    dateRangeFinish: endTime,
    filter: eventFilters.length
      ? { 'eventTypeId': { '$in': eventFilters, ...locationFilters } }
      : { 'eventTypeId': locationFilters },
    limit: THING_EVENT_API_LIMIT,
    sort: { occurred: 1 },
  };

  const transformData = (response: AxiosResponse<PaginatedResponse<ThingEventSnapshot>, any>): AxiosResponse<PaginatedResponse<ThingEventSnapshot>> => {
    if (data.entityType === CacheDataTypes.THING_TYPE) return response;
    const personData = response.data.items as unknown[] as PersonThingEvent[];
    return { ...response, data: { ...response.data, items: personData.map(({ thingEvent }) => thingEvent) } };
  };

  const [thingEventSnapShots, thingEventSnapShotsError, thingEventSnapShotsStatus] = useRecursive<ThingEventSnapshot>(
    url,
    params,
    transformData,
    [data, filters],
    isBatchingEnabled ? batchSize : undefined,
    isRetryingEnabled ? HISTORY_REQUEST_RETRY_COUNT : undefined,
  );

  useDisplaySnackbarAfterTimeout({
    enabled: isBatchingEnabled,
    isPending: thingEventSnapShotsStatus === 'pending',
    message: t('track:page.history.long-api-response.hint.info'),
    timeoutMs: Time.seconds(timeoutToDisplayToast ?? 0),
  });

  const [timeZoneFallBack] = usePromise<Undefinable<string>>(async () => {
    const itemLocation = thingEventSnapShots?.items.find(({ data: { location } }) => location)?.data.location;
    const validLocation = validateLocationType(() => itemLocation);
    if (!thingEventSnapShots || thingEventSnapShotsStatus !== 'resolved' || !validLocation) return;
    const timeZone = await axios.get<{ timeZone: string }>('/api/v1/reverse-geocode', {
      params: {
        apiKey: config.hereMaps?.apiKey ?? HERE_MAP_API_KEY,
        lat: validLocation.latitude,
        long: validLocation.longitude,
      },
      transformResponse: (result: string) => JSON.parse(result) as { timeZone: string },
    }).then((response) => response.data.timeZone);
    return timeZone;
  }, [thingEventSnapShots, thingEventSnapShotsStatus]);

  const timeLineData = useMemo(() => {
    return thingEventSnapShotsStatus === 'resolved'
      ? thingEventSnapShots?.items.map(({ feature, featureTypeId, eventId, eventTypeId, occurred, data: { location }, ...rest }) => {
        const eventData = getEventDictionary({ feature, featureTypeId, eventId, eventTypeId, occurred, data: { location }, ...rest }, theme);
        if (!isValidEvent(eventData) || !eventData) return;
        const eventLocation = validateLocationType(() => location);
        const primary = t(`common:features.${feature}`);
        const secondary = t(`common:event-descriptions-v2.${featureTypeId}.${eventTypeId}.label`);
        return {
          color: eventData.styling.backgroundColor,
          date: occurred,
          eventId,
          primary,
          secondary,
          timezone: eventLocation?.timeZone ?? timeZoneFallBack,
        };
      }).filter(Boolean) as Undefinable<TimelineData[]>
      : [];
  }, [thingEventSnapShotsStatus, thingEventSnapShots, timeZoneFallBack, theme, t]);

  const events = useMemo(() => (
    thingEventSnapShots?.items.filter((eventSnapshot) => {
      const eventData = getEventDictionary(eventSnapshot, theme);
      return eventData && isValidEvent(eventData);
    })
  ), [thingEventSnapShots]);

  const thingEventSnapShotsRetrieved = thingEventSnapShots?.items?.length ?? 0;
  const thingEventSnapShotsTotalCount = thingEventSnapShots?.count ?? 0;

  const journeyActions: CardAction[] = [
    {
      name: 'add',
      icon: <Add />,
      isConfirm: false,
      label: t('common:component.entity-journey.action.change-date-time'),
      onClick: () => {
        setAnchorEl(null);
        toggleEditingDate(data.id, data.entityId);
      },
    }, {
      name: 'delete',
      confirmPrompt: t('common:common.hint.confirm'),
      icon: <DeleteIcon />,
      isConfirm: true,
      label: t('common:common.action.remove'),
      onClick: () => {
        setAnchorEl(null);
        removeJourney(data.entityId, data.id);
      },
    },
  ];

  const handleCloseActions = (): void => setAnchorEl(null);

  const handleDateRangeChange = (startDate: Date, endDate: Date): void => {
    updateDateTime(data.entityId, data.id, startDate, endDate);
  };

  const handleClickActions = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleVisibility = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.stopPropagation();
    toggleVisibleItem(data.id, data.entityId);
  };

  const handleZoomTo = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.stopPropagation();
    setNextZoomTarget(data.id);
  };

  const debouncedSetHoveredEventId = useCallback(debounce((eventId: Maybe<string>) => {
    setHoveredEventId(eventId);
  }, 200), []);

  const onMouseLeave = useCallback(() => { debouncedSetHoveredEventId(null); }, [debouncedSetHoveredEventId]);

  const onItemClick = useCallback((index: number) => {
    setEventIndex(index);
    setEvents(events ?? []);
    setOpen(true);
  }, [events]);

  const renderAccordionTitle = (): JSX.Element => {
    if (isEditingDate) return <Typography sx={{ fontStyle: 'italic' }}>{t('common:component.entity-journey.action.set-date-range')}</Typography>;

    return <>
      <Typography sx={{ color: theme.palette.text.secondary, fontSize: 'small' }}>
        {!isMatchingDay(startDateTime, endDateTime) && (endTime > startTime)
          ? formatDateRange(startDateTime, endDateTime)
          : <FormatDate value={startTime} />
        }
      </Typography>
      <Typography>
        {formatTimeRange(startTime, endTime)}
      </Typography>
    </>;
  };

  const renderAccordionDetails: JSX.Element = useMemo(() => {
    if (!timeLineData?.length) return <Typography data-testid="no-events-label" sx={{ fontStyle: 'italic' }}>{t('common:component.events.no-events.hint')}</Typography>;
    return (
      <Box sx={{ ...scrollbar, maxHeight: '500px', overflow: 'auto' }}>
        <TimeLine
          data-testid="timeline"
          data={timeLineData}
          hoveredEventId={hoveredEventId}
          minTimeDifference={{ minutes: darklyTime ?? 5 }}
          onClick={onItemClick}
          onMouseEnter={debouncedSetHoveredEventId}
          onMouseLeave={onMouseLeave}
          startTime={startTime}
        />
      </Box>
    );
  }, [data, hoveredEventId, instanceExpanded, isEditingDate, timeLineData]);

  useEffect(() => {
    setMapLoading((previousLoading) => ({ ...previousLoading, [data.id]: thingEventSnapShotsStatus === 'pending' }));
  }, [thingEventSnapShotsStatus]);

  useEffect(() => {
    if (!thingEventSnapShots || thingEventSnapShotsStatus !== 'resolved') return;
    updateJourney(
      data,
      thingEventSnapShots as PaginatedResponse<EventLocationData>,
      true,
      entityDisplay,
    );
  }, [thingEventSnapShots]);

  useEffect(() => {
    if (!instanceExpanded) return;
    setEvents(events ?? []);
  }, [events]);

  useEffect(() => {
    if (thingEventSnapShotsError) {
      enqueueSnackbar(<ErrorMessage error={thingEventSnapShotsError} />, { variant: 'error' });
    }
  }, [thingEventSnapShotsError]);

  const loadingJourney = Object.entries(mapLoading).find(([id]) => id === data.id)?.[1] ?? false;

  if (thingEventSnapShotsStatus === 'pending' || loadingJourney) {
    return (
      <Stack direction="row" spacing={2} sx={{ alignItems: 'center', pl: 1 }}>
        <CircularProgressIndicator
          color={color}
          isLoadingOthers={loadingJourney}
          loadingHint={loadingHint}
          retrievedCount={thingEventSnapShotsRetrieved}
          totalCount={thingEventSnapShotsTotalCount}
        />
        <Stack direction="column">
          {renderAccordionTitle()}
        </Stack>
      </Stack>
    );
  }

  return <>
    <Accordion
      data-testid={props['data-testid']}
      disableGutters
      expanded={instanceExpanded}
      onChange={() => handleExpand(data.entityId, data.id)}
      sx={{
        '&:before': { display: 'none' },
        '& .MuiAccordionSummary-root': { minHeight: 'fit-content', p: 0 },
        '& .MuiAccordionSummary-root.Mui-expanded': { minHeight: 'fit-content' },
        '& .MuiAccordionDetails-root': { py: 1 },
        boxShadow: 'none',
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon data-testid="expand-icon" />}
        sx={{
          flexDirection: 'row-reverse',
          '& .MuiAccordionSummary-content': {
            justifyContent: 'space-between',
            m: '0',
          },
          '& .MuiAccordionSummary-content.Mui-expanded': {
            m: '0',
          },
          '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'rotate(180deg)',
          },
        }}
      >
        <Stack direction="row" spacing={1} sx={{ alignItems: 'center', pl: 1 }}>
          <RoomIcon sx={{ color, fontSize: '2.5rem' }} />
          <Stack direction="column">
            {renderAccordionTitle()}
          </Stack>
        </Stack>
        <Stack alignItems="center" direction="row" spacing={1}>
          {!smallScreen && isJourneyVisible &&
            <Tooltip title={t('common:common.labels.zoom-to-journey')} arrow>
              <IconButton sx={{ p: 0 }} onClick={handleZoomTo}>
                <LocationSearching />
              </IconButton>
            </Tooltip>
          }
          <IconButton sx={{ p: 0 }} onClick={handleVisibility}>
            {isJourneyVisible ? <Visibility /> : <VisibilityOff />}
          </IconButton>
          <IconButton sx={{ p: 0 }} onClick={handleClickActions}>
            <MoreVertIcon />
          </IconButton>
        </Stack>
      </AccordionSummary>
      <AccordionDetails sx={{ m: 0, p: 0 }}>
        {renderAccordionDetails}
      </AccordionDetails>
      <EntityCardActions actions={journeyActions} anchorEl={anchorEl} open={actionsOpen} handleClose={handleCloseActions} />
    </Accordion>
    <Collapse in={isEditingDate} unmountOnExit>
      <DateTimeRangePickerProvider>
        <InlineDateTimeRangePicker
          data-testid="datetime-picker"
          closeDateTimePicker={() => toggleEditingDate(data.id, data.entityId)}
          currentEndDateTime={endDateTime}
          currentStartDateTime={startDateTime}
          hideSeconds
          maxDateRange={maxDateRange}
          maxRangeUnit={HISTORY_DEFAULT_DATE_RANGE_UNIT}
          onDateRangeChanged={handleDateRangeChange}
        />
      </DateTimeRangePickerProvider>
    </Collapse>
  </>;
};

interface UseDisplaySnackbarAfterTimeoutOptions {
  enabled?: boolean;
  isPending: boolean;
  message: string;
  timeoutMs: number;
}

const useDisplaySnackbarAfterTimeout = ({ enabled, isPending, message, timeoutMs }: UseDisplaySnackbarAfterTimeoutOptions): void => {
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (enabled && timeoutMs && isPending) {
      const timeout = setTimeout(() => {
        enqueueSnackbar(message, { preventDuplicate: true });
      }, timeoutMs);

      return (() => {
        clearTimeout(timeout);
      });
    }
  }, [enqueueSnackbar, enabled, isPending, message, timeoutMs]);
};
