import { AlertResponse, Person, Thing } from '@eagle/core-data-types';
import { Box, Stack, TableCell, TableRow, Typography, useTheme } from '@mui/material';
import { DateTime } from 'luxon';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useCustomRoutes } from '../../hooks';
import { CacheDataTypes } from '../../types';
import { testid, validateLocationType } from '../../util';
import { AlertLabel } from '../alert-label';
import { FetchOne } from '../fetch';
import { FormatAddress, FormatTimestamp } from '../format';
import { GroupChipElement } from '../group-chip-element';
import { DateTimeTooltip } from '../tooltip';
import { DATE_TIME_WIDTH } from './layout-desktop';

interface Props {
  alert: AlertResponse;
  alertPath: string;
  showFeature: boolean;
  showGroups: boolean;
  showPerson: boolean;
  showThing: boolean;
  smallTable: boolean;
}

interface DisplayMultipleProps {
  ids: string[];
  renderer: (id: string) => JSX.Element;
}

const AlertDate: FC<{ when: DateTime }> = ({ when }): JSX.Element => {
  const date = when.toJSDate();
  const whenLocal = when.toLocal();
  const { t } = useTranslation(['common']);
  const localTimeFormat = when.day !== whenLocal.day ? DateTime.DATETIME_MED_WITH_SECONDS : DateTime.TIME_WITH_SECONDS;
  const localTimeString = when.toLocaleString({ ...localTimeFormat });

  return (
    <Stack data-chromatic="ignore">
      <DateTimeTooltip
        disableHoverListener={whenLocal.offsetNameShort === when.offsetNameShort}
        display={
          <Box component="span" sx={{ display: 'flex', flexDirection: 'column' }}>
            <span><FormatTimestamp format="dateFull" value={date} /></span>
            <span><FormatTimestamp format="timeWithSeconds" value={date} /></span>
          </Box>
        }
        placement="top"
        title={t('common:component.alert-table.hint.tooltip', { dateTime: localTimeString, offsetName: when.offsetNameShort })}
      />
      <Typography color="text.secondary" variant="caption">
        <FormatTimestamp format="relative" value={date} />
      </Typography>
    </Stack>
  );
};

const DisplayMultiple: FC<DisplayMultipleProps> = ({ ids, renderer }) => {
  const { t } = useTranslation(['common']);
  if (!ids.length) return <></>;

  return <>
    <span>{renderer(ids[0])}</span>
    {ids.length > 1 && <span>, {renderer(ids[1])}</span>}
    {ids.length > 2 && <span>, {t('common:common.labels.othersWithCount', { count: ids.length - 1 })}</span>}
  </>;
};

export const AlertListItemDesktop: FC<Props> = ({ alert, alertPath, showFeature, showGroups, showPerson, showThing, smallTable }) => {
  const { t } = useTranslation(['common']);
  const theme = useTheme();
  const { alert: customRoutesAlert } = useCustomRoutes();
  const location = validateLocationType(() => alert.data?.location);
  const address = location?.address ?? [];
  const zoneName = location?.timeZone?.id;
  const when = DateTime.fromJSDate(alert.occurred, { zone: zoneName }).setLocale(navigator.language);

  return (
    <TableRow
      component={Link}
      data-testid={testid`alert-id-${alert.data.testId ?? alert.alertId}`}
      hover
      to={`/${customRoutesAlert}/${alert.alertId}`}
      state={{ previousUrl: alertPath }}
      sx={{
        cursor: 'pointer',
        textDecoration: 'none',
        '&:focus, &.hover': {
          backgroundColor: theme.palette.action.hover,
        },
      }}
    >
      <TableCell component="div" sx={{ minWidth: smallTable ? ['unset', 'unset', 'unset', DATE_TIME_WIDTH] : DATE_TIME_WIDTH }}>
        <AlertDate when={when} />
      </TableCell>

      <TableCell component="div" sx={{ px: 2 }}>
        <AlertLabel alert={alert} />
      </TableCell>

      {showFeature &&
        <TableCell component="div">
          {t(`common:event-descriptions-v2.${alert.featureTypeId}.${alert.alertTypeId}.label`, { features: t(`common:features.${alert.feature}`) })}
        </TableCell>
      }

      {showThing
        && <TableCell
          component="div"
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            width: '100%',
          }}
        >
          {!alert.thingIds.length
            ? <Typography color="text.secondary" fontStyle="italic" variant="body2">
              {t('common:component.alert-table.hint.no-results.none')}
            </Typography>
            : <DisplayMultiple
              ids={alert.thingIds}
              renderer={(id: string) => (
                <FetchOne
                  dataType={CacheDataTypes.THING}
                  id={id}
                  renderFactory={(thing: Thing) => <>{thing.display}</>}
                />
              )}
            />
          }
        </TableCell>
      }

      {showPerson
        && <TableCell
          component="div"
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            width: '100%',
          }}
        >
          {!alert.personIds.length
            ? <Typography color="text.secondary" fontStyle="italic" variant="body2">
              {t('common:component.alert-table.hint.no-results.none')}
            </Typography>
            : <DisplayMultiple
              ids={alert.personIds}
              renderer={(id: string) => (
                <FetchOne
                  dataType={CacheDataTypes.PERSON}
                  id={id}
                  renderFactory={(person: Person) => <>{person.display}</>}
                />
              )}
            />
          }
        </TableCell>
      }

      <TableCell component="div">
        {!address.length
          ? <Typography color="text.secondary" fontStyle="italic" variant="body2">
            {t('common:component.alert-table.hint.no-results.address')}
          </Typography>
          : <Stack>
            <FormatAddress value={address} variant="body2" />
          </Stack>
        }
      </TableCell>

      {showGroups
        && <TableCell component="div">
          {!alert.groupIds || !alert.groupIds.length
            ? <Typography color="text.secondary" fontStyle="italic" variant="body2">
              {t('common:component.alert-table.hint.no-results.groups')}
            </Typography>
            : <GroupChipElement
              groupIds={alert.groupIds}
              sx={{
                maxWidth: '100%',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
              type={CacheDataTypes.GROUP}
            />
          }
        </TableCell>
      }
    </TableRow>
  );
};
