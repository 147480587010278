import { AlertResponse, Subscription } from '@eagle/core-data-types';
import { Paper, TableCell, Typography, useTheme } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useHref } from 'react-router-dom';
import { getAlertTypeStyle, getFeatureType } from '../../util';
import { ResultsTableRow } from '../search-results-table';
import { COLUMN_WIDTH } from './subscription-list-desktop-view';
import { SubscriptionUserRecipientDataType } from './subscriptions.types';
import { getEntityData, RecipientDisplay, SourceDisplay } from './subscriptions.util';

interface SubscriptionsTableRowProps {
  disableLink?: boolean;
  userRecipientDataType: SubscriptionUserRecipientDataType;
  subscription: Subscription;
  subscriptionDetailPath: string;
  'data-testid'?: string;
}

export const SubscriptionsTableRow: FC<SubscriptionsTableRowProps> = ({ subscription, disableLink, subscriptionDetailPath, userRecipientDataType, ...props }) => {
  const href = useHref(subscriptionDetailPath);
  const { _id, alertFilters, recipient } = subscription;
  const { alertTypeId, feature } = alertFilters;
  const { t } = useTranslation(['admin']);
  const theme = useTheme();

  const featureTypeId = getFeatureType(alertTypeId);
  const renderContent = (): JSX.Element => <>
    <TableCell component="div" sx={{ width: COLUMN_WIDTH }} data-testid="subscription-recipient">
      <RecipientDisplay userDataType={userRecipientDataType} recipient={recipient} />
    </TableCell>

    <TableCell component="div" sx={{ width: COLUMN_WIDTH }} data-testid="subscription-entity">
      <SourceDisplay entityData={getEntityData(alertFilters)} />
    </TableCell>

    <TableCell component="div" sx={{ width: COLUMN_WIDTH }}>
      <Typography data-testid="subscription-feature">{feature ? t(`common:features.${feature}`) : feature}</Typography>
    </TableCell>

    <TableCell component="div" sx={{ width: COLUMN_WIDTH }}>
      {alertTypeId && featureTypeId
        && <Paper
          data-testid="subscription-alert-description"
          elevation={0}
          sx={{
            ...getAlertTypeStyle({ alertTypeId, featureTypeId, feature } as AlertResponse, theme),
            display: 'flex',
            justifyContent: 'center',
            p: '0.5rem',
            textAlign: 'center',
          }}
        >
          {t(`common:alert-descriptions:${featureTypeId}.${alertTypeId}.label`)}
        </Paper>
      }
    </TableCell>
  </>;

  return (
    <ResultsTableRow
      content={renderContent()}
      href={disableLink ? undefined : `${href}/${_id}`}
      data-testid={props['data-testid']}
    />
  );
};
