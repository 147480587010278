/* eslint-disable react-hooks/rules-of-hooks */
import { Json, JsonArray, JsonObject, PropertiesDefinition, PropertyDefinition, ReferencePropertyDefinition, TypeDefinitionTypes } from '@eagle/common';
import { Button } from '@mui/material';
import { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Undefinable } from '../../types';
import { FormatDate, FormatNumber, FormatReference, FormatText, FormatTimestamp, getMaybePropertyValue } from '../format';
import { DataRow } from './data-row';

interface PropertiesRowsProps {
  properties: JsonObject;
  propertyDefinitions: PropertiesDefinition;
  maxProperties?: number;
}

export const PropertiesRows: FC<PropertiesRowsProps> = ({ properties, propertyDefinitions, maxProperties }) => {
  const propertiesDisplayCount = maxProperties
    ? Math.min(maxProperties, propertyDefinitions.order.length)
    : propertyDefinitions.order.length;

  return <>
    {propertyDefinitions.order.slice(0, propertiesDisplayCount).map((property, index) => (
      <DataRow
        data-testid={`property-display-${index}`}
        key={index}
        action={generateAction(property, properties, propertyDefinitions)}
        description={propertyDefinitions.definitions[property]?.description}
        label={generateLabel(property, propertyDefinitions)}
        value={generateValue(property, properties, propertyDefinitions)}
      />
    ))}
  </>;
};

const generateValue = (
  property: string,
  properties: JsonObject,
  propertyDefinitions: PropertiesDefinition,
): JSX.Element => {
  const rawPropertyValue = properties[property];
  const propertyValue = getMaybePropertyValue(rawPropertyValue);
  const propertyDefinition = propertyDefinitions.definitions[property];

  if (!propertyValue || !propertyDefinition) return <></>;
  if (!propertyDefinition.multiple) return <FormatValue value={propertyValue as Json} definition={propertyDefinition} />;

  const multipleValue = propertyValue
    ? propertyValue as JsonArray
    : [];
  return <>
    {multipleValue.map((value, ind) => {
      return (
        <Fragment key={ind} >
          {ind > 0 && <>, </>}
          <FormatValue value={value} definition={propertyDefinition} />
        </Fragment>
      );
    })}
  </>;
};

const FormatValue: FC<{ value: Json; definition: PropertyDefinition }> = ({ value, definition }) => {
  switch (definition.type) {
    case TypeDefinitionTypes.TEXT:
      return <FormatText value={value as string} />;
    case TypeDefinitionTypes.NUMBER:
      return <FormatNumber value={value as number} format={definition.format} type={'number'} />;
    case TypeDefinitionTypes.PHONE:
      return <FormatText value={value as string} />;
    case TypeDefinitionTypes.INTEGER:
      return <FormatNumber value={value as number} format={definition.format} type={'integer'} />;
    case TypeDefinitionTypes.BOOLEAN:
      return <FormatBoolean value={value as boolean} format={definition.format} />;
    case TypeDefinitionTypes.DATE: {
      const dateValue = value instanceof Date ? value : value as string;
      return <FormatDate value={dateValue} format={definition.format} />;
    }
    case TypeDefinitionTypes.TIMESTAMP: {
      const timestampValue = value instanceof Date ? value : value as string;
      return <FormatTimestamp value={timestampValue} format={definition.format} />;
    }
    case TypeDefinitionTypes.REFERENCE: {
      return <FormatReference value={value as string} definition={definition as ReferencePropertyDefinition} />;
    }
  }
  return <></>;
};

const generateLabel = (
  property: string,
  propertyDefinitions: PropertiesDefinition,
): JSX.Element => {
  const propertyDefinition = propertyDefinitions.definitions[property];

  if (!propertyDefinition) return <>{property}</>;

  return <>{propertyDefinitions.definitions[property].label}</>;
};

interface FormatBooleanProps {
  format: string;
  value: boolean;
}

const FormatBoolean: FC<FormatBooleanProps> = ({ value, format }) => {
  if (format === 'yes-no') return value ? <>Yes</> : <>No</>;
  return value ? <>True</> : <>False</>;
};

const generateAction = (
  property: string,
  properties: JsonObject,
  propertyDefinitions: PropertiesDefinition,
): Undefinable<JSX.Element> => {
  const { t } = useTranslation(['common']);
  const propertyDefinitionType = propertyDefinitions.definitions[property]?.type;
  const propertyValue = getMaybePropertyValue(properties[property]);

  switch (propertyDefinitionType) {
    case TypeDefinitionTypes.PHONE: {
      if (!propertyValue) return;
      return (
        <Button
          href={`tel:${propertyValue.toString()}`}
          variant="text"
        >
          {t('common:common.action.call')}
        </Button>
      );
    }
  }

  return undefined;
};
