import { Operator } from '@jexop/core';
import i18next from 'i18next';
import { isObject, isString } from 'lodash';
import { Nullable } from '../types';

export const translate: Operator<string> = ({translation, options, ...replace}, _):Nullable<string>  => {
  if (!isString(translation)) {
    return null;
  }
  const opts = !isObject(options) ? {} : options;
  const keyReplace = !isObject(replace) ? {} : replace;

  return i18next.t(translation, { ...opts, ...keyReplace });
};
