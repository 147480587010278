import { Account, DomainPublicConfigResponse, OidcAuthRequest, PublicAuthConfig, User } from '@eagle/core-data-types';
import { AxiosInstance } from 'axios';
import { PropsWithChildren } from 'react';
import { CodedError } from '../util/error';
import { SwitchedClientTokenProvider } from './token';
import { EthingsRestClient } from '@eagle/ethings-rest-client';

export type DomainCustomConfig = DomainPublicConfigResponse<PublicAuthConfig>;

export interface Auth extends PropsWithChildren {
  config?: OpenIdConfiguration;
  createAuthorizeParams: (overrides?: AuthorizeParamsOverride) => OidcAuthRequest | null;
  recentAccounts: string[];
  signIn: (overrides?: AuthorizeParamsOverride) => Promise<void>;
  signOut: () => Promise<void>;
  state?: AuthenticationState;
  switchAccount: (targetAccount: string) => Promise<void>;
  switchBackToOriginalAccount: () => void;
}

export interface WebOpenIdConfiguration {
  'authorization_endpoint': string;
  'end_session_endpoint': string;
  'token_endpoint': string;
  'x_ethings_authentication_provider'?: boolean;
}

export interface OpenIdConfiguration extends PublicAuthConfig {
  authUrl: string;
  signOutUrl: string;
  tokenEndpoint: string;
  xEthingsAuthenticationProvider?: boolean;
}

export enum AuthStatus {
  AUTHENTICATED,
  CONFIG_ERROR,
  AUTHENTICATION_FAILED,
  NOT_AUTHENTICATED,
}

export interface UserInfo {
  accountId: string;
  baseAccountId: string;
  hasRoleFunction: (...anyOfRfn: string[]) => boolean;
  type: string;
  userId: string;
}

export type AuthenticationState = AuthenticatedState | ConfigErrorState | AuthenticationFailedState | NotAuthenticatedState;

export interface AuthenticatedState {
  account: Account;
  axios: AxiosInstance;
  error?: CodedError;
  loading: boolean;
  restClient: EthingsRestClient;
  status: AuthStatus.AUTHENTICATED;
  switchedTokenProvider?: SwitchedClientTokenProvider;
  switchedConfig?: Partial<DomainCustomConfig>;
  timestamp: number;
  user: User;
  userInfo: UserInfo;
}

export interface ConfigErrorState {
  error: Error;
  status: AuthStatus.CONFIG_ERROR;
}

export interface AuthenticationFailedState {
  failure: AuthenticationFailure;
  status: AuthStatus.AUTHENTICATION_FAILED;
}

export interface AuthenticationFailure {
  error: string;
  errorDescription: string;
}

export interface NotAuthenticatedState {
  error?: CodedError;
  loading: boolean;
  status: AuthStatus.NOT_AUTHENTICATED;
}

export interface TokenResult {
  'id_token'?: string;
}

export interface RemoteAuthInfo {
  accessToken?: string;
  refreshToken?: string;
}

export enum SessionStorageKeys {
  SWITCHED_ACCOUNT_ID = 'switched-account-id'
}

export interface AuthorizeParamsOverride {
  redirectUri?: string;
  state?: string;
}
