import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { useGetTitle } from '../../hooks';

export const PageTitle: FC<{ defaultValue: string }> = ({ defaultValue }) => {
  const {title, replace} = useGetTitle();

  if (replace) return <>{title ?? defaultValue}</>;
  return (
    <Box sx={{ alignItems: 'center', display: 'flex', flex: 1, maxWidth: 'calc(100% - 40px)' }}>
      <Typography noWrap variant="h6" sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
        {title ?? defaultValue}
      </Typography>
    </Box>
  );
};
