import { useTheme } from '@mui/material';
import { FC } from 'react';
import { ThemeLogoOptions } from '../../types';

interface LogoProps {
  classes?: Record<'svg' | 'text', string>;
  width?: string;
  logo?: ThemeLogoOptions;
}

export const EthingsLogo: FC<LogoProps> = ({ width = '100%', logo = 'dark-colorful', classes }) => {
  const theme = useTheme();
  const logoRightColor = logo.includes('colorful') ? '#fc9700' : (logo === 'light' ? theme.logo.light : theme.logo.dark);
  const logoLeftColor = logo.includes('colorful') ? '#ff006a' : (logo === 'light' ? theme.logo.light : theme.logo.dark);
  const logoBottomColor = logo.includes('colorful') ? '#0097fe' : (logo === 'light' ? theme.logo.light : theme.logo.dark);
  const textColor = logo.includes('dark') ? theme.logo.dark : theme.logo.light;

  return <svg xmlns="http://www.w3.org/2000/svg" className={classes?.svg} viewBox="0 0 981.8 224.17" width={width} >
    <g id="container">
      <g id="logo">
        <g>
          <path id="top" d="M222.11,135.43c2.12,1.22,2.84,3.93,1.62,6.05l-9.96,17.25c-1.22,2.12-3.93,2.84-6.05,1.62l-59.03-34.08c-19.18-11.07-30.99-31.54-30.99-53.68V4.43c0-2.45,1.98-4.43,4.43-4.43h19.92c2.45,0,4.43,1.98,4.43,4.43V41.95c0,16.82-8.68,32.45-22.95,41.34-1.04,.65-1.36,2.01-.71,3.05,.63,1.01,1.95,1.34,2.99,.75l72.85-42.06c2.12-1.22,4.83-.5,6.05,1.62l9.96,17.25c1.22,2.12,.5,4.83-1.62,6.05l-72.85,42.06c-1.06,.61-1.42,1.97-.81,3.02,.6,1.03,1.91,1.41,2.96,.84,14.84-7.92,32.71-7.62,47.28,.79l32.5,18.76Z" fill={logoRightColor} />
          <path d="M202.19,169.94c2.12,1.22,2.84,3.93,1.62,6.05l-9.96,17.26c-1.22,2.12-3.93,2.84-6.05,1.62l-32.5-18.76c-14.56-8.41-23.76-23.74-24.32-40.55-.04-1.22-1.06-2.18-2.29-2.14-1.19,.04-2.14,1.02-2.14,2.21v84.12c0,2.45-1.98,4.43-4.43,4.43h-19.92c-2.45,0-4.43-1.98-4.43-4.43v-84.12c0-1.22-.99-2.21-2.21-2.21s-2.17,.95-2.21,2.14c-.56,16.81-9.76,32.14-24.32,40.55l-32.5,18.76c-2.12,1.22-4.83,.5-6.05-1.62l-9.96-17.26c-1.22-2.12-.5-4.82,1.62-6.05l59.03-34.08c19.18-11.07,42.81-11.07,61.98,0l59.03,34.08Z" fill={logoBottomColor} />
          <path d="M106.63,4.43V72.59c0,22.14-11.81,42.61-30.99,53.68l-59.03,34.08c-2.12,1.22-4.83,.5-6.05-1.62L.59,141.48c-1.22-2.12-.5-4.83,1.62-6.05l32.5-18.76c14.57-8.41,32.44-8.71,47.28-.79,1.08,.58,2.42,.17,3-.91,.56-1.05,.19-2.36-.85-2.96L11.29,69.95c-2.12-1.22-2.84-3.93-1.62-6.05l9.96-17.25c1.22-2.12,3.93-2.84,6.05-1.62l72.85,42.06c1.06,.61,2.41,.25,3.02-.81,.6-1.03,.27-2.36-.75-2.99-14.27-8.89-22.95-24.52-22.95-41.34V4.43c0-2.45,1.98-4.43,4.43-4.43h19.92c2.45,0,4.43,1.98,4.43,4.43Z" fill={logoLeftColor} />
        </g>
      </g>
      <g id="text" className={classes?.text}>
        <path d="M339.33,146.5c12.59,0,22.03-5.67,27.07-13.01l18.67,10.91c-9.65,14.27-25.39,22.87-45.95,22.87-34.62,0-57.07-23.71-57.07-55.18s22.45-55.18,55.39-55.18,52.67,25.18,52.67,55.39c0,3.15-.42,6.5-.84,9.44h-83.51c3.57,16.37,16.79,24.76,33.57,24.76Zm27.91-43.22c-3.15-18.04-16.37-25.81-30-25.81-17,0-28.54,10.07-31.68,25.81h61.69Z" fill={textColor} />
        <path d="M394.3,151.32c0-8.81,6.92-15.74,15.74-15.74s15.74,6.92,15.74,15.74-7.13,15.74-15.74,15.74-15.74-7.13-15.74-15.74Z" fill={textColor} opacity=".6" />
        <path d="M464.59,81.45v50.36c0,13.43,8.81,13.22,26.02,12.38v20.35c-34.83,4.2-48.68-5.46-48.68-32.73v-50.36h-19.3v-21.82h19.3v-22.66l22.66-6.71v29.38h26.02v21.82h-26.02Z" fill={textColor} />
        <path d="M601.4,100.13v64.42h-22.66v-62.11c0-15.95-9.23-24.34-23.5-24.34s-26.65,8.81-26.65,30.21v56.23h-22.66V17.67h22.66v55.39c6.92-10.91,18.25-16.16,32.52-16.16,23.5,0,40.29,15.95,40.29,43.22Z" fill={textColor} />
        <path d="M617.34,29.42c0-7.97,6.5-14.48,14.48-14.48s14.48,6.5,14.48,14.48-6.5,14.48-14.48,14.48-14.48-6.71-14.48-14.48Zm3.15,30.21h22.66v104.91h-22.66V59.63Z" fill={textColor} />
        <path d="M759.6,100.13v64.42h-22.66v-62.11c0-15.95-9.23-24.34-23.5-24.34s-26.65,8.81-26.65,30.21v56.23h-22.66V59.63h22.66v13.43c6.92-10.91,18.25-16.16,32.52-16.16,23.5,0,40.29,15.95,40.29,43.22Z" fill={textColor} />
        <path d="M884.44,59.63v100.08c0,33.78-26.44,49.52-53.92,49.52-23.08,0-40.92-8.6-49.73-25.39l19.51-11.33c4.62,9.02,12.38,16.16,30.63,16.16,19.51,0,31.26-10.7,31.26-28.96v-12.8c-7.97,11.33-20.35,18.25-36.51,18.25-29.58,0-52.88-24.13-52.88-54.13s23.29-54.13,52.88-54.13c16.16,0,28.54,6.92,36.51,18.25v-15.53h22.24Zm-22.24,51.41c0-18.88-14.27-32.94-33.36-32.94s-33.36,14.06-33.36,32.94,14.27,32.94,33.36,32.94,33.36-14.06,33.36-32.94Z" fill={textColor} />
        <path d="M981.8,134.96c0,20.98-18.25,32.31-40.92,32.31-20.98,0-36.09-9.44-42.8-24.55l19.51-11.33c3.36,9.44,11.75,15.11,23.29,15.11,10.07,0,17.83-3.36,17.83-11.75,0-18.67-56.44-8.18-56.44-45.74,0-19.72,17-32.1,38.4-32.1,17.21,0,31.47,7.97,38.82,21.82l-19.09,10.7c-3.78-8.18-11.12-11.96-19.72-11.96s-15.32,3.57-15.32,11.12c0,19.09,56.44,7.55,56.44,46.37Z" fill={textColor} />
      </g>
    </g>
  </svg>;
};
